import React, { useState, useEffect, useRef, memo } from 'react';
import { useLocation } from 'react-router-dom';
import {
  useTheme,
  useMediaQuery,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Tabs,
  Tab,
  Box,
  Grid,
  Paper,
  Typography,
  CircularProgress,
} from '@mui/material';
import BarCharts, { COLORS } from './UI/BarChart';
import DashboardService from '../services/dashboardService';
import moment from 'moment';
import PieCharts from './UI/PieCharts';
import { LabelList } from 'recharts';
import StackBarCharts from './UI/StackBarChat';
import userService from '../services/user.service';
import eventBus from '../common/EventBus';

const findTotal = (arr, type, key) => {
  if (arr?.length > 0) {
    return arr?.find((v, k) => v?.service_group === type)?.[key] || 0;
  } else {
    return 0;
  }
};

const insuranceList = [
  'Aetna',
  'BCBS',
  'CIGNA',
  'Hopkins EHP',
  'Hopkins USFHP',
  'Medical Assistance',
  'Medicare',
  'Tricare',
  'Other',
];

const healthcareProvidersOptions = [
  'Blanche Kattie, NP',
  'Laura Bothe, NP',
  'Dr. Yaniv Berger (Internist)',
  'Dr. Francisca Bruney (Family Medicine)',
  'Dr. Makeida Koyi (Psychiatrist)',
  'Dr. Steven Miller (Internist)',
  'Other',
];

const reasonOptions = [
  'No Availability',
  'In-Office Only Request (COVID)',
  'Not Appropriate for VC',
  'Psychologist Only',
  'Other',
];

const referalOptions = [
  'psychcare therapist',
  'other healthcare provider',
  'insurance list',
  'word of mouth',
  'other please specify',
];

const reasonColor = [
  '#FF5733',
  '#5733FF',
  '#FF33A3',
  '#33A3FF',
  '#FF3366',
  '#3366FF',
  '#6633FF',
  '#33FFA3',
  '#33FF66',
  '#66FF33',
  '#FF6633',
  '#33A3FF',
  '#FF33FF',
  '#33FFFF',
  '#FF33CC',
  '#33CCFF',
  '#CC33FF',
  '#33FF33',
  '#FFCC33',
  '#33FF00',
  '#00FF33',
  '#33FF99',
  '#9933FF',
  '#FF9933',
  '#33FFCC',
  '#CC33FF',
  '#FF99CC',
  '#CC99FF',
  '#FFCC99',
  '#CCFF99',
  '#99CCFF',
  '#99FFCC',
  '#FF99FF',
  '#CCFFFF',
  '#FFCCFF',
  '#FFFF33',
  '#33FFFF',
  '#FFFF66',
  '#66FFFF',
  '#FFFF99',
  '#99FFFF',
  '#FF9933',
  '#33FF33',
  '#33CC33',
];

const convertLabelForMobile = (label) => {
  if (label?.toLowerCase() === 'testing') {
    return 'Pt';
  } else if (label?.toLowerCase() === 'therapy') {
    return 'Th';
  } else {
    return '?';
  }
};

const child1Tabs = [
  { label: 'Type of Inquiries & Results', value: 'inquiries' },
  { label: 'Non-Conversion Reasons', value: 'non-conversion' },
  { label: 'Referral Sources', value: 'referral_sources' },
  {
    label: 'Non Conversion - Preferred Providers',
    value: 'non_conversion-preferred_providers',
  },
  { label: 'Appt Type & Location', value: 'appointment_type' },
  { label: 'Service Type - Appts', value: 'service_type_appts' },
  { label: 'Provider & New Appts', value: 'provider_new_ppts' },
];

const child2Tabs = [
  { label: 'All Calls', value: 'all_calls' },
  { label: 'New Client Inquirie (Ext 1 + Ext 11)', value: 'new_client' },
  { label: 'Cancellations (Ext 3)', value: 'cancellations' },
  { label: 'Outgoing Calls', value: 'outgoing' },
];

const transformData = (inputData, reasonOptions) => {
  const transformedData = {};
  inputData.forEach((item) => {
    const name = item.name;
    const reasonIndex = reasonOptions.indexOf(item.reason);
    if (reasonIndex >= 0) {
      if (!transformedData[name]) {
        transformedData[name] = {
          name,
        };
        for (let i = 1; i <= reasonOptions.length; i++) {
          transformedData[name][`value${i}`] = '0';
          transformedData[name][`labelForValue${i}`] = reasonOptions[i - 1];
        }
      }
      transformedData[name][`value${reasonIndex + 1}`] = item?.value
        ? item?.value
        : '1';
    }
  });
  const output = Object.values(transformedData);
  return output;
};

const CustomTooltipForCallLogs = ({ active, payload, label }) => {
  if (active && payload && payload.length) {
    return (
      <div
        className=""
        style={{
          paddingTop: '10px',
          paddingLeft: '10px',
          paddingRight: '10px',
          backgroundColor: 'black',
          cursor: 'pointer',
          border: '1px solid #e3e1e1',
          borderRadius: '3px',
          color: 'white',
          fontSize: '13px',
        }}
      >
        {payload[0]?.payload?.name?.toLowerCase() !== 'total' && (
          <p className="intro" style={{ marginTop: '5px' }}>
            {payload[0].payload.name}
          </p>
        )}
        <p className="intro" style={{ marginTop: '5px' }}>
          {payload[0].payload.labelForValue} : {payload[0]?.payload?.value}
        </p>
        <p className="intro" style={{ marginTop: '5px' }}>
          {payload[0].payload.labelForValue1} : {payload[0]?.payload?.value1}
        </p>
        <p className="intro" style={{ marginTop: '5px' }}>
          {payload[0].payload.labelForValue2} : {payload[0]?.payload?.value2}
        </p>
        <p className="intro" style={{ marginTop: '5px' }}>
          {payload[0].payload.labelForValue3} : {payload[0]?.payload?.value3}
        </p>
      </div>
    );
  }

  return null;
};

function calculateAllPercentage(numeratorData, denominatorData) {
  // Calculate Numerator
  const numerator =
    numeratorData && numeratorData.length > 0
      ? numeratorData.reduce((acc, cur) => acc + (Number(cur?.total) || 0), 0)
      : 0;

  // Calculate Denominator
  const denominator =
    denominatorData && denominatorData.length > 0
      ? denominatorData.reduce(
          (acc, cur) => acc + (Number(cur?.total_inquiries) || 0),
          0
        )
      : 0;

  // Calculate Percentage
  let percentage = '0.00'; // Default to '0.00' if denominator is zero or invalid
  if (denominator > 0) {
    percentage = ((numerator / denominator) * 100).toFixed(2);
  }

  // Return the percentage as a string with the '%' symbol
  return `${percentage}%`;
}

const Dashboard = () => {
  const xs9GridRef = useRef(null);
  const [providersOptions, setProvidersOptions] = React.useState([]);
  const [data, setData] = useState([]);
  const [value, setValue] = React.useState('1');
  const [childValue, setChildValue] = React.useState('inquiries');
  const [date, setDate] = useState(moment());
  const [view, setView] = useState('appointments');
  const [loading, setLoading] = useState(false);
  const [secondData, setSecondData] = useState([]);
  const [xs9Width, setXs9Width] = useState(0);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const { pathname } = useLocation();

  useEffect(() => {
    userService.getUsers().then(
      (response) => {
        let providers = response?.data?.users ?? [];
        providers = providers
          .filter((provider) => {
            return provider.roleId === 5;
          })
          .sort((provider1, provider2) => {
            const name1 = provider1?.username?.split(' ')[1] ?? '';
            const name2 = provider2?.username?.split(' ')[1] ?? '';
            return name1.localeCompare(name2);
          });
        setProvidersOptions(
          providers?.map((v, k) => v?.username?.toLowerCase())
        );
      },
      (error) => {
        const _content =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();

        console.log('_content', _content);

        if (error.response && error.response.status === 401) {
          eventBus.dispatch('logout');
        }
      }
    );
  }, []);

  useEffect(() => {
    function handleResize() {
      if (xs9GridRef.current) {
        setXs9Width(xs9GridRef.current.clientWidth - 50);
      }
    }

    // Add event listener for window resize
    window.addEventListener('resize', handleResize);

    // Initial width measurement
    handleResize();

    // Clean up the event listener on component unmount
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    if (pathname === '/dashboard/appointments') {
      setView('appointments');
      setChildValue('inquiries'); // Set to a valid value for appointments
    } else if (pathname === '/dashboard/call-logs') {
      setView('callLogs');
      setChildValue('all_calls'); // Set to a valid value for call logs
    }
  }, [pathname]);

  useEffect(() => {
    setLoading(true);
    if (view === 'appointments') {
      DashboardService.getStats({ type: childValue, date: date }).then(
        (res) => {
          console.log('Appointment Data:', res?.data?.stats);
          setData(res?.data?.stats);
          setLoading(false);
        }
      );
    } else if (view === 'callLogs') {
      DashboardService.getCallLogs({ date: date })
        .then((res) => {
          console.log('Full Call Logs Response:', res);
          console.log('Call Logs Data:', res?.data?.stats);
          setData(res?.data?.stats);
          setLoading(false);
        })
        .catch((error) => {
          console.error('Error fetching call logs:', error);
          setLoading(false);
        });
    }
  }, [view, childValue, date]);

  useEffect(() => {
    if (value === '1') {
      setLoading(true);

      DashboardService.getStats({ type: childValue, date: date }).then(
        (res) => {
          setLoading(false);
          setData(res?.data?.stats);
          if (res?.data?.stats1) {
            if (childValue === 'inquiries') {
              setSecondData(res?.data?.stats1);
            } else {
              let testingData = res?.data?.stats1?.filter(
                (v, k) => v.service_group === 'Testing'
              );
              let therapyData = res?.data?.stats1?.filter(
                (v, k) => v.service_group === 'Therapy'
              );

              setSecondData({
                therapy: {
                  psychcareTherapist: transformData(
                    therapyData?.filter(
                      (v, k) => v.aboutUsInfo === referalOptions[0]
                    ),
                    providersOptions
                  ),
                  otherHealthCareProvider: transformData(
                    therapyData?.filter(
                      (v, k) => v.aboutUsInfo === referalOptions[1]
                    ),
                    healthcareProvidersOptions?.map((v, k) => v?.toLowerCase())
                  ),
                  insuranceList: transformData(
                    therapyData?.filter(
                      (v, k) => v.aboutUsInfo === referalOptions[2]
                    ),
                    insuranceList?.map((v, k) => v?.toLowerCase())
                  ),
                  // wordOfMouth:transformData(therapyData,providersOptions),
                  //otherPleaseSpecify:transformData(therapyData,providersOptions)
                },
                testing: {
                  psychcareTherapist: transformData(
                    testingData?.filter(
                      (v, k) => v.aboutUsInfo === referalOptions[0]
                    ),
                    providersOptions
                  ),
                  otherHealthCareProvider: transformData(
                    testingData?.filter(
                      (v, k) => v.aboutUsInfo === referalOptions[1]
                    ),
                    healthcareProvidersOptions?.map((v, k) => v?.toLowerCase())
                  ),
                  insuranceList: transformData(
                    testingData?.filter(
                      (v, k) => v.aboutUsInfo === referalOptions[2]
                    ),
                    insuranceList?.map((v, k) => v?.toLowerCase())
                  ),
                  // wordOfMouth:transformData(testingData,providersOptions),
                  //otherPleaseSpecify:transformData(testingData,providersOptions)
                },
              });
            }
            //  console.log(transformData(testingData,referalOptions),"hereee")
          }
        }
      );
      // non-conversion-result-for-referal
      // "Referred Out"
      if (childValue === 'non-conversion') {
        DashboardService.getStats({
          type: 'non-conversion-result',
          date: date,
        }).then((genericRes) => {
          setLoading(false);
          DashboardService.getStats({
            type: 'non-conversion-result-for-referal',
            date: date,
          }).then((referredOutRes) => {
            setLoading(false);
            setSecondData({
              generic: genericRes?.data?.stats,
              referred_out_testing: transformData(
                referredOutRes?.data?.stats?.filter(
                  (v, k) => v.service_group === 'Testing'
                ),
                reasonOptions
              ),
              referred_out_threpy: transformData(
                referredOutRes?.data?.stats?.filter(
                  (v, k) => v.service_group === 'Therapy'
                ),
                reasonOptions
              ),
            });
          });
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [childValue, date]);

  useEffect(() => {
    if (value === '2') {
      setLoading(true);
      DashboardService.getCallLogs({ date: date }).then((res) => {
        setLoading(false);
        setData(res?.data?.stats);
      });
    }
  }, [value, date]);

  const handleChange = (event, newValue) => {
    setDate(moment());
    setData([]);
    if (newValue === '1') {
      setChildValue('inquiries');
    } else if (newValue === '2') {
      setChildValue('all_calls');
    } else if (newValue === '3') {
      setChildValue('1');
    }
    setValue(newValue);
  };
  const handleChangeChild = (event, newValue) => {
    setChildValue(newValue);
    if (value === '1') {
      setData([]);
    }
  };

  const CustomTooltipForReferredOut = ({
    active,
    payload,
    label,
    reasonOptions,
    type,
  }) => {
    if (active && payload && payload.length) {
      return (
        <div
          className=""
          style={{
            paddingTop: '10px',
            paddingLeft: '10px',
            paddingRight: '10px',
            backgroundColor: 'black',
            cursor: 'pointer',
            border: '1px solid #e3e1e1',
            borderRadius: '3px',
            color: 'white',
            fontSize: '13px',
          }}
        >
          <p className="intro" style={{ marginTop: '5px' }}>
            {payload[0].payload.name}
          </p>
          {type === 'referral_sources' ? (
            <>
              {' '}
              {reasonOptions?.map((v, k) => (
                <>
                  {payload[0]?.payload[`value${k + 1}`] !== '0' && (
                    <p
                      className="intro"
                      style={{ marginTop: '5px', textTransform: 'capitalize' }}
                    >
                      {payload[0].payload[`labelForValue${k + 1}`]} :{' '}
                      {payload[0]?.payload[`value${k + 1}`]}
                    </p>
                  )}
                </>
              ))}
            </>
          ) : (
            <>
              {reasonOptions?.map((v, k) => (
                <p
                  className="intro"
                  style={{ marginTop: '5px', textTransform: 'capitalize' }}
                >
                  {payload[0].payload[`labelForValue${k + 1}`]} :{' '}
                  {payload[0]?.payload[`value${k + 1}`] ||
                    payload[0]?.payload[`customValue${k + 1}`]}
                </p>
              ))}
            </>
          )}
        </div>
      );
    }

    return null;
  };

  function capitalize(string) {
    if (string === null || string === undefined) {
      return '';
    }
    return string.charAt(0).toUpperCase() + string.slice(1);
  }

  const onChangeDate = (e) => {
    let startdate = moment();
    startdate = startdate.subtract(e.target.value, 'days');
    setDate(startdate);
  };

  const referalGraph = [
    {
      name: 'PsychCare Therapist Therapy',
      value: 'psychcareTherapist',
      type: 'therapy',
      options: providersOptions,
      childValue: true,
    },
    {
      name: 'Other Healthcare Provider Therapy',
      value: 'otherHealthCareProvider',
      type: 'therapy',
      options: healthcareProvidersOptions,
    },
    {
      name: 'Insurance List Therapy',
      value: 'insuranceList',
      type: 'therapy',
      options: insuranceList,
    },
    {
      name: 'PsychCare Therapist Testing',
      value: 'psychcareTherapist',
      type: 'testing',
      options: providersOptions,
    },
    {
      name: 'Other Healthcare Provider Testing',
      value: 'otherHealthCareProvider',
      type: 'testing',
      options: healthcareProvidersOptions,
      childValue: true,
    },
    {
      name: 'Insurance List Testing',
      value: 'insuranceList',
      type: 'testing',
      options: insuranceList,
    },
  ];

  const MobileMenuAppointments = () => (
    <Select
      value={childValue} // Bind the current child tab value for accurate display
      onChange={(e) => handleChangeChild(null, e.target.value)}
      style={{ width: '100%' }}
      sx={{ mr: 2 }}
    >
      {child1Tabs.map((tab, index) => (
        <MenuItem key={index} value={tab.value}>
          {tab.label}
        </MenuItem>
      ))}
    </Select>
  );

  const MobileMenuCallLogs = () => (
    <div style={{ width: '100%' }}>
      <Select
        value={childValue} // Bind the current child tab value for accurate display
        onChange={(e) => handleChangeChild(null, e.target.value)}
        style={{ width: '100%', fontSize: '14px' }}
      >
        {child2Tabs.map((tab, index) => (
          <MenuItem key={index} value={tab.value}>
            {tab.label}
          </MenuItem>
        ))}
      </Select>
    </div>
  );

  return (
    <>
      <div style={{ width: '100%' }}>
        {view === 'appointments' && (
          <>
            {isMobile && (
              <Typography
                variant="h4"
                gutterBottom
                sx={{
                  marginTop: '0px',
                  marginBottom: '40px',
                  textAlign: 'center',
                  mt: 2, // Adds a top margin on mobile
                }}
              >
                Dashboard
              </Typography>
            )}
            {isMobile ? (
              <div
                style={{
                  width: `100%`,
                  position: 'sticky',
                  top: 70,
                  background: 'white',
                  zIndex: '999999',
                }}
              >
                <MobileMenuAppointments />
                <div
                  style={{
                    width: `100%`,
                    marginTop: '15px',
                  }}
                >
                  <FormControl
                    style={{
                      width: `100%`,
                    }}
                  >
                    <InputLabel id="select-days-label">Select Days</InputLabel>
                    <Select
                      onChange={(e) => {
                        onChangeDate(e);
                      }}
                      defaultValue={0}
                      className="outline-none"
                      label="Select Days"
                      sx={{ fontSize: '14px' }}
                    >
                      <MenuItem value={0}>Today</MenuItem>
                      <MenuItem value={1}>Yesterday</MenuItem>
                      <MenuItem value={7}>Last 7 Days</MenuItem>
                      <MenuItem value={30}>Past Month</MenuItem>
                      <MenuItem value={180}>Past 6 Months</MenuItem>
                      <MenuItem value={365}>Past 1 Year</MenuItem>
                    </Select>
                  </FormControl>
                </div>
              </div>
            ) : (
              <Tabs
                className=""
                onChange={handleChange}
                value={value}
                sx={{
                  '& button.Mui-selected': { backgroundColor: '#ebeded' },
                  borderBottom: 1,
                  borderColor: 'divider',
                }}
                aria-label="Tabs where each tab needs to be selected manually"
              ></Tabs>
            )}
            <Typography
              variant="h6"
              gutterBottom
              center
              sx={{ marginTop: '10px' }}
            >
              {value === '1' ? '' : 'All Call Logs'}
            </Typography>
            {value === '1' && (
              <div>
                <div style={{ marginTop: '20px', display: 'flex' }}>
                  <Grid
                    container
                    spacing={2}
                    justifyContent="center"
                    alignItems="center"
                  >
                    <Grid container spacing={2}>
                      {!isMobile && ( // Conditionally render the child tabs only for non-mobile screens
                        <Grid item xs={2}>
                          <Box sx={{ display: 'flex' }}>
                            <Tabs
                              orientation="vertical"
                              value={childValue}
                              onChange={handleChangeChild}
                              aria-label="Vertical tabs example"
                              sx={{
                                '& button.Mui-selected': {
                                  backgroundColor: '#ebeded',
                                },
                              }}
                            >
                              {child1Tabs?.map((v, k) => (
                                <Tab
                                  sx={{
                                    '&.Mui-selected': {
                                      outline: 'none',
                                    },
                                  }}
                                  label={v?.label}
                                  value={v?.value}
                                  key={v?.label + v?.value}
                                />
                              ))}
                            </Tabs>
                          </Box>
                        </Grid>
                      )}
                      <Grid
                        item
                        xs={isMobile ? 12 : 10}
                        ref={xs9GridRef}
                        sx={{ mx: isMobile ? 2 : 0, mt: isMobile ? 2 : 0 }}
                      >
                        <div
                          style={{
                            width:
                              !isMobile && xs9Width?.toString()?.concat('px'),
                            overflowX: 'auto',
                          }}
                        >
                          {loading && (
                            <div
                              style={{
                                position: 'absolute',
                                right: '130px',
                                marginTop: '-60px',
                                width: '140px',
                              }}
                            >
                              <CircularProgress />
                            </div>
                          )}
                          {!isMobile && (
                            <div
                              style={{
                                display: 'flex',
                                justifyContent: 'end',
                                alignItems: 'end',
                                width: `100%`,
                                // position:"sticky",
                                // top:80
                              }}
                            >
                              <FormControl
                                style={{
                                  width: `${isMobile ? '100%' : '150px'}`,
                                }}
                              >
                                <InputLabel id="select-days-label">
                                  Select Days
                                </InputLabel>
                                <Select
                                  onChange={(e) => {
                                    onChangeDate(e);
                                  }}
                                  defaultValue={0}
                                  className="outline-none"
                                  label="Select Days"
                                  sx={{ fontSize: '14px' }}
                                >
                                  <MenuItem value={0}>Today</MenuItem>
                                  <MenuItem value={1}>Yesterday</MenuItem>
                                  <MenuItem value={7}>Last 7 Days</MenuItem>
                                  <MenuItem value={30}>Past Month</MenuItem>
                                  <MenuItem value={180}>Past 6 Months</MenuItem>
                                  <MenuItem value={365}>Past 1 Year</MenuItem>
                                </Select>
                              </FormControl>
                            </div>
                          )}

                          {childValue === 'inquiries' && (
                            <div>
                              <div
                                style={{
                                  display: 'flex',
                                  justifyContent: 'center',
                                }}
                              >
                                <div>
                                  <Typography
                                    variant="h6"
                                    gutterBottom
                                    sx={{ mt: 2 }}
                                  >
                                    Conversion Rate (Appt/Inquiry)
                                  </Typography>
                                  <div
                                    style={{
                                      display: 'flex',
                                      flexDirection: isMobile
                                        ? 'column'
                                        : 'row',
                                    }}
                                  >
                                    <div
                                      style={{
                                        width: isMobile ? '100%' : '250px',
                                      }}
                                    >
                                      <Paper
                                        sx={{
                                          p: 2,
                                          mt: 1,
                                          mx: isMobile ? 4 : 1,
                                        }}
                                      >
                                        <p>All</p>
                                        <Typography variant="h5">
                                          {/** The below function for calculating percentage for All**/}
                                          {calculateAllPercentage(
                                            secondData,
                                            data
                                          )}
                                        </Typography>
                                      </Paper>
                                    </div>
                                    <div
                                      style={{
                                        width: isMobile ? '100%' : '250px',
                                      }}
                                    >
                                      <Paper
                                        sx={{
                                          p: 2,
                                          mt: 1,
                                          mx: isMobile ? 4 : 1,
                                        }}
                                      >
                                        <p>Therapy</p>
                                        <Typography variant="h5">
                                          {(
                                            ((findTotal(
                                              secondData,
                                              'Therapy',
                                              'total'
                                            ) || 0) /
                                              (findTotal(
                                                data,
                                                'Therapy',
                                                'total_inquiries'
                                              ) || 1)) *
                                              100 || 0
                                          )?.toFixed(2)}
                                          %
                                        </Typography>
                                      </Paper>
                                    </div>
                                    <div
                                      style={{
                                        width: isMobile ? '100%' : '250px',
                                      }}
                                    >
                                      <Paper
                                        sx={{
                                          p: 2,
                                          mt: 1,
                                          mx: isMobile ? 4 : 1,
                                        }}
                                      >
                                        <p>Testing</p>
                                        <Typography variant="h5">
                                          {(
                                            ((findTotal(
                                              secondData,
                                              'Testing',
                                              'total'
                                            ) || 0) /
                                              (findTotal(
                                                data,
                                                'Testing',
                                                'total_inquiries'
                                              ) || 1)) *
                                              100 || 0
                                          )?.toFixed(2)}
                                          %
                                        </Typography>
                                      </Paper>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <Paper
                                style={{
                                  width:
                                    data?.length > 6
                                      ? ((data?.length || 0) * 200)
                                          ?.toString()
                                          ?.concat('px')
                                      : '100%',
                                }}
                              >
                                <>
                                  <div
                                    style={{
                                      height: '500px',
                                      width: '100%',
                                      marginTop: isMobile ? '50px' : '40px', // Adjust this value as needed
                                    }}
                                  >
                                    <StackBarCharts
                                      data={
                                        data?.length > 0 &&
                                        data?.map((v, k) => ({
                                          ...v,
                                          name: isMobile
                                            ? convertLabelForMobile(
                                                v?.service_group
                                              )
                                            : `(${capitalize(
                                                v?.service_group || 'Unknown'
                                              )})`,
                                          value1: v?.live_calls,
                                          value2: v?.online_inquiries,
                                          customValue3: v?.total_inquiries,
                                          labelForValue1: 'live Calls',
                                          labelForValue2: 'Online Inquiries',
                                          labelForValue3: 'Total Inquiries',
                                        }))
                                      } // Pass the sorted data here
                                      CustomTooltip={
                                        <CustomTooltipForReferredOut
                                          reasonOptions={[
                                            'live Calls',
                                            'Online Inquiries',
                                            'Total Inquiries',
                                          ]}
                                        />
                                      }
                                      isTick={window.innerWidth >= 768}
                                    />
                                  </div>
                                  <div
                                    style={{
                                      display: 'flex',
                                      flexWrap: 'wrap',
                                      width: '100%',
                                      justifyContent: !isMobile ? 'center' : '',
                                      marginLeft: isMobile ? '10px' : '0px', // Add a left margin only on mobile
                                    }}
                                  >
                                    {[
                                      'Online Inquiries',
                                      'Total Inquiries',
                                    ]?.map((v, k) => (
                                      <div style={{ display: 'flex' }}>
                                        <div>{v}</div>
                                        <div
                                          style={{
                                            backgroundColor: reasonColor[k],
                                            height: '10px',
                                            marginTop: '7px',
                                            width: '20px',
                                            marginLeft: '10px',
                                            marginRight: '10px',
                                          }}
                                        ></div>
                                      </div>
                                    ))}
                                  </div>
                                </>
                              </Paper>
                              <Paper
                                style={{
                                  width:
                                    data?.length > 6
                                      ? ((data?.length || 0) * 200)
                                          ?.toString()
                                          ?.concat('px')
                                      : '100%',
                                }}
                              >
                                <>
                                  <Typography
                                    variant="h5"
                                    gutterBottom
                                    sx={{
                                      marginTop: '10px',
                                      marginBottom: '10px',
                                      mt: 2, // Adds a top margin on mobile
                                      marginLeft: isMobile ? '10px' : '0px', // Add a left margin only on mobile
                                    }}
                                  >
                                    Existing, New And Returning Patient
                                    Appointments
                                  </Typography>
                                  <div
                                    style={{
                                      height: '500px',
                                      width: '100%',
                                      marginTop: isMobile ? '50px' : '40px', // Adjust this value as needed
                                    }}
                                  >
                                    <StackBarCharts
                                      data={
                                        secondData?.length > 0 &&
                                        secondData?.map((v, k) => ({
                                          ...v,
                                          name: isMobile
                                            ? convertLabelForMobile(
                                                v?.service_group
                                              )
                                            : `(${capitalize(
                                                v?.service_group
                                              )})`,
                                          value1: v?.['Existing Patient'],
                                          value2: v?.['New Patient'],
                                          value3: v?.['Returning Patient'],
                                          labelForValue1: 'Existing Patient',
                                          labelForValue2: 'New Patient',
                                          labelForValue3: 'Returning Patient',
                                        }))
                                      } // Pass the sorted data here
                                      CustomTooltip={
                                        <CustomTooltipForReferredOut
                                          reasonOptions={[
                                            'Existing Patient',
                                            'New Patient',
                                            'Returning Patient',
                                          ]}
                                        />
                                      }
                                      isTick={window.innerWidth >= 768}
                                    />
                                  </div>
                                  <div
                                    style={{
                                      display: 'flex',
                                      flexWrap: 'wrap',
                                      width: '100%',
                                      justifyContent: !isMobile ? 'center' : '',
                                    }}
                                  >
                                    {[
                                      'Existing Patient',
                                      'New Patient',
                                      'Returning Patient',
                                    ]?.map((v, k) => (
                                      <div style={{ display: 'flex' }}>
                                        <div>{v}</div>
                                        <div
                                          style={{
                                            backgroundColor: reasonColor[k],
                                            height: '10px',
                                            marginTop: '7px',
                                            width: '20px',
                                            marginLeft: '10px',
                                            marginRight: '10px',
                                          }}
                                        ></div>
                                      </div>
                                    ))}
                                  </div>
                                </>
                              </Paper>
                            </div>
                          )}
                          {childValue === 'non-conversion' && (
                            <>
                              <Paper
                                style={{
                                  width:
                                    data?.length > 6
                                      ? ((data?.length || 0) * 200)
                                          ?.toString()
                                          ?.concat('px')
                                      : '100%',
                                }}
                              >
                                <div
                                  style={{
                                    height: '500px',
                                    overflowX: 'auto',
                                    paddingBottom: '50px',
                                    width: '100%',
                                    overflowY: 'hidden',
                                  }}
                                >
                                  <Typography
                                    variant="h6"
                                    gutterBottom
                                    center
                                    sx={{
                                      marginTop: '10px',
                                      marginLeft: '5px',
                                    }}
                                  >
                                    Therapy
                                  </Typography>

                                  {window.innerWidth >= 768 ? (
                                    <BarCharts
                                      isResponsive={false}
                                      yAxisLabel={
                                        <LabelList
                                          dataKey="alias"
                                          position="top"
                                        />
                                      }
                                      data={secondData?.generic
                                        ?.filter(
                                          (v, k) =>
                                            v.service_group === 'Therapy'
                                        )
                                        ?.map((v, k) => ({
                                          ...v,
                                          value: parseInt(v?.value),
                                        }))}
                                    />
                                  ) : (
                                    <div style={{ height: '400px' }}>
                                      <PieCharts
                                        data={
                                          secondData?.generic
                                            ?.filter(
                                              (v, k) =>
                                                v.service_group === 'Therapy'
                                            )
                                            ?.map((v, k) => ({
                                              ...v,
                                              name: `${v?.reason} (${v?.name})`,
                                              value: parseInt(v?.value),
                                            })) || []
                                        }
                                      />
                                    </div>
                                  )}
                                </div>
                              </Paper>

                              <Paper
                                style={{
                                  width:
                                    data?.length > 6
                                      ? ((data?.length || 0) * 200)
                                          ?.toString()
                                          ?.concat('px')
                                      : '100%',
                                }}
                              >
                                <div
                                  style={{
                                    height: '500px',
                                    overflowX: 'auto',
                                    paddingBottom: '50px',
                                    width: '100%',
                                    overflowY: 'hidden',
                                  }}
                                >
                                  <Typography
                                    variant="h6"
                                    gutterBottom
                                    center
                                    sx={{
                                      marginTop: '10px',
                                      marginLeft: '5px',
                                    }}
                                  >
                                    Referred Out Therapy
                                  </Typography>
                                  <StackBarCharts
                                    CustomTooltip={
                                      <CustomTooltipForReferredOut
                                        reasonOptions={reasonOptions}
                                      />
                                    }
                                    data={secondData?.referred_out_threpy}
                                    showXaxis={!isMobile}
                                  />
                                </div>
                                <div
                                  style={{
                                    display: 'flex',
                                    flexWrap: 'wrap',
                                    width: '100%',
                                    justifyContent: !isMobile ? 'center' : '',
                                  }}
                                >
                                  {reasonOptions?.map((v, k) => (
                                    <div style={{ display: 'flex' }}>
                                      <div
                                        style={{
                                          textTransform: 'capitalize',
                                        }}
                                      >
                                        {v}
                                      </div>
                                      <div
                                        style={{
                                          backgroundColor: reasonColor[k],
                                          height: '10px',
                                          marginTop: '7px',
                                          width: '20px',
                                          marginLeft: '10px',
                                          marginRight: '20px',
                                        }}
                                      ></div>
                                    </div>
                                  ))}
                                </div>
                              </Paper>
                              <Paper
                                style={{
                                  width:
                                    data?.length > 6
                                      ? ((data?.length || 0) * 200)
                                          ?.toString()
                                          ?.concat('px')
                                      : '100%',
                                }}
                              >
                                <div
                                  style={{
                                    height: '500px',
                                    overflowX: 'auto',
                                    paddingBottom: '50px',
                                    width: '100%',
                                    overflowY: 'hidden',
                                  }}
                                >
                                  <Typography
                                    variant="h6"
                                    gutterBottom
                                    center
                                    sx={{
                                      marginTop: '10px',
                                      marginLeft: '5px',
                                    }}
                                  >
                                    Testing
                                  </Typography>
                                  <BarCharts
                                    yAxisLabel={
                                      <LabelList
                                        dataKey="reason"
                                        position="top"
                                      />
                                    }
                                    isResponsive={true}
                                    data={secondData?.generic?.filter(
                                      (v, k) => v.service_group === 'Testing'
                                    )}
                                    isTick={window.innerWidth >= 768}
                                  />
                                  {window.innerWidth >= 768 && (
                                    <div
                                      style={{
                                        display: 'flex',
                                        flexWrap: 'wrap',
                                        width: '100%',
                                        justifyContent: !isMobile
                                          ? 'center'
                                          : '',
                                      }}
                                    >
                                      {secondData?.generic
                                        ?.filter(
                                          (v, k) =>
                                            v.service_group === 'Testing'
                                        )
                                        ?.map((v, k) => (
                                          <div style={{ display: 'flex' }}>
                                            <div
                                              style={{
                                                textTransform: 'capitalize',
                                              }}
                                            >
                                              {v?.name}
                                            </div>
                                            <div
                                              style={{
                                                backgroundColor: COLORS[k],
                                                height: '10px',
                                                marginTop: '7px',
                                                width: '20px',
                                                marginLeft: '10px',
                                                marginRight: '20px',
                                              }}
                                            ></div>
                                          </div>
                                        ))}
                                    </div>
                                  )}
                                </div>
                              </Paper>
                              <div
                                style={{
                                  height: '500px',
                                  overflowX: 'auto',
                                  paddingBottom: '50px',
                                  width: '100%',
                                  overflowY: 'hidden',
                                }}
                              >
                                <Typography
                                  variant="h6"
                                  gutterBottom
                                  center
                                  sx={{ marginTop: '10px', marginLeft: '5px' }}
                                >
                                  Referred Out Testing
                                </Typography>
                                <StackBarCharts
                                  CustomTooltip={
                                    <CustomTooltipForReferredOut
                                      reasonOptions={reasonOptions}
                                    />
                                  }
                                  isTick={window.innerWidth >= 768}
                                  data={secondData?.referred_out_testing}
                                />
                              </div>
                              <div
                                style={{
                                  display: 'flex',
                                  flexWrap: 'wrap',
                                  width: '100%',
                                  justifyContent: !isMobile ? 'center' : '',
                                }}
                              >
                                {reasonOptions?.map((v, k) => (
                                  <div style={{ display: 'flex' }}>
                                    <div
                                      style={{
                                        textTransform: 'capitalize',
                                      }}
                                    >
                                      {v}
                                    </div>
                                    <div
                                      style={{
                                        backgroundColor: reasonColor[k],
                                        height: '10px',
                                        marginTop: '7px',
                                        width: '20px',
                                        marginLeft: '10px',
                                        marginRight: '20px',
                                      }}
                                    ></div>
                                  </div>
                                ))}
                              </div>
                              <div style={{ height: '500px' }}>
                                <PieCharts data={data || []} />
                              </div>
                            </>
                          )}

                          {childValue ===
                            'non_conversion-preferred_providers' && (
                            <Paper>
                              <div
                                style={{ height: isMobile ? '400px' : '500px' }}
                              >
                                {window.innerWidth >= 768 ? (
                                  <BarCharts data={data} />
                                ) : (
                                  <PieCharts
                                    data={
                                      data?.map((v, k) => ({
                                        ...v,
                                        name: capitalize(v?.name),
                                        value: parseInt(v?.value),
                                      })) || []
                                    }
                                  />
                                )}
                              </div>
                            </Paper>
                          )}
                          {(childValue === 'referral_sources' ||
                            childValue === 'service_type_appts' ||
                            childValue === 'appointment_type' ||
                            childValue === 'provider_new_ppts') && (
                            <>
                              <Paper>
                                <div
                                  style={{
                                    height: '500px',
                                    overflowX: 'auto',
                                    paddingBottom: '50px',
                                    width: '100%',
                                    overflowY: 'hidden',
                                  }}
                                >
                                  <Typography
                                    variant="h6"
                                    gutterBottom
                                    center
                                    sx={{
                                      marginTop: '10px',
                                      marginLeft: '5px',
                                    }}
                                  >
                                    Therapy
                                  </Typography>
                                  {window.innerWidth >= 768 ||
                                  !(
                                    childValue === 'referral_sources' ||
                                    childValue === 'service_type_appts' ||
                                    childValue === 'provider_new_ppts'
                                  ) ? (
                                    <BarCharts
                                      barWidth={150}
                                      data={data
                                        ?.filter(
                                          (v, k) =>
                                            v.service_group === 'Therapy'
                                        )
                                        ?.map((v, k) => ({
                                          ...v,
                                          name:
                                            v?.name?.length > 35
                                              ? v?.name?.split(' ')[0]
                                              : capitalize(v?.name),
                                        }))}
                                      isResponsive={
                                        !(
                                          childValue === 'referral_sources' ||
                                          childValue === 'service_type_appts' ||
                                          childValue === 'provider_new_ppts'
                                        )
                                      }
                                    />
                                  ) : (
                                    <div style={{ height: '500px' }}>
                                      <PieCharts
                                        data={
                                          data
                                            ?.filter(
                                              (v, k) =>
                                                v.service_group === 'Therapy'
                                            )
                                            ?.map((v, k) => ({
                                              ...v,
                                              name: capitalize(v?.name),
                                              value: parseInt(v?.value),
                                            })) || []
                                        }
                                      />
                                    </div>
                                  )}
                                </div>
                              </Paper>

                              <Paper>
                                <div
                                  style={{
                                    height: '500px',
                                    overflowX: 'auto',
                                    paddingBottom: '50px',
                                    width: '100%',
                                    marginTop: '70px',
                                    overflowY: 'hidden',
                                  }}
                                >
                                  <Typography
                                    variant="h6"
                                    gutterBottom
                                    center
                                    sx={{
                                      marginTop: '10px',
                                      marginLeft: '5px',
                                    }}
                                  >
                                    Testing
                                  </Typography>
                                  {window.innerWidth >= 768 ||
                                  !(
                                    childValue === 'referral_sources' ||
                                    childValue === 'service_type_appts' ||
                                    childValue === 'provider_new_ppts'
                                  ) ? (
                                    <BarCharts
                                      barWidth={150}
                                      data={data
                                        ?.filter(
                                          (v, k) =>
                                            v.service_group === 'Testing'
                                        )
                                        ?.map((v, k) => ({
                                          ...v,
                                          name:
                                            v?.name?.length > 35
                                              ? v?.name?.split(' ')[0]
                                              : capitalize(v?.name),
                                        }))}
                                      isResponsive={
                                        !(
                                          childValue === 'referral_sources' ||
                                          childValue === 'service_type_appts' ||
                                          childValue === 'provider_new_ppts'
                                        )
                                      }
                                    />
                                  ) : (
                                    <div style={{ height: '400px' }}>
                                      <PieCharts
                                        data={
                                          data
                                            ?.filter(
                                              (v, k) =>
                                                v.service_group === 'Testing'
                                            )
                                            ?.map((v, k) => ({
                                              ...v,
                                              name: capitalize(v?.name),
                                              value: parseInt(v?.value),
                                            })) || []
                                        }
                                      />
                                    </div>
                                  )}
                                </div>
                              </Paper>

                              {childValue === 'referral_sources' && (
                                <>
                                  {referalGraph?.map((v, k) => (
                                    <>
                                      <Paper>
                                        <div
                                          style={{
                                            height: '500px',
                                            overflowX: 'auto',
                                            paddingBottom: '50px',
                                            width: '100%',
                                            overflowY: 'hidden',
                                          }}
                                        >
                                          <Typography
                                            variant="h6"
                                            gutterBottom
                                            center
                                            sx={{
                                              marginTop: '10px',
                                              marginLeft: '5px',
                                            }}
                                          >
                                            {v?.name}
                                          </Typography>
                                          {window.innerWidth >= 768 ? (
                                            <StackBarCharts
                                              CustomTooltip={
                                                <CustomTooltipForReferredOut
                                                  type={
                                                    v?.childValue
                                                      ? childValue
                                                      : false
                                                  }
                                                  reasonOptions={v?.options}
                                                />
                                              }
                                              data={
                                                secondData?.[v?.type]?.[
                                                  v?.value
                                                ]
                                              }
                                            />
                                          ) : (
                                            <div style={{ height: '400px' }}>
                                              <PieCharts
                                                data={
                                                  secondData?.[v?.type]?.[
                                                    v?.value
                                                  ]?.map(
                                                    (graphdata, graphIndex) => {
                                                      let total = 0;
                                                      let name =
                                                        graphdata?.name;
                                                      // eslint-disable-next-line no-unused-vars
                                                      let c = v?.options?.map(
                                                        (
                                                          option,
                                                          optionIndex
                                                        ) => {
                                                          total =
                                                            total +
                                                            (parseInt(
                                                              graphdata?.[
                                                                `value${optionIndex}`
                                                              ]
                                                            ) || 0);
                                                          if (
                                                            parseInt(
                                                              graphdata?.[
                                                                `value${optionIndex}`
                                                              ]
                                                            ) > 0
                                                          ) {
                                                            name =
                                                              name +
                                                              ' ' +
                                                              graphdata?.[
                                                                `labelForValue${optionIndex}`
                                                              ] +
                                                              ' (' +
                                                              graphdata?.[
                                                                `value${optionIndex}`
                                                              ] +
                                                              ') ';
                                                          }
                                                          return null;
                                                        }
                                                      );
                                                      return {
                                                        value: total,
                                                        name,
                                                      };
                                                    }
                                                  ) || []
                                                }
                                              />
                                            </div>
                                          )}
                                        </div>
                                      </Paper>
                                      {v?.value === 'psychcareTherapist' && (
                                        <div
                                          style={{
                                            display: 'flex',
                                            flexWrap: 'wrap',
                                            justifyContent: !isMobile
                                              ? 'center'
                                              : '',
                                            width: '100%',
                                          }}
                                        >
                                          {providersOptions?.map((v, k) => (
                                            <div style={{ display: 'flex' }}>
                                              <div
                                                style={{
                                                  textTransform: 'capitalize',
                                                }}
                                              >
                                                {v}
                                              </div>
                                              <div
                                                style={{
                                                  backgroundColor:
                                                    reasonColor[k],
                                                  height: '10px',
                                                  marginTop: '7px',
                                                  width: '20px',
                                                  marginLeft: '10px',
                                                  marginRight: '20px',
                                                }}
                                              ></div>
                                            </div>
                                          ))}
                                        </div>
                                      )}

                                      {v?.value ===
                                        'otherHealthCareProvider' && (
                                        <div
                                          style={{
                                            display: 'flex',
                                            justifyContent: 'center',
                                            flexWrap: 'wrap',
                                            width: '100%',
                                          }}
                                        >
                                          {healthcareProvidersOptions?.map(
                                            (v, k) => (
                                              <div style={{ display: 'flex' }}>
                                                <div
                                                  style={{
                                                    textTransform: 'capitalize',
                                                  }}
                                                >
                                                  {v}
                                                </div>
                                                <div
                                                  style={{
                                                    backgroundColor:
                                                      reasonColor[k],
                                                    height: '10px',
                                                    marginTop: '7px',
                                                    width: '20px',
                                                    marginLeft: '10px',
                                                    marginRight: '20px',
                                                  }}
                                                ></div>
                                              </div>
                                            )
                                          )}
                                        </div>
                                      )}

                                      {v?.type === 'insuranceList' && (
                                        <div
                                          style={{
                                            display: 'flex',
                                            justifyContent: 'center',
                                            flexWrap: 'wrap',
                                            width: '100%',
                                          }}
                                        >
                                          {insuranceList?.map((v, k) => (
                                            <div style={{ display: 'flex' }}>
                                              <div
                                                style={{
                                                  textTransform: 'capitalize',
                                                }}
                                              >
                                                {v}
                                              </div>
                                              <div
                                                style={{
                                                  backgroundColor:
                                                    reasonColor[k],
                                                  height: '10px',
                                                  marginTop: '7px',
                                                  width: '20px',
                                                  marginLeft: '10px',
                                                  marginRight: '20px',
                                                }}
                                              ></div>
                                            </div>
                                          ))}
                                        </div>
                                      )}
                                    </>
                                  ))}
                                </>
                              )}
                            </>
                          )}
                        </div>
                      </Grid>
                    </Grid>
                  </Grid>
                </div>
              </div>
            )}
          </>
        )}
        {view === 'callLogs' && (
          <>
            {isMobile && (
              <div style={{ marginTop: '5px', width: '100%' }}>
                {' '}
                <MobileMenuCallLogs />
              </div>
            )}
            <div>
              <div
                style={{
                  marginTop: isMobile ? '10px' : '20px',
                  display: 'flex',
                }}
              >
                {!isMobile && ( // Conditionally render the child tabs only for non-mobile screens
                  <Box sx={{ display: 'flex', width: '15%' }}>
                    <Tabs
                      orientation="vertical"
                      value={childValue}
                      onChange={handleChangeChild}
                      aria-label="Vertical tabs example"
                      sx={{
                        '& button.Mui-selected': { backgroundColor: '#ebeded' },
                      }}
                    >
                      {child2Tabs?.map((v, k) => (
                        <Tab
                          sx={{
                            '&.Mui-selected': {
                              outline: 'none',
                            },
                          }}
                          label={v?.label}
                          value={v?.value}
                        />
                      ))}
                    </Tabs>
                  </Box>
                )}

                <div
                  style={{
                    padding: isMobile ? '0px' : '10px',
                    width: isMobile ? '100%' : '85%',
                    height: '500px',
                  }}
                >
                  {loading && (
                    <div
                      style={{
                        position: 'absolute',
                        right: '130px',
                        marginTop: '-60px',
                        width: '140px',
                      }}
                    >
                      <CircularProgress />
                    </div>
                  )}

                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'end',
                      alignItems: 'end',
                      width: `100%`,
                    }}
                  >
                    <FormControl
                      style={{
                        width: `${isMobile ? '100%' : '150px'}`,
                      }}
                    >
                      <InputLabel id="select-days-label">
                        Select Days
                      </InputLabel>
                      <Select
                        onChange={(e) => {
                          onChangeDate(e);
                        }}
                        className="outline-none"
                        label="Select Days"
                        sx={{ fontSize: '14px' }}
                      >
                        <MenuItem value={0}>Today</MenuItem>
                        <MenuItem value={1}>Yesterday</MenuItem>
                        <MenuItem value={7}>Last 7 Days</MenuItem>
                        <MenuItem value={30}>Past Month</MenuItem>
                        <MenuItem value={180}>Past 6 Months</MenuItem>
                        {/* <MenuItem value={365}>Past 1 Year</MenuItem> */}
                      </Select>
                    </FormControl>
                  </div>
                  <div
                    style={{
                      display: 'flex',
                      flexWrap: 'wrap',
                      marginLeft: isMobile ? '' : '75px',
                      marginTop: isMobile ? '50px' : '0px',
                    }}
                  >
                    <div style={{ display: 'flex' }}>
                      <div>Total</div>
                      <div
                        style={{
                          backgroundColor: '#0088FE',
                          height: '10px',
                          marginTop: '7px',
                          width: '20px',
                          marginLeft: '10px',
                          marginRight: '20px',
                        }}
                      ></div>
                    </div>
                    <div style={{ display: 'flex' }}>
                      <div>0-3 min</div>
                      <div
                        style={{
                          backgroundColor: '#00C49F',
                          height: '10px',
                          marginTop: '7px',
                          width: '20px',
                          marginLeft: '10px',
                          marginRight: '20px',
                        }}
                      ></div>
                    </div>
                    <div style={{ display: 'flex' }}>
                      <div>3-10 min</div>
                      <div
                        style={{
                          backgroundColor: '#FFBB28',
                          height: '10px',
                          marginTop: '7px',
                          width: '20px',
                          marginLeft: '10px',
                          marginRight: '20px',
                        }}
                      ></div>
                    </div>
                    <div style={{ display: 'flex' }}>
                      <div>10+ min</div>
                      <div
                        style={{
                          backgroundColor: '#ff2a00',
                          height: '10px',
                          marginTop: '7px',
                          width: '20px',
                          marginLeft: '10px',
                          marginRight: '20px',
                        }}
                      ></div>
                    </div>
                  </div>

                  {childValue === 'all_calls' && (
                    <div style={{ height: '500px' }}>
                      <BarCharts
                        CustomTooltip={<CustomTooltipForCallLogs />}
                        firstBarColor="#0088FE"
                        secondBarColor="#00C49F"
                        thirdBarColor="#FFBB28"
                        forthBarColor="#ff2a00"
                        secondBar={true}
                        thirdBar={true}
                        forthBar={true}
                        data={data?.['allCalls']}
                        isResponsive={true}
                        showXaxis={!isMobile}
                      />{' '}
                    </div>
                  )}

                  {childValue === 'new_client' && (
                    <div style={{ height: '500px' }}>
                      <BarCharts
                        CustomTooltip={<CustomTooltipForCallLogs />}
                        firstBarColor="#0088FE"
                        secondBarColor="#00C49F"
                        thirdBarColor="#FFBB28"
                        forthBarColor="#ff2a00"
                        secondBar={true}
                        thirdBar={true}
                        forthBar={true}
                        isResponsive={true}
                        showXaxis={!isMobile}
                        data={data?.['Extension_1+11']}
                      />{' '}
                    </div>
                  )}

                  {childValue === 'cancellations' && (
                    <div style={{ height: '500px' }}>
                      <BarCharts
                        CustomTooltip={<CustomTooltipForCallLogs />}
                        firstBarColor="#0088FE"
                        secondBarColor="#00C49F"
                        thirdBarColor="#FFBB28"
                        forthBarColor="#ff2a00"
                        secondBar={true}
                        thirdBar={true}
                        forthBar={true}
                        isResponsive={true}
                        showXaxis={!isMobile}
                        data={data?.['Extension_3']}
                      />{' '}
                    </div>
                  )}

                  {childValue === 'outgoing' && (
                    <div style={{ height: '500px' }}>
                      <BarCharts
                        CustomTooltip={<CustomTooltipForCallLogs />}
                        firstBarColor="#0088FE"
                        secondBarColor="#00C49F"
                        thirdBarColor="#FFBB28"
                        forthBarColor="#ff2a00"
                        secondBar={true}
                        thirdBar={true}
                        isResponsive={true}
                        showXaxis={!isMobile}
                        data={data?.['outGoingCalls']}
                      />{' '}
                    </div>
                  )}
                </div>
              </div>
            </div>
          </>
        )}
      </div>
    </>
  );
};

export default memo(Dashboard);
