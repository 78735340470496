import React, { useState, useEffect } from 'react';
import { Checkbox, IconButton, LinearProgress, Tooltip } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import UserService from '../../services/user.service';
import ArrowDownward from '@mui/icons-material/ArrowDownward';
import ArrowUpward from '@mui/icons-material/ArrowUpward';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import '../../styles/Questionaires.css';
import '../../styles/Clients.css';
import {
  formatDate,
  formatDateCalendarFormat,
  isAuditor,
} from '../../common/utility';
import { logout } from '../../actions/auth';
import { SET_MESSAGE } from '../../actions/types';
import QuestionnaireCancelModal from '../Modals/QuestionnaireCancelModal/QuestionnaireCancelModal';

export default function Questionaires() {
  const dispatch = useDispatch();
  const [sortColumn, setSortColumn] = useState('Provider');
  const [sortDirection, setSortDirection] = useState('asc');

  const [clients, setClients] = useState([]);
  const [loading, setLoading] = useState(false);
  const [providers, setProviders] = useState([]);
  const [, setLocalLoading] = useState(true);
  const [selectedProvider, setSelectedProvider] = useState({});
  const [displayedClients, setDisplayedClients] = useState([]);
  const [expandedRows, setExpandedRows] = useState({});
  const [checkboxStates, setCheckboxStates] = useState({});
  const [specificConfirmationModalOpen, setSpecificConfirmationModalOpen] =
    useState(false);
  const [dateSelections, setDateSelections] = useState({});

  const isAdmin = (user) => user.roles.includes('ROLE_ADMIN');
  const isAdminOrAuditor = (user) => isAdmin(user) || isAuditor(user);

  const [anxietyDepressionPtsdModalOpen, setAnxietyDepressionPtsdModalOpen] =
    useState(false);
  const [anxietyDepressionPtsdResponse, setAnxietyDepressionPtsdResponse] =
    useState({});
  const [radioSelections] = useState({});
  const [users] = React.useState([]);
  const [selectedClient, setSelectedClient] = useState(null);
  const [, setSelectedUser] = React.useState();
  const [, setSelectedUserDetail] = React.useState();
  const [selectedClientUniqueKey, setSelectedClientUniqueKey] = useState(null);
  const [checkBoxPurpose, setCheckBoxPurpose] = useState('');
  const [triggerRequest, setTriggerRequest] = useState(false);
  const [dateFilter, setDateFilter] = useState('Overdue'); // Default to 'Overdue'
  const [cancelQuestionnaireModalOpen, setCancelQuestionnaireModalOpen] =
    useState(false);

  const { user: currentUser } = useSelector((state) => state.auth);

  const toggleClientDetail = (ta_mrn) => {
    setExpandedRows((prevExpandedRows) => ({
      ...prevExpandedRows,
      [ta_mrn]: !prevExpandedRows[ta_mrn],
    }));
  };

  // Inline styles using the `sx` prop
  const tooltipStyles = {
    fontSize: '1.2em', // Existing tooltip styles
  };

  const sortClients = (clients) => {
    if (!Array.isArray(clients)) return [];

    return [...clients].sort((a, b) => {
      let primaryValA, primaryValB, secondaryValA, secondaryValB;

      switch (sortColumn) {
        case 'Provider':
          primaryValA = a.PROVIDER.split(' ')[a.PROVIDER.split(' ').length - 2]; // Sorting by last name
          primaryValB = b.PROVIDER.split(' ')[b.PROVIDER.split(' ').length - 2];
          secondaryValA = `${a.LASTNAME} ${a.FIRSTNAME}`;
          secondaryValB = `${b.LASTNAME} ${b.FIRSTNAME}`;
          break;
        case 'Name':
          primaryValA = a.LASTNAME;
          primaryValB = b.LASTNAME;
          secondaryValA = a.FIRSTNAME;
          secondaryValB = b.FIRSTNAME;
          break;
        case 'Status':
          primaryValA = a.STATUS;
          primaryValB = b.STATUS;
          break;
        case 'Therapist':
          primaryValA = a.PROVIDER.split(' ')[a.PROVIDER.split(' ').length - 2];
          primaryValB = b.PROVIDER.split(' ')[b.PROVIDER.split(' ').length - 2];
          break;
        case 'Progress': // Case for sorting by progress
          primaryValA = calculateCompletionProgress(a); // Assuming this function returns a numeric progress percentage
          primaryValB = calculateCompletionProgress(b);
          break;
        default:
          return 0;
      }

      if (primaryValA < primaryValB) return sortDirection === 'asc' ? -1 : 1;
      if (primaryValA > primaryValB) return sortDirection === 'asc' ? 1 : -1;

      // Secondary sort logic (for Provider and Name)
      if (secondaryValA < secondaryValB) return -1;
      if (secondaryValA > secondaryValB) return 1;

      return 0;
    });
  };

  const filterClients = () => {
    const today = new Date();
    today.setHours(0, 0, 0, 0); // Normalize the time part to ensure consistent date comparison

    return sortClients(clients).filter((client) => {
      const dueDate = new Date(client.questionnaireAssignedDate);
      dueDate.setHours(0, 0, 0, 0);

      if (dateFilter === 'Overdue') {
        return dueDate <= today;
      } else if (dateFilter === 'Due In Future') {
        return dueDate > today;
      }
      return true; // For 'All' filter, return all clients
    });
  };

  const handleProviderChange = (event) => {
    const providerId = event.target.value;
    if (providerId === '') {
      // Case when "Please Select" is chosen
      setSelectedProvider({});
      setClients([]);
      setDisplayedClients([]);
      // Clear other states as necessary
    } else if (providerId === '1001') {
      // Case when "All" is chosen
      setSelectedProvider({ id: '1001' });
      fetchAndFilterAllClients('1001'); // Assuming fetchAndFilterAllClients can handle '1001' as "All"
    } else {
      // Case when a specific provider is selected
      const selected = providers.find((p) => p.id.toString() === providerId);
      setSelectedProvider(selected || {});
      fetchAndFilterAllClients(providerId);
    }
  };

  const handleDateChange = (ta_mrn, field, value) => {
    setDateSelections((prevDates) => ({
      ...prevDates,
      [ta_mrn]: {
        ...prevDates[ta_mrn],
        [field]: value,
      },
    }));
  };

  const handleCheckboxChange = (
    clientId,
    providerId,
    checkBoxPurpose,
    value
  ) => {
    // if checkbox is checked

    const uniqueKey = `${clientId}-${providerId}`; // Include the checkbox purpose in the unique key
    setSelectedClientUniqueKey(uniqueKey);

    const currentDate = moment().format('MM/DD/YY');

    switch (checkBoxPurpose) {
      case 'sentQuestionnaire':
        setSpecificConfirmationModalOpen(true);
        setCheckBoxPurpose(checkBoxPurpose);
        setCheckboxStates((prevState) => ({
          ...prevState,
          [uniqueKey]: {
            isQuestionnaireChecked: true,
            questionnaireCheckedDate: currentDate,
          },
        }));
        break;

      case 'clientCompletedQuestionnaire':
        setSpecificConfirmationModalOpen(true);
        setCheckBoxPurpose(checkBoxPurpose);
        setCheckboxStates((prevState) => ({
          ...prevState,
          [uniqueKey]: {
            questionnaireCompletedDate: value,
          },
        }));
        break;

      case 'scoreIncluded':
        setSpecificConfirmationModalOpen(true);
        setCheckBoxPurpose(checkBoxPurpose);
        setCheckboxStates((prevState) => ({
          ...prevState,
          [uniqueKey]: {
            scoreIncluded: value,
            scoreIncludedDate: currentDate,
          },
        }));
        break;
      default:
        break;
    }
  };

  const handleClientNameClick = (client) => {
    setSelectedClient(client);
    setCancelQuestionnaireModalOpen(true);
  };
  const calculateCompletionProgress = (client) => {
    let progress = 0;
    const stepContribution = 25;

    // Step 1: Check if diagnosisStatus is 1 (equivalent to "YES")
    if (client.diagnosisStatus === 1) {
      progress += stepContribution;
    }

    // Step 2: Questionnaire Sent
    if (client.questionnaireSent) {
      progress += stepContribution;
    }

    // Step 3: Questionnaire Completed by Client
    if (client.questionnaireCompletedDate) {
      progress += stepContribution;
    }

    // Step 4: Score Included in 96127 Note
    if (client.scoreIncluded) {
      progress += stepContribution;
    }

    return progress;
  };

  const handleConfirmSubmit = async () => {
    setSpecificConfirmationModalOpen(false);
    setLoading(true);

    try {
      switch (checkBoxPurpose) {
        case 'sentQuestionnaire':
          await UserService.updateSentQuestionnaireDate(
            selectedClientUniqueKey,
            {
              isQuestionnaireChecked:
                checkboxStates[selectedClientUniqueKey].isQuestionnaireChecked,
              questionnaireCheckedDate:
                checkboxStates[selectedClientUniqueKey]
                  .questionnaireCheckedDate,
            }
          );
          setLoading(false);
          setTriggerRequest((triggerRequest) => !triggerRequest);
          setCheckboxStates({});
          break;
        case 'clientCompletedQuestionnaire':
          await UserService.updateClientCompleteQuestionnaire(
            selectedClientUniqueKey,
            {
              questionnaireCompletedDate:
                checkboxStates[selectedClientUniqueKey]
                  .questionnaireCompletedDate,
            }
          );
          setLoading(false);
          setTriggerRequest((triggerRequest) => !triggerRequest);
          setCheckboxStates({});
          break;
        case 'scoreIncluded':
          await UserService.updateClientScore(selectedClientUniqueKey, {
            scoreIncluded:
              checkboxStates[selectedClientUniqueKey].scoreIncluded,
            scoreIncludedDate:
              checkboxStates[selectedClientUniqueKey].scoreIncludedDate,
          });
          setLoading(false);
          setTriggerRequest((triggerRequest) => !triggerRequest);
          setCheckboxStates({});
          break;
        default:
          return null;
      }
    } catch (error) {
      console.log('error', error.message);
    }
  };

  const handleCancelConfirmation = () => {
    setSpecificConfirmationModalOpen(false);

    switch (checkBoxPurpose) {
      case 'sentQuestionnaire':
        setCheckboxStates((prevState) => ({
          ...prevState,
          [selectedClientUniqueKey]: {
            isQuestionnaireChecked: null,
            questionnaireCheckedDate: null,
          },
        }));
        break;
      case 'clientCompletedQuestionnaire':
        setCheckboxStates((prevState) => ({
          ...prevState,
          [selectedClientUniqueKey]: {
            questionnaireCompletedDate: null,
          },
        }));
        break;
      case 'scoreIncluded':
        setCheckboxStates((prevState) => ({
          ...prevState,
          [selectedClientUniqueKey]: {
            scoreIncluded: null,
            scoreIncludedDate: null,
          },
        }));
        break;
      default:
        return null;
    }
  };

  // Define fetchAndFilterAllClients within the component
  const fetchAndFilterAllClients = async (providerId = undefined) => {
    setLoading(true);
    try {
      const response = await UserService.fetchClientsWithCriteria(
        providerId,
        ['Active', 'New'],
        ['Tricare Standard/Indemnity', 'Johns Hopkins USFHP']
      );
      setClients(response.data.clients);
      setDisplayedClients(response.data.clients); // Update displayedClients as well
    } catch (error) {
      console.error('Error fetching all clients:', error);

      // here have to make user logout of the application
      dispatch({
        type: SET_MESSAGE,
        payload:
          'You have been logged out something went wrong. Please click on login button to login back!',
      });
      dispatch(logout(currentUser));
    } finally {
      setLoading(false);
    }
  };

  // Function to toggle row expansion
  const toggleRowExpansion = (clientKey) => {
    setExpandedRows((prevExpandedRows) => {
      const updatedExpandedRows = {};

      // Toggle the value of the specified clientKey
      updatedExpandedRows[clientKey] = !prevExpandedRows[clientKey];

      // Set all other keys to false
      Object.keys(prevExpandedRows).forEach((key) => {
        if (key !== clientKey) {
          updatedExpandedRows[key] = false;
        }
      });

      return updatedExpandedRows;
    });
  };

  const onTagsChange = async (event, values) => {
    let enObj = users.find((obj) => {
      return obj?.id === values?.id;
    });
    setSelectedUser(enObj);
    if (enObj?.id) {
      const providerDetail = await UserService.getProviderDetail(
        enObj.id,
        enObj.email
      );
      if (providerDetail && providerDetail.data) {
        providerDetail.data.provider.username = enObj.username;
        setSelectedUserDetail(providerDetail?.data?.provider);
      }
    }
  };

  const handleClientUpdate = async (clientUpdate) => {
    try {
      const updateResponse = await UserService.updateClientColumns([
        clientUpdate,
      ]);
      console.log('Client updated successfully:', updateResponse.data);
      // Update the client data in your state
      setSelectedClient({
        ...selectedClient,
        PRIMINSURANCE:
          clientUpdate.primInsurance || selectedClient.PRIMINSURANCE,
        STATUS: clientUpdate.newStatus || selectedClient.STATUS,
      });
    } catch (error) {
      console.error('Error updating client:', error);
    }
  };

  const handleCancelSubmit = async (selectedOption, selectedInsurance) => {
    if (!selectedClient) {
      console.error('No client selected for update');
      return;
    }

    const details = {
      submittedBy: currentUser.username, // Assuming you have access to the current user
      submittedAt: new Date().toISOString(),
      actionDetails:
        selectedOption === 'option1'
          ? { newInsurance: selectedInsurance }
          : { madeInactive: true },
    };

    const clientUpdate = {
      clientId: selectedClient.ta_mrn,
      providerId: selectedClient.provider_id,
      cancellingQuestionnaireDetails: details,
    };

    if (selectedOption === 'option1') {
      // Update client primary insurance
      clientUpdate.primInsurance = selectedInsurance;
    } else if (selectedOption === 'option2') {
      // Update client status to Inactive
      clientUpdate.newStatus = 'Inactive';
    }

    await handleClientUpdate(clientUpdate);
    setCancelQuestionnaireModalOpen(false);
    setTriggerRequest((triggerRequest) => !triggerRequest);
  };

  useEffect(() => {
    // When "Please Select" is chosen, clear the clients.
    if (!selectedProvider.id) {
      setClients([]);
      setDisplayedClients([]);
      return; // Exit the effect early as there's nothing more to do.
    }

    setLoading(true);

    // If "All" (id='1001') is selected, fetch all clients.
    if (selectedProvider.id === '1001') {
      UserService.fetchClientsWithCriteria()
        .then((response) => {
          setClients(response.data.clients);
          setDisplayedClients(response.data.clients);
        })
        .catch((error) => {
          console.error('Error fetching all clients:', error);
          // Handle the error appropriately.
        })
        .finally(() => {
          setLoading(false);
        });
    }
    // Otherwise, fetch clients for the selected provider.
    else {
      UserService.fetchClientsWithCriteria(
        selectedProvider.id,
        ['Active', 'New'],
        ['Tricare Standard/Indemnity', 'Johns Hopkins USFHP']
      )
        .then((response) => {
          setClients(response.data.clients);
          setDisplayedClients(response.data.clients);
        })
        .catch((error) => {
          console.error(
            `Error fetching clients for provider ${selectedProvider.id}:`,
            error
          );
          // Handle the error appropriately.
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }, [selectedProvider]);

  useEffect(() => {
    if (Object.keys(dateSelections).length !== 0) {
      setSpecificConfirmationModalOpen(true);
    }
  }, [dateSelections]);

  useEffect(() => {
    UserService.getProviders().then(
      (response) => {
        let providers = response?.data?.providers ?? [];
        // Extract last name by finding the word before the comma
        if (providers?.length > 0) {
          providers.sort((a, b) => {
            const lastNameA = (a?.provider_name || '')
              .split(', ')[0]
              .split(' ')
              .slice(-1)[0]
              .toLowerCase();

            const lastNameB = (b?.provider_name || '')
              .split(', ')[0]
              .split(' ')
              .slice(-1)[0]
              .toLowerCase();

            return lastNameA.localeCompare(lastNameB);
          });
        }
        setProviders(providers);
      },
      (error) => {
        // Handle any errors here
        console.error('Error fetching providers:', error);
      }
    );
  }, []);

  useEffect(() => {
    setLocalLoading(true); // Start loading indication

    // Initialize fetch promise
    let fetchPromise;

    if (selectedProvider.id === '1001') {
      // Case for "ALL" providers: fetch data for all clients
      fetchPromise = UserService.fetchClientsWithCriteria(
        undefined, // Your API call might require undefined or a special parameter for fetching all clients
        ['Active', 'New'],
        ['Tricare Standard/Indemnity', 'Johns Hopkins USFHP']
      );
    } else if (selectedProvider.id) {
      // Case for a specific provider: fetch data for this provider
      fetchPromise = UserService.fetchClientsWithCriteria(
        selectedProvider.id,
        ['Active', 'New'],
        ['Tricare Standard/Indemnity', 'Johns Hopkins USFHP']
      );
    }

    if (fetchPromise) {
      fetchPromise
        .then((response) => {
          const newClients = response.data.clients || [];
          setClients(newClients);
          setDisplayedClients(newClients);

          // Set responses for anxiety/depression/PTSD
          const newResponses = {};
          newClients.forEach((client) => {
            const clientKey = `${client.ta_mrn}-${client.provider_id}`;
            // Here in the below condition we are verifying if the diagnosisStatus has actually any value init
            newResponses[clientKey] = {
              response: client.diagnosisStatus
                ? 'YES'
                : client.diagnosisStatusDate
                ? 'NO (Snoozed)'
                : null,
              date: client.diagnosisStatusDate,
            };
          });
          setAnxietyDepressionPtsdResponse(newResponses);
        })
        .catch((error) => {
          console.error(`Error fetching clients:`, error);
          setClients([]);
          setDisplayedClients([]);
        })
        .finally(() => {
          setLocalLoading(false); // End loading indication
        });
    } else {
      // If no provider is selected (e.g., initial state or "Please Select"), clear all client data
      setClients([]);
      setDisplayedClients([]);
      setAnxietyDepressionPtsdResponse({});
      setLocalLoading(false); // End loading indication
    }
  }, [selectedProvider, triggerRequest]);

  useEffect(() => {
    if (users.length > 1) {
      // not a doctor. so return
      return;
    }
    if (currentUser.roles.indexOf('ROLE_DOCTOR') > -1) {
      onTagsChange(null, users[0]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [users]);

  useEffect(() => {
    if (!isAdmin(currentUser)) {
      setSelectedProvider({
        ...currentUser,
        provider_name: currentUser.username,
      });
    }
  }, [currentUser]);

  const toggleSort = (column) => {
    if (sortColumn === column) {
      setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc');
    } else {
      setSortColumn(column);
      setSortDirection('asc');
    }
  };

  return (
    <div>
      <div className="select-filters-wrapper">
        <select
          value={dateFilter}
          onChange={(e) => setDateFilter(e.target.value)}
          className="filter-dropdown"
        >
          <option value="All">All</option>
          <option value="Overdue">Overdue</option>
          <option value="Due In Future">Due In Future</option>
        </select>

        {isAdminOrAuditor(currentUser) && (
          <select
            value={selectedProvider?.id || ''}
            onChange={handleProviderChange}
          >
            {/* Add a default option with an empty value */}
            <option value="">Please Select</option>

            <option key={1001} value={1001}>
              ALL
            </option>

            {providers
              .filter(
                (provider) =>
                  provider.provider_name && provider.provider_name.trim() !== ''
              )
              .map((provider) => (
                <option key={provider.id} value={provider.id}>
                  {provider.provider_name}
                </option>
              ))}
          </select>
        )}
      </div>

      {loading ? (
        <p>Loading...</p>
      ) : (
        <>
          <div className="mobile-questionnaire-list">
            {sortClients(displayedClients).map((client) => {
              const clientKey = `${client.ta_mrn}-${client.provider_id}`;
              // just a comment to push
              return (
                <div
                  className="mobile-questionnaire-card"
                  key={clientKey}
                  onClick={() => toggleClientDetail(clientKey)}
                >
                  <div className="questionnaire-client-name">{`${client.FIRSTNAME} ${client.LASTNAME}`}</div>
                  {expandedRows[clientKey] && (
                    <div className="questionnaire-client-details">
                      {/* Provider */}
                      <div className="centered-content">
                        <strong>Provider:</strong> {client.PROVIDER}
                      </div>

                      {/* Due Date to Assign Questionnaire(s) */}
                      <div className="centered-content">
                        <strong>Due Date to Assign Questionnaire(s):</strong>
                        {client.questionnaireAssignedDate
                          ? formatDate(client.questionnaireAssignedDate)
                          : 'Not Set'}
                      </div>

                      {/* Questionnaire(s) Sent */}
                      <div className="mobile-questionnaire-sent">
                        <strong>Questionnaire Sent?</strong>
                        <Checkbox
                          checked={
                            checkboxStates[clientKey]?.isQuestionnaireChecked ||
                            client.questionnaireSent ||
                            false
                          }
                          onChange={(e) => {
                            handleCheckboxChange(
                              client.ta_mrn,
                              client.provider_id,
                              'sentQuestionnaire',
                              e.target.checked
                            );
                            e.stopPropagation();
                          }}
                          onClick={(e) => e.stopPropagation()}
                          inputProps={{
                            'aria-label': 'Checkbox for questionnaire sent',
                          }}
                        />
                        {(checkboxStates[clientKey]?.isQuestionnaireChecked ||
                          client.questionnaireSent) && (
                          <div style={{ fontSize: '14px' }}>
                            Sent on{' '}
                            {formatDate(
                              checkboxStates[clientKey]
                                ?.questionnaireCheckedDate ||
                                client.questionnaireSentDate
                            )}
                          </div>
                        )}
                      </div>

                      {/* Date Client Completed Questionnaire */}
                      <div className="centered-content">
                        <label onClick={(e) => e.stopPropagation()}>
                          Date Client Completed Questionnaire:
                          <input
                            type="date"
                            value={formatDateCalendarFormat(
                              radioSelections[clientKey]
                                ?.questionnaireCompletedDate ||
                                client.questionnaireCompletedDate
                            )}
                            className="date-picker-input"
                            onChange={(e) => {
                              handleDateChange(
                                client.ta_mrn,
                                'questionnaireCompletedDate',
                                e.target.value
                              );
                              e.stopPropagation(); // Prevent the click from closing the card
                            }}
                            onClick={(e) => e.stopPropagation()} // Prevent the click from collapsing the card
                          />
                        </label>
                      </div>

                      {/* Score Included in 96217 Note */}
                      <div className="centered-content">
                        <label onClick={(e) => e.stopPropagation()}>
                          Score Included in 96127 Note?
                          <Checkbox
                            checked={
                              radioSelections[client.ta_mrn]?.scoreIncluded ||
                              false
                            }
                            onChange={(e) => {
                              handleCheckboxChange(
                                client.ta_mrn,
                                'scoreIncluded',
                                e.target.checked
                              );
                              e.stopPropagation(); // This stops the click from affecting parent elements.
                            }}
                            onClick={(e) => e.stopPropagation()} // Prevents the checkbox click from collapsing the card.
                            inputProps={{
                              'aria-label': 'Checkbox for score included',
                            }}
                          />
                        </label>
                      </div>
                      {/* Additional details if needed */}
                    </div>
                  )}
                </div>
              );
            })}
          </div>

          <table className="desktop-view" border="1">
            <thead>
              <tr>
                <th onClick={() => toggleSort('Expand')}>
                  <div className="header-title">Expand</div>
                </th>
                {/* Name column with sorting */}
                <th onClick={() => toggleSort('Name')}>
                  <div className="header-title">
                    Name
                    <ArrowUpward
                      style={{
                        color:
                          sortColumn === 'Name' && sortDirection === 'asc'
                            ? 'black'
                            : 'lightgray',
                      }}
                    />
                    <ArrowDownward
                      style={{
                        color:
                          sortColumn === 'Name' && sortDirection === 'desc'
                            ? 'black'
                            : 'lightgray',
                      }}
                    />
                  </div>
                </th>
                <th onClick={() => toggleSort('Progress')}>
                  <div className="header-title">
                    Progress
                    <ArrowUpward
                      style={{
                        color:
                          sortColumn === 'Progress' && sortDirection === 'asc'
                            ? 'black'
                            : 'lightgray',
                      }}
                    />
                    <ArrowDownward
                      style={{
                        color:
                          sortColumn === 'Progress' && sortDirection === 'desc'
                            ? 'black'
                            : 'lightgray',
                      }}
                    />
                  </div>
                </th>
                {/* Provider column with sorting */}
                {isAdminOrAuditor(currentUser) && (
                  <th onClick={() => toggleSort('Provider')}>
                    <div className="header-title">Provider</div>
                  </th>
                )}
                {/* Questionnaire(s) Assigned column with tooltip */}
                <th>
                  <div className="header-with-tooltip">
                    <div className="header-title">
                      Due Date to Assign Questionnaire(s)
                      <Tooltip
                        title="Date when the questionnaire was assigned"
                        sx={tooltipStyles}
                      >
                        <IconButton aria-label="help">
                          <HelpOutlineIcon />
                        </IconButton>
                      </Tooltip>
                    </div>
                  </div>
                </th>
                {/* Diagnosis column with tooltip */}
                <th>
                  <div className="header-with-tooltip">
                    <div className="header-title">
                      Anxiety, Depression OR PTSD?
                      <Tooltip
                        title="Does client meet one of the 3 diagnoses OR Snooze 60 Days"
                        sx={tooltipStyles}
                      >
                        <IconButton aria-label="help">
                          <HelpOutlineIcon />
                        </IconButton>
                      </Tooltip>
                    </div>
                  </div>
                </th>
                {/* Questionnaire(s) Completed by Client column with tooltip */}
                <th>
                  <div className="header-with-tooltip">
                    <div className="header-title">
                      Questionnaire(s) Sent?
                      <Tooltip
                        title="Click the checkbox when you have assigned the questionnaire(s)"
                        sx={tooltipStyles}
                      >
                        <IconButton aria-label="help">
                          <HelpOutlineIcon />
                        </IconButton>
                      </Tooltip>
                    </div>
                  </div>
                </th>
                {/* Score Included in Progress Note column with tooltip */}
                <th>
                  <div className="header-with-tooltip">
                    <div className="header-title">
                      Date Client Completed Questionnaire?
                      <Tooltip
                        title="Date when the score was included in the progress note"
                        sx={tooltipStyles}
                      >
                        <IconButton aria-label="help">
                          <HelpOutlineIcon />
                        </IconButton>
                      </Tooltip>
                    </div>
                  </div>
                </th>
                {/* Billed 96217 on Date of Service of Progress Note column with tooltip */}
                <th>
                  <div className="header-with-tooltip">
                    <div className="header-title">
                      Score Included in 96127 Note?
                      <Tooltip
                        title="Date when the service was billed"
                        sx={tooltipStyles}
                      >
                        <IconButton aria-label="help">
                          <HelpOutlineIcon />
                        </IconButton>
                      </Tooltip>
                    </div>
                  </div>
                </th>
              </tr>
            </thead>
            <tbody>
              {filterClients().map((client) => {
                const clientKey = `${client.ta_mrn}-${client.provider_id}`;
                const isSnoozed =
                  anxietyDepressionPtsdResponse[clientKey]?.response ===
                  'NO (Snoozed)';
                const lastQuestionnaire =
                  client.previousQuestionnaireValues?.[0] || {};
                const wasPreviouslySnoozed =
                  lastQuestionnaire.diagnosisStatus === 0; // Assuming '0' signifies 'No (Snoozed)'
                const progress = calculateCompletionProgress(client);
                const isPastDue =
                  new Date(client.questionnaireAssignedDate) < new Date();

                return (
                  <React.Fragment key={clientKey}>
                    {' '}
                    {/* Use React.Fragment with a key */}
                    <tr key={clientKey} className={isSnoozed ? 'snoozed' : ''}>
                      <td>
                        <button onClick={() => toggleRowExpansion(clientKey)}>
                          {expandedRows[clientKey] ? '▲' : '▼'}
                        </button>
                      </td>
                      <td
                        onClick={() => handleClientNameClick(client)}
                        style={{ cursor: 'pointer' }}
                      >
                        {`${client.FIRSTNAME} ${client.LASTNAME}`}
                      </td>
                      <td>
                        {isPastDue && (
                          <LinearProgress
                            variant="determinate"
                            value={progress}
                            sx={{
                              height: 8,
                              borderRadius: 5,
                              backgroundColor: 'red', // background for incomplete part
                              '& .MuiLinearProgress-bar1Determinate': {
                                borderRadius: 5,
                                backgroundColor: 'green', // color for completed part
                              },
                            }}
                          />
                        )}
                      </td>
                      {isAdminOrAuditor(currentUser) &&
                        selectedProvider?.id && (
                          <td colSpan={expandedRows[clientKey] ? 1 : 1}>
                            {client.PROVIDER}
                          </td>
                        )}
                      {/*NOTE THE BELOW VALUE LOGIC HAS TO BE CHANGED FOR NEW CLIENTS IN FUTURE*/}
                      <td className="centered-radio">
                        <span>
                          {client.questionnaireAssignedDate
                            ? formatDate(client.questionnaireAssignedDate)
                            : formatDate(
                                process.env.REACT_APP_DEFAULT_ASSIGN_DATE
                              )}
                        </span>
                      </td>
                      <td
                        onClick={() => {
                          setSelectedClientUniqueKey(clientKey);
                          setAnxietyDepressionPtsdModalOpen(true);
                        }}
                      >
                        <div
                          className={`response-cell ${
                            isSnoozed ? 'snoozed' : ''
                          }`}
                        >
                          {anxietyDepressionPtsdResponse[clientKey]?.response !=
                          null ? (
                            `${
                              anxietyDepressionPtsdResponse[clientKey].response
                            } on ${formatDate(
                              anxietyDepressionPtsdResponse[clientKey].date
                            )}`
                          ) : (
                            <strong
                              style={{
                                fontWeight: 'bold',
                                color: 'blue',
                                cursor: 'pointer',
                              }}
                            >
                              Click HERE to Select
                              {wasPreviouslySnoozed && (
                                <em
                                  style={{
                                    fontWeight: 'normal',
                                    color: '#999',
                                    fontSize: '0.9em',
                                    display: 'block',
                                  }}
                                >
                                  Previously Snoozed
                                </em>
                              )}
                            </strong>
                          )}
                        </div>
                      </td>
                      <td className="centered-radio">
                        <span>
                          <Checkbox
                            checked={
                              checkboxStates[clientKey]
                                ?.isQuestionnaireChecked ||
                              client.questionnaireSent ||
                              false
                            }
                            onChange={(e) =>
                              handleCheckboxChange(
                                client.ta_mrn,
                                client.provider_id,
                                'sentQuestionnaire',
                                e.target.checked
                              )
                            }
                            // disabled={isSnoozed}

                            // Disable checkbox if snoozed
                            // disabled={
                            //   !isAdmin(currentUser) ||
                            //   checkboxStates[clientKey]?.isQuestionnaireChecked ||
                            //   client.questionnaireSent
                            // }
                            inputProps={{
                              'aria-label': 'Checkbox for questionnaire sent',
                            }}
                          />
                          {(checkboxStates[clientKey]?.isQuestionnaireChecked ||
                            client.questionnaireSent) && (
                            <div style={{ fontSize: '14px' }}>
                              Sent on{' '}
                              {formatDate(
                                checkboxStates[clientKey]
                                  ?.questionnaireCheckedDate ||
                                  client.questionnaireSentDate
                              )}
                            </div>
                          )}
                        </span>
                      </td>
                      {/* Score Included in Progress Note */}
                      {/* Assuming scoreIncludedDate is the relevant field; adjust if necessary */}
                      <td className="centered-radio">
                        <span>
                          <input
                            type="date"
                            value={formatDateCalendarFormat(
                              checkboxStates[clientKey]
                                ?.questionnaireCompletedDate ||
                                client.questionnaireCompletedDate
                            )}
                            className="date-picker-input"
                            // disabled={
                            //   checkboxStates[clientKey]
                            //     ?.questionnaireCompletedDate ||
                            //   client.questionnaireCompletedDate
                            //     ? true
                            //     : false
                            // }
                            onChange={(e) =>
                              handleCheckboxChange(
                                client.ta_mrn,
                                client.provider_id,
                                'clientCompletedQuestionnaire',
                                e.target.value
                              )
                            }
                            // disabled={isSnoozed}
                            // Disable checkbox if snoozed
                          />
                        </span>
                      </td>
                      {/* Billed 96217 on Date of Service of Progress Note */}
                      {/* Assuming billedDate is the relevant field; adjust if necessary */}
                      <td className="centered-radio">
                        <span>
                          <Checkbox
                            checked={
                              checkboxStates[clientKey]?.scoreIncluded || false
                            }
                            onChange={(e) =>
                              handleCheckboxChange(
                                client.ta_mrn,
                                client.provider_id,
                                'scoreIncluded',
                                e.target.checked
                              )
                            }
                            // disabled={isSnoozed}

                            // Disable checkbox if snoozed
                            inputProps={{
                              'aria-label': 'Checkbox for score included',
                            }}
                          />
                        </span>
                      </td>
                    </tr>
                    {expandedRows[clientKey] && (
                      <>
                        {JSON.parse(
                          JSON.stringify(client.previousQuestionnaireValues)
                        )?.map((previousValuesObj, index) => {
                          return (
                            <tr key={index}>
                              <td></td>
                              <td>
                                {client.FIRSTNAME} {client.LASTNAME}
                              </td>
                              <td></td>{' '}
                              {/* Placeholder for Progress column which should be empty */}
                              <td>{client.PROVIDER}</td>
                              <td style={{ textAlign: 'center' }}>
                                {' ' +
                                  ' ' +
                                  formatDate(
                                    previousValuesObj?.questionnaireAssignedDate
                                  )}
                              </td>
                              <td style={{ textAlign: 'center' }}>
                                <div style={{ fontSize: '14px' }}>
                                  {previousValuesObj?.diagnosisStatus === true
                                    ? 'Yes on '
                                    : 'No on '}
                                  {formatDate(
                                    previousValuesObj?.diagnosisStatusDate
                                  )}
                                </div>
                              </td>
                              {/*The below I am putting a condition to check if value exists then show it otherwise hide it*/}
                              {previousValuesObj?.questionnaireSent &&
                              previousValuesObj?.questionnaireSentDate ? (
                                <td style={{ textAlign: 'center' }}>
                                  <div>
                                    <Checkbox
                                      checked={
                                        previousValuesObj?.questionnaireSent
                                      }
                                      disabled={true}
                                    ></Checkbox>
                                  </div>

                                  <div style={{ fontSize: '14px' }}>
                                    Sent on{' '}
                                    {formatDate(
                                      previousValuesObj?.questionnaireSentDate
                                    )}
                                  </div>
                                </td>
                              ) : (
                                <td>
                                  <div></div>
                                </td>
                              )}
                              {/* In the above showing empty div so that there is no UI change*/}
                              {previousValuesObj?.questionnaireCompletedDate ? (
                                <td style={{ textAlign: 'center' }}>
                                  <input
                                    type="date"
                                    value={formatDateCalendarFormat(
                                      previousValuesObj?.questionnaireCompletedDate
                                    )}
                                    className="date-picker-input"
                                    disabled={true}
                                  ></input>
                                </td>
                              ) : (
                                <td>
                                  <div></div>
                                </td>
                              )}
                              {previousValuesObj?.scoreIncluded ? (
                                <td style={{ textAlign: 'center' }}>
                                  <div>
                                    <Checkbox
                                      checked={previousValuesObj?.scoreIncluded}
                                      disabled={true}
                                    ></Checkbox>
                                  </div>

                                  <div style={{ fontSize: '14px' }}>
                                    Included on{' '}
                                    {formatDate(
                                      previousValuesObj?.scoreIncludedDate
                                    )}
                                  </div>
                                </td>
                              ) : (
                                <td>
                                  <div></div>
                                </td>
                              )}
                            </tr>
                          );
                        })}
                      </>
                    )}
                  </React.Fragment>
                );
              })}
            </tbody>
          </table>
        </>
      )}

      <ConfirmationModal
        isVisible={specificConfirmationModalOpen}
        onConfirm={handleConfirmSubmit}
        onCancel={handleCancelConfirmation}
      />

      <QuestionnaireCancelModal
        isQuestionnaireModalOpen={cancelQuestionnaireModalOpen}
        onCloseCancelModal={() => setCancelQuestionnaireModalOpen(false)}
        client={selectedClient}
        onClientUpdate={handleClientUpdate}
        onSubmitCancelModal={handleCancelSubmit}
      />

      <AnxietyDepressionPtsdModal
        isOpen={anxietyDepressionPtsdModalOpen}
        onClose={() => setAnxietyDepressionPtsdModalOpen(false)}
        onConfirm={(response) => {
          setLoading(true);
          const updatedResponse = {
            response,
            date: moment().format('MM/DD/YYYY'),
          };
          setAnxietyDepressionPtsdResponse((prevResponses) => ({
            ...prevResponses,
            [selectedClientUniqueKey]: updatedResponse,
          }));
          setAnxietyDepressionPtsdModalOpen(false);

          if (response === 'YES' || response === 'NO (Snoozed)') {
            const diagnosisStatus = response === 'YES'; // Translate 'YES' to true, 'NO (Snoozed)' to false
            UserService.updateDiagnosisStatus(selectedClientUniqueKey, {
              diagnosisStatus,
              diagnosisStatusDate: moment().format('YYYY-MM-DD'),
            })
              .then(() => {
                // Directly update the displayedClients state
                setDisplayedClients((currentDisplayedClients) => {
                  return currentDisplayedClients.map((client) => {
                    if (
                      `${client.ta_mrn}-${client.provider_id}` ===
                      selectedClientUniqueKey
                    ) {
                      // Update the client data here as per the changes done on the backend
                      return {
                        ...client,
                        diagnosisStatus, // Make sure your client object structure on the frontend matches the backend's expectations
                        diagnosisStatusDate: moment().format('YYYY-MM-DD'),
                        // If there are updates needed for previousQuestionnaireValues, add those here too
                      };
                    }
                    return client; // No changes for other clients
                  });
                });
              })
              .catch((error) => {
                // Handle any errors from the update call
                console.error('Error updating diagnosis status', error);
              })
              .finally(() => {
                setLoading(false);
                setTriggerRequest((triggerRequest) => !triggerRequest);
              });
          }
        }}
      />
    </div>
  );

  function ConfirmationModal({ isVisible, onConfirm, onCancel }) {
    if (!isVisible) return null;

    return (
      <div className="modal">
        <div className="modal-content">
          <p>Are you sure you want to confirm this selection?</p>
          <div className="modal-button-container">
            <button onClick={onConfirm} className="modal-button submit-button">
              Confirm
            </button>
            <button onClick={onCancel} className="modal-button cancel-button">
              Cancel
            </button>
          </div>
        </div>
      </div>
    );
  }

  function AnxietyDepressionPtsdModal({ isOpen, onClose, onConfirm }) {
    return isOpen ? (
      <div className="modal">
        <div className="modal-content">
          <p>
            Does this client have any of the following diagnoses - Anxiety,
            Depression, or PTSD (Please refer to the table below which Dx codes
            are included in each category) ?
          </p>
          <table className="diagnosis-table">
            <tbody>
              <tr>
                <th>Category</th>
                <th>Dx Codes</th>
              </tr>
              <tr>
                <td>Depression</td>
                <td>
                  F32.0-9, F33.0-9, F34.1, N94.3, F06.31, F06.32, F06.34,
                  F31.11-13, F31.31, F31.32, F31.4
                </td>
              </tr>
              <tr>
                <td>Anxiety</td>
                <td>F41.1</td>
              </tr>
              <tr>
                <td>PTSD</td>
                <td>F43.10</td>
              </tr>
            </tbody>
          </table>
          <div className="modal-button-container">
            <button
              onClick={() => onConfirm('YES')}
              className="modal-button submit-button"
            >
              {' '}
              YES
            </button>
            <button
              onClick={() => onConfirm('NO (Snoozed)')}
              className="modal-button snooze-button"
            >
              NO (Snooze)
            </button>
            <button onClick={onClose} className="modal-button cancel-button">
              CANCEL
            </button>
          </div>
        </div>
      </div>
    ) : null;
  }
}
