import React, { useState, useEffect, useMemo } from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import moment from 'moment';
import { TablePagination } from '@mui/material';
import UserService from '../../services/user.service';
import { useSnackbar } from 'notistack';
import {
  isCareCoordinator,
  hasCredentialingAccess,
} from '../../common/utility';
import { useSelector } from 'react-redux';

export default function ProviderGeneralTable({ showActiveProviders }) {
  const { enqueueSnackbar } = useSnackbar();
  const [providers, setProviders] = useState([]); // This captures both the state and its setter

  const [locations, setLocations] = useState([]);

  const { user: currentUser } = useSelector((state) => state.auth);

  const [, setLocationSet] = useState(false);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(50);

  const hasEmergencyContactAccess = (currentUser) => {
    return (
      // eslint-disable-next-line eqeqeq
      currentUser.id ==
        process.env.REACT_APP_USERID1_FOR_LOGS_AND_USER_MANAGE ||
      // eslint-disable-next-line eqeqeq
      currentUser.id ==
        process.env.REACT_APP_USERID2_FOR_LOGS_AND_USER_MANAGE ||
      isCareCoordinator(currentUser)
    );
  };

  function compareProviderFullName(a, b) {
    // Safely split the provider_name, default to empty string if provider_name is missing
    const lastNameA = a.provider_name
      ? a.provider_name.split(' ')[1] || ''
      : '';
    const lastNameB = b.provider_name
      ? b.provider_name.split(' ')[1] || ''
      : '';

    return lastNameA.localeCompare(lastNameB);
  }

  useEffect(() => {
    const status = showActiveProviders ? 'active' : 'inactive';

    UserService.getProviders(status)
      .then((response) => {
        // Filter out any providers with a null provider_name right after fetching
        const fetchedProviders =
          response?.data?.providers.filter(
            (provider) => provider.provider_name != null
          ) ?? [];

        return UserService.getAllUsers().then((usersResponse) => {
          const users = usersResponse?.data?.users ?? [];

          // Enrich providers with user details including imageUrl
          const providersWithImages = fetchedProviders.map((provider) => {
            const user = users.find((user) => user.id === provider.id);
            return {
              ...provider,
              imageUrl:
                user?.imageUrl ||
                'https://img.freepik.com/free-vector/doctor-character-background_1270-84.jpg',
            };
          });

          // Now proceed with fetching additional details for each provider and updating state
          // Convert each provider detail fetching into a promise
          const fetchDetailsPromises = providersWithImages.map((provider) =>
            getProviderDetail(provider.id, provider)
              .then((detail) => ({ ...provider, ...detail })) // Merge provider with its details
              .catch((error) => {
                console.error(
                  'Failed to fetch details for provider:',
                  provider.id,
                  error
                );
                return provider; // Return provider as is in case of error
              })
          );

          Promise.all(fetchDetailsPromises).then((completedProviders) => {
            // Further filter out any enriched providers if needed
            const validProviders = completedProviders.filter(
              (provider) => provider.provider_name != null
            );
            setLocations(validProviders); // Update locations with all fetched and valid details
            setLocationSet(true); // Indicate completion
          });
          setProviders(providersWithImages); // Update the providers state
        });
      })
      .catch((error) => {
        const errorMessage =
          error.response?.data?.message || error.message || error.toString();
        enqueueSnackbar(errorMessage, {
          variant: 'error',
          timeout: 3000,
        });
      });
  }, [showActiveProviders, enqueueSnackbar]); // Include alert if used within the effect

  // Adjust getProviderDetail to return details instead of setting state
  const getProviderDetail = async (id, provider) => {
    try {
      const response = await UserService.getTherapyConfig(id);
      const therapyLocations = response?.data?.therapy?.locations || '';
      const testingLocations = response?.data?.testing?.locations || '';
      const allLocations = [...testingLocations, ...therapyLocations].filter(
        Boolean
      );
      const uniqueLocations = [...new Set(allLocations)].join(',');

      return {
        location: uniqueLocations || ' ',
        last_attestation: response?.data?.last_attestation,
        last_roster_submission: response?.data?.last_roster_submission,
      };
    } catch (error) {
      console.error('Error fetching provider details:', error);
      // Return some default/fallback detail structure in case of error
      return {
        location: ' ',
        last_attestation: null,
        last_roster_submission: null,
      };
    }
  };

  useEffect(() => {
    // Convert each provider detail fetching into a promise
    const fetchDetailsPromises = providers.map((provider) =>
      getProviderDetail(provider.id, provider)
        .then((detail) => ({ ...provider, ...detail })) // Merge provider with its details
        .catch((error) => {
          console.error(
            'Failed to fetch details for provider:',
            provider.id,
            error
          );
          return provider; // Return provider as is in case of error
        })
    );

    Promise.all(fetchDetailsPromises).then((completedProviders) => {
      setLocations(completedProviders); // Update locations with all fetched details
      setLocationSet(true); // Indicate completion
    });
  }, [providers]); // Re-run when providers change

  const handleChangePage = (_, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0); // Reset the page to 0 when changing the rows per page
  };

  // ...

  const displayedProviders = useMemo(() => {
    // Sort providers just before rendering
    return locations
      .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
      .sort(compareProviderFullName);
  }, [locations, page, rowsPerPage]);

  // ...
  const tableHeaderCellStyle = {
    borderBottom: '0.5px solid black',
    fontWeight: '900',
    position: 'sticky',
    top: '0px',
    zIndex: 100,
    backgroundColor: '#F5F5F5',
  };
  const tableCellStyle = { borderBottom: '0.1px solid black' };

  return (
    <div>
      <TableContainer
        component={Paper}
        id="child"
        style={{ maxHeight: 'calc(100vh - 200px)', overflowY: 'auto' }}
      >
        <Table
          sx={{ minWidth: 650 }}
          size="medium"
          aria-label="Providers_table"
        >
          <TableHead>
            <TableRow>
              <TableCell
                style={{
                  ...tableHeaderCellStyle,
                }}
              >
                Full Name
              </TableCell>

              <TableCell
                style={{
                  ...tableHeaderCellStyle,
                }}
              >
                Image
              </TableCell>

              {showActiveProviders && (
                <TableCell
                  style={{
                    ...tableHeaderCellStyle,
                  }}
                >
                  PsychCare Email
                </TableCell>
              )}

              <TableCell
                style={{
                  ...tableHeaderCellStyle,
                }}
              >
                Personal Email
              </TableCell>

              {showActiveProviders && (
                <TableCell
                  style={{
                    ...tableHeaderCellStyle,
                  }}
                >
                  Ext
                </TableCell>
              )}

              <TableCell
                style={{
                  ...tableHeaderCellStyle,
                }}
              >
                Mobile #
              </TableCell>

              <TableCell
                style={{
                  ...tableHeaderCellStyle,
                }}
              >
                Date of Birth (Age)
              </TableCell>

              {hasCredentialingAccess(currentUser) && (
                <TableCell
                  style={{
                    ...tableHeaderCellStyle,
                  }}
                >
                  SS#
                </TableCell>
              )}
              {showActiveProviders && (
                <TableCell
                  style={{
                    ...tableHeaderCellStyle,
                  }}
                >
                  Home Address
                </TableCell>
              )}

              {showActiveProviders &&
                hasEmergencyContactAccess(currentUser) && (
                  <>
                    <TableCell
                      style={{
                        ...tableHeaderCellStyle,
                      }}
                    >
                      Emergency Contact Name
                    </TableCell>
                    <TableCell
                      style={{
                        ...tableHeaderCellStyle,
                      }}
                    >
                      Emergency Contact Relationship
                    </TableCell>
                    <TableCell
                      style={{
                        ...tableHeaderCellStyle,
                      }}
                    >
                      Emergency Contact Phone#
                    </TableCell>
                  </>
                )}
            </TableRow>
          </TableHead>
          <TableBody>
            {setLocationSet &&
              displayedProviders?.map((row, index) => {
                return (
                  <TableRow
                    key={index}
                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                  >
                    <TableCell style={tableCellStyle}>
                      <div
                        style={{
                          width: '140px',
                        }}
                      >
                        {row?.provider_name}
                      </div>
                    </TableCell>

                    <TableCell
                      style={{
                        ...tableCellStyle,
                        width: '150px',
                        minWidth: '150px',
                      }}
                    >
                      <img
                        src={
                          row.imageUrl ||
                          'https://img.freepik.com/free-vector/doctor-character-background_1270-84.jpg'
                        } // Use your default image URL
                        alt="Provider"
                        style={{ width: '100px', height: '100px' }}
                      />
                    </TableCell>
                    {showActiveProviders && (
                      <TableCell style={tableCellStyle}>
                        {row?.provider_email}
                      </TableCell>
                    )}

                    <TableCell style={tableCellStyle}>
                      {row?.personal_email}
                    </TableCell>
                    {showActiveProviders && (
                      <TableCell style={tableCellStyle}>{row?.ext}</TableCell>
                    )}

                    <TableCell style={tableCellStyle}>
                      {row?.mobile_number}
                    </TableCell>

                    <TableCell style={tableCellStyle}>
                      {moment.utc(row?.date_of_birth ?? '').format('M/D/YY')}
                      <br />(
                      {moment().diff(moment(row?.date_of_birth), 'years')} yrs)
                    </TableCell>

                    {hasCredentialingAccess(currentUser) && (
                      <TableCell style={tableCellStyle}>
                        {row?.ss_num}
                      </TableCell>
                    )}
                    {showActiveProviders && (
                      <TableCell style={tableCellStyle}>
                        {row?.address1 ||
                        row?.address2 ||
                        row?.city ||
                        row?.state ||
                        row?.zip ? (
                          <>
                            {`${row?.address1 || ''} ${
                              row?.address2 || ''
                            }`.trim()}
                            <br />
                            {`${row?.city || ''}, ${row?.state || ''} ${
                              row?.zip || ''
                            }`.trim()}
                          </>
                        ) : null}
                      </TableCell>
                    )}

                    {showActiveProviders &&
                      hasEmergencyContactAccess(currentUser) && (
                        <TableCell style={tableCellStyle}>
                          {row.emergencyContactName}
                        </TableCell>
                      )}

                    {showActiveProviders &&
                      hasEmergencyContactAccess(currentUser) && (
                        <TableCell style={tableCellStyle}>
                          {row.emergencyContactRelationship === 'Other'
                            ? row.customRelationship
                            : row.emergencyContactRelationship}
                        </TableCell>
                      )}
                    {showActiveProviders &&
                      hasEmergencyContactAccess(currentUser) && (
                        <TableCell style={tableCellStyle}>
                          {row.emergencyContactPhone}
                        </TableCell>
                      )}
                  </TableRow>
                );
              })}
          </TableBody>
        </Table>
      </TableContainer>

      <TablePagination
        component="div"
        count={providers.length}
        page={page}
        onPageChange={handleChangePage}
        rowsPerPage={rowsPerPage}
        onRowsPerPageChange={handleChangeRowsPerPage}
        labelRowsPerPage="Rows per page:"
      />
    </div>
  );
}
