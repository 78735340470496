import React, { useCallback, useEffect, useState } from 'react';
import {
  Autocomplete,
  Button,
  Box,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  InputLabel,
  ListItemText,
  MenuItem,
  Modal,
  OutlinedInput,
  Radio,
  RadioGroup,
  Select,
  Tab,
  TextField,
  Typography,
} from '@mui/material';
import { DateTimePicker, DatePicker } from '@mui/x-date-pickers';
import { TabContext, TabList, TabPanel } from '@mui/lab';
import { Col, Row } from 'reactstrap';
import { useSelector } from 'react-redux';
import { Calendar, momentLocalizer } from 'react-big-calendar';
import { useSnackbar } from 'notistack';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import UserService from '../../services/user.service';
import EventBus from '../../common/EventBus';
import moment from 'moment';
import { durations, isAdmin, isCareCoordinator } from '../../common/utility';
import '../../styles/UpdateAvailability.css';

const eventPropGetter = (event) => {
  let backgroundColor = event.color;

  // Check if the event is a remote session
  if (!backgroundColor && event.setting === 'Remote Only') {
    backgroundColor = '#ddd600'; // Set to yellow for remote sessions
  } else if (backgroundColor === 'Yellow') {
    backgroundColor = '#ddd600'; // Convert 'Yellow' to its equivalent color code
  }

  return {
    style: { backgroundColor: `${backgroundColor}` },
  };
};

const getColorsForLocations = (locations) => {
  const numLocations = locations.length;
  const colors = [];

  switch (numLocations) {
    case 1:
      const location = locations[0];
      switch (location) {
        case 'Silver Spring':
          colors.push('Green');
          break;
        case 'Baltimore':
          colors.push('Purple');
          break;
        case 'Columbia':
          colors.push('Blue');
          break;
        case 'Remote':
          colors.push('Yellow');
          break;
        default:
          colors.push('');
      }
      break;
    case 2:
      colors.push('Green', 'Purple');
      break;
    case 3:
      colors.push('Green', 'Purple', 'Blue');
      break;
    case 4:
      colors.push('Green', 'Purple', 'Blue', 'Yellow');
      break;
    default:
      colors.push('');
  }

  return colors;
};

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 50,
    },
  },
};

const UpdateAvailability = () => {
  const { enqueueSnackbar } = useSnackbar();

  const [activeEvent, setActiveEvent] = React.useState({});
  const [allEvents, setAllEvents] = useState([]);
  const [providersOptions, setProvidersOptions] = React.useState([]);
  const [selectedProvider, setSelectedProvider] = React.useState();
  const [showCustomAlert, setShowCustomAlert] = useState(false);
  const locations = ['Silver Spring', 'Baltimore', 'Columbia'];

  const colors = ['Green', 'Blue', 'Purple', 'Yellow'];
  const [selectedLocation, setSelectedLocation] = React.useState([]);
  const [selectedColor, setSelectedColor] = React.useState([]);
  const [service, setService] = React.useState('Therapy');
  const [type, setType] = React.useState('Open');
  const [selectedStartDate, setSelectedStartDate] = useState('');
  const [selectedEndDate, setSelectedEndDate] = useState('');
  const [selectedEventType, setSelectedEventType] = React.useState();
  const [setting, setSetting] = useState('Remote Only');

  const [note, setNote] = useState();
  const [tabIndex, setTabIndex] = useState('0');
  const frequencies = {
    weekly: 'Weekly',
    every_other_week: 'Every Other Week',
    floater: 'Floater',
  };
  const [selectedFrequency, setSelectedFrequency] = React.useState(['weekly']);

  const [confirmModalOpen, setConfirmModalOpen] = useState(false);

  const [selectedDuration, setSelectedDuration] = React.useState(['60']);
  const localizer = momentLocalizer(moment);

  const handleSelectedEndDate = (event) => {
    setSelectedEndDate(event.target.value);
  };

  const handleChangeSetting = (event) => {
    setSetting(event.target.value);
    let st = event.target.value;
    // 'Remote Only', 'In-Person Only', 'Any Setting'
    if (st === 'Remote Only') {
      setSelectedColor(['Yellow']);
    } else if (st === 'In-Person Only') {
    } else if (st === 'Any Setting') {
    } else {
      setSelectedColor([]);
    }
  };
  const handleChangeActiveEventSetting = (event) => {
    let color = null;
    let st = event.target.value;
    // 'Remote Only', 'In-Person Only', 'Any Setting'
    if (st === 'Remote Only') {
      color = ['Yellow'];
      activeEvent.location = [];
    } else if (st === 'In-Person Only') {
    } else if (st === 'Any Setting') {
    } else {
      color = [];
    }
    setActiveEvent({ ...activeEvent, setting: event.target.value, color });
  };
  const handleChangeLocation = (event) => {
    const {
      target: { value },
    } = event;
    setSelectedLocation(typeof value === 'string' ? value.split(',') : value);
    const colors = getColorsForLocations(value.split(','));
    setSelectedColor(colors);
  };

  const handleChangeActiveEventLocation = (event) => {
    const {
      target: { value },
    } = event;
    let location = typeof value === 'string' ? value.split(',') : value;
    let color = '';
    if (value === 'Silver Spring') color = ['Green'];
    else if (value === 'Baltimore') color = ['Purple'];
    else if (value === 'Columbia') color = ['Blue'];
    else if (value === 'Remote') color = ['Yellow'];
    setActiveEvent({ ...activeEvent, color, location });
  };

  const handleChangeColor = (event) => {
    const {
      target: { value },
    } = event;
    setSelectedColor(typeof value === 'string' ? value.split(',') : value);
  };

  const handleChangeActiveEventColor = (event) => {
    const {
      target: { value },
    } = event;
    let color = typeof value === 'string' ? value.split(',') : value;
    setActiveEvent({ ...activeEvent, color });
  };

  const handleChangeFrequency = (event) => {
    const {
      target: { value },
    } = event;
    setSelectedFrequency(typeof value === 'string' ? value.split(',') : value);
  };
  const handleChangeDuration = (event) => {
    const {
      target: { value },
    } = event;
    setSelectedDuration(typeof value === 'string' ? value.split(',') : value);
  };

  const { user: currentUser } = useSelector((state) => state.auth);
  const [open, setOpen] = React.useState(false);
  const [holdCreators, setHoldCreators] = React.useState([]); // This State for seeing holdCreators
  const [selectedHoldCreator, setSelectedHoldCreator] = useState(''); // This State for selected hold creator
  const [holdForClient, setHoldForClient] = useState(''); // This State for setting client for which hold is created
  const [holdCreationDate, setHoldCreationDate] = useState(); // This State for setting hold creation date for client

  const handleClose = () => setOpen(false);
  const handleOpen = () => setOpen(true);

  const [openEdit, setEditOpen] = React.useState(false);
  const handleCloseEdit = () => setEditOpen(false);

  const [availabilityDetailOpen, setAvailabilityDetailOpen] =
    React.useState(false);
  const handleAvailabilityDetailClose = () => setAvailabilityDetailOpen(false);
  const handleAvailabilityDetailOpen = () => setAvailabilityDetailOpen(true);

  const handleSelectSlot = useCallback(
    ({ start }) => {
      // Format the start date to the appropriate format for a datetime-local input
      const formattedStartDate = moment(start).format('YYYY-MM-DDTHH:mm');

      // Set the formatted start date in your state
      setSelectedStartDate(formattedStartDate);
      setSelectedColor(['Yellow']); // Set Yellow as the default color for Remote Only

      // Open the modal for confirmation
      setOpen(true);
    },
    [setSelectedStartDate, setOpen]
  );

  const handleSelectEvent = useCallback((event) => {
    const stDate = moment(event.slot_start_date_time).format(
      'YYYY/MM/DD HH:mm:ss'
    );
    const endDate = moment(event.slot_end_date_time).format(
      'YYYY/MM/DD HH:mm:ss'
    );
    // setSelectedEventType()
    setActiveEvent({
      ...event,
      slot_start_date_time: stDate,
      slot_end_date_time: endDate,
    });
    // setActiveEvent(event)
    handleAvailabilityDetailOpen();
  }, []);

  useEffect(() => {
    // Fetch users from the service.
    UserService.getAllProviders().then(
      (response) => {
        let users = response?.data?.users ?? [];
        let holdCreators = users.filter(
          (user) => user.roleId === 3 || user.roleId === 6
        );
        setHoldCreators(
          holdCreators.sort((a, b) => a.username.localeCompare(b.username))
        );

        // If the current user is a doctor, only show their own schedule.
        if (
          currentUser &&
          !(isAdmin(currentUser) || isCareCoordinator(currentUser))
        ) {
          setProvidersOptions([currentUser]); // ProvidersOptions contains only the current user.
          setSelectedProvider(currentUser); // Automatically select the current user.
          fetchProviderAvailabilityAndSlots(currentUser.id); // Fetch the availability for the current user only.
        } else {
          users = users.filter(
            // The below filtering provider and only active provider
            (user) => user.roleId === 5 && user.providerActiveStatus === 1
          );
          users.sort((a, b) => {
            const lastNameA = a.username.split(' ')[1] ?? '';
            const lastNameB = b.username.split(' ')[1] ?? '';
            return lastNameA.localeCompare(lastNameB);
          });
          // For non-doctors, show all providers including the dummy 'All' entry.
          let dummyAllProvider = {
            id: 0,
            username: 'All',
            email: null,
            password: null,
            createdAt: null,
            updatedAt: null,
            roleId: 5,
            userId: 0,
          };
          users = users.filter((user) => user.roleId === 5);
          users.unshift(dummyAllProvider); // Insert the dummy 'All' provider at the start of the list.
          setProvidersOptions(users); // ProvidersOptions contains all providers for non-doctors.
          setSelectedProvider(dummyAllProvider); // Automatically select the 'All' option for non-doctors.
          fetchProviderAvailabilityAndSlots(0); // Fetch the availability for all providers.
        }
      },
      (error) => {
        // Handle errors here.
        if (error.response && error.response.status === 401) {
          EventBus.dispatch('logout');
        }
      }
    );
  }, [currentUser]);

  useEffect(() => {
    const time = moment(selectedStartDate, 'YYYY-MM-DDTHH:mm')
      .add(selectedDuration, 'm')
      .toDate();
    setSelectedEndDate(moment(time).format('YYYY-MM-DDTHH:mm'));
  }, [selectedDuration, selectedStartDate]);

  const onTagsChange = async (event, values) => {
    let enObj = providersOptions.find((obj) => {
      return obj?.id === values?.id;
    });
    setSelectedProvider(enObj);
    if (enObj?.id >= 0) {
      await fetchProviderAvailabilityAndSlots(enObj.id);
    }
  };

  const fetchProviderAvailabilityAndSlots = async (id) => {
    UserService.getProviderAvailabilityAndSlots(id).then(
      (response) => {
        let providerSlots = response?.data?.slots ?? [];

        providerSlots = providerSlots.map((providerSlot) => {
          const slot = {
            ...providerSlot,
            end_date_time: new Date(
              moment.utc(providerSlot.end_date_time).format('llll')
            ),
            slot_end_date_time: new Date(
              moment.utc(providerSlot.slot_end_date_time).format('llll')
            ),
            slot_start_date_time: new Date(
              moment.utc(providerSlot.slot_start_date_time).format('llll')
            ),
            start_date_time: new Date(
              moment.utc(providerSlot.start_date_time).format('llll')
            ),
          };
          return slot;
        });
        setAllEvents(providerSlots);
      },
      (error) => {
        const _content =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();

        console.log('_content', _content);

        if (error.response && error.response.status === 401) {
          EventBus.dispatch('logout');
        }
      }
    );
  };
  const handleAddAvailability = () => {
    setConfirmModalOpen(true);
  };

  const handleConfirmAddAvailability = async () => {
    try {
      // Convert selected dates to JavaScript Date objects for comparison
      const selectedStartDateTime = new Date(selectedStartDate);
      const selectedEndDateTime = new Date(selectedEndDate);

      // Check if an event with the same date, time, and provider already exists
      const isDuplicateEvent = allEvents.some((e) => {
        const isDuplicate =
          +e.start_date_time === +selectedStartDateTime &&
          +e.end_date_time === +selectedEndDateTime &&
          e.provider_id === selectedProvider?.id &&
          e.status === 'created'; // Adjusted the status check to "created"
        return isDuplicate;
      });

      if (isDuplicateEvent) {
        setShowCustomAlert(true); // Display the custom alert
        return;
      }

      // Continue with the rest of the existing logic
      if (
        selectedProvider?.id &&
        selectedStartDate &&
        selectedEndDate &&
        selectedFrequency?.[0] &&
        setting &&
        (setting === 'Remote Only' || selectedLocation?.[0])
      ) {
        if (selectedStartDate > selectedEndDate) {
          enqueueSnackbar('Start date must be less than End date.', {
            variant: 'error',
            timeout: 3000,
          });
          return;
        }
        await UserService.addProviderAvailability(selectedProvider.id, {
          email: selectedProvider?.provider_email,
          provider_id: selectedProvider?.id,
          slot_start_date_time: moment.utc(selectedStartDate),
          slot_end_date_time: moment.utc(selectedEndDate),
          frequency: selectedFrequency[0],
          location: selectedLocation[0],
          note: note,
          color: selectedColor[0],
          service,
          type,
          setting,
          person_creating_hold: selectedHoldCreator || null,
          clientInitials: holdForClient || null,
          holdCreationDate: holdCreationDate || null,
          actionBy: currentUser.username,
        });
        enqueueSnackbar('Added Provider availability successfully', {
          variant: 'success',
          timeout: 3000,
        });
        handleClose();
        // reset the fields in modal
        setSelectedFrequency(['weekly']); // Reset to the initial frequency
        setSelectedLocation([]);
        setSelectedColor([]);
        setSelectedStartDate(null);
        setSelectedEndDate(null);
        setNote('');
        setSetting('Remote Only'); // Reset to the initial setting
        await fetchProviderAvailabilityAndSlots(selectedProvider?.id);
      } else {
        enqueueSnackbar('Please select required fields.', {
          variant: 'error',
          timeout: 3000,
        });
      }
    } catch (error) {
      enqueueSnackbar(error.message, {
        variant: 'error',
        timeout: 3000,
      });
    }
  };

  const deleteEvent = async (event) => {
    try {
      await UserService.deleteEvent(selectedProvider.id, {
        id: activeEvent.id,
        eventType: selectedEventType,
      });
    } catch (error) {
      console.log('deleteEvent.error', error);
    }
  };
  const updateEvent = async (event) => {
    try {
      await UserService.updateEvent(selectedProvider.id, {
        activeEvent,
      });
      enqueueSnackbar('Successfully updated!', {
        variant: 'success',
        timeout: 3000,
      });
    } catch (error) {
      console.log('editEvent.error', error);
    }
  };

  const handleChangeEventType = (event) => {
    const eventType = event.target.value;
    setSelectedEventType(eventType);
  };

  const getEventTitleAccessor = (event) => {
    let provider = providersOptions.filter((provider) => {
      return provider.id === event.provider_id;
    });
    // eslint-disable-next-line no-unused-vars
    let note = '';
    let dateStr = moment(event.start_date_time).format('h:mmA');
    // eslint-disable-next-line
    if (event.note) note = ` | ${event.note}`;
    let username = provider?.[0]?.username;
    if (username && username.split(' ').length > 1) {
      let name = username.split(',')[0];
      username = name.split(' ')[name.split(' ').length - 1];
    }
    let frequency = event.frequency === 'every_other_week' ? 'EOW ' : '';
    let typeIcon = event.type === 'Hold' ? '🤚 ' : '';
    let noteIcon = '';
    if (
      event.type !== 'Hold' ||
      (event.type === 'Hold' && event.note === null)
    ) {
      noteIcon = event.note ? '📝 ' : '';
    }
    if (event.service === 'Testing') {
      return (
        <div style={{ border: '5px solid red', borderRadius: '5px' }}>
          <span>
            {typeIcon}
            {frequency}
            {noteIcon}
            {dateStr} | {username}
          </span>
        </div>
      );
    } else {
      return (
        <div>
          <span>
            {typeIcon}
            {frequency}
            {noteIcon}
            {dateStr} | {username}
            {/* {note} */}
          </span>
        </div>
      );
    }
  };
  const shouldShowAddButton = () => {
    if (type === 'Hold') {
      if (selectedHoldCreator && holdForClient && holdCreationDate) {
        return true;
      } else {
        return false;
      }
    } else {
      return true;
    }
  };

  const shouldShowEditButton = () => {
    if (activeEvent.type === 'Hold') {
      if (
        activeEvent.person_creating_hold &&
        activeEvent.clientInitials &&
        activeEvent.holdCreationDate
      ) {
        return true;
      } else {
        return false;
      }
    } else {
      return true;
    }
  };

  return (
    <>
      <React.Fragment>
        <div className="mainContainer">
          <Grid container spacing={3}>
            {(isAdmin(currentUser) || isCareCoordinator(currentUser)) && (
              <Grid item xs={3}>
                <Autocomplete
                  disablePortal
                  defaultValue={{
                    id: 0,
                    username: 'All',
                    email: null,
                    password: null,
                    createdAt: null,
                    updatedAt: null,
                    roleId: 5,
                    userId: 0,
                  }}
                  id="provider"
                  options={providersOptions}
                  onChange={onTagsChange}
                  renderInput={(params) => (
                    <TextField
                      variant="outlined"
                      {...params}
                      name="provider_"
                      label="Provider"
                    />
                  )}
                  getOptionLabel={(option) => option.username || ''}
                />
              </Grid>
            )}

            {selectedProvider && selectedProvider.id !== 0 && (
              <Grid item xs={2}>
                <Button variant="contained" onClick={handleOpen}>
                  ADD AVAILABILITY
                </Button>
              </Grid>
            )}
          </Grid>

          <div
            style={{
              border: '2px solid black',
              padding: '10px',
              marginTop: '-6%',
              marginBottom: '2%',
              maxWidth: '40%',
              marginLeft: 'auto',
            }}
          >
            {/* <Typography variant="subtitle2" style={{ marginBottom: '10px', fontSize: '16px' }}>Legend</Typography> */}

            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                marginBottom: '10px',
              }}
            >
              <span style={{ fontWeight: 'bold' }}>Service:</span>
              <div
                style={{
                  backgroundColor: 'white',
                  padding: '5px',
                  border: '5px solid red',
                  marginLeft: '10px',
                  marginRight: '5px',
                }}
              >
                Psychological Testing
              </div>
              <div
                style={{
                  backgroundColor: 'white',
                  padding: '5px',
                  boxShadow: '0px 0px 10px 5px rgba(0,0,0,0.2)',
                  marginRight: '5px',
                }}
              >
                Therapy
              </div>
            </div>

            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                marginBottom: '10px',
              }}
            >
              <span style={{ fontWeight: 'bold' }}>Location:</span>
              <div
                style={{
                  backgroundColor: 'purple',
                  width: '10px',
                  height: '10px',
                  marginLeft: '10px',
                  marginRight: '5px',
                }}
              />
              <span>Baltimore</span>
              <div
                style={{
                  backgroundColor: 'blue',
                  width: '10px',
                  height: '10px',
                  marginLeft: '10px',
                  marginRight: '5px',
                }}
              />
              <span>Columbia</span>
              <div
                style={{
                  backgroundColor: 'yellow',
                  width: '10px',
                  height: '10px',
                  marginLeft: '10px',
                  marginRight: '5px',
                }}
              />
              <span>Remote</span>
              <div
                style={{
                  backgroundColor: 'green',
                  width: '10px',
                  height: '10px',
                  marginLeft: '10px',
                  marginRight: '5px',
                }}
              />
              <span>Silver Spring</span>
            </div>

            <div style={{ display: 'flex', alignItems: 'center' }}>
              <span style={{ fontWeight: 'bold' }}>Icon:</span>
              <Typography
                component="span"
                style={{
                  marginLeft: '10px',
                  marginRight: '5px',
                  fontFamily: 'sans-serif',
                  fontSize: '16px',
                }}
              >
                <span role="img" aria-label="note">
                  🤚 Hold{' '}
                </span>
              </Typography>

              <Typography
                component="span"
                style={{
                  marginLeft: '10px',
                  marginRight: '5px',
                  fontFamily: 'sans-serif',
                  fontSize: '16px',
                }}
              >
                <span role="img" aria-label="note">
                  📝 Note{' '}
                </span>
              </Typography>
            </div>
          </div>

          {selectedProvider && (
            <div className="calendar-container">
              <Calendar
                step={15}
                timeslots={4} // 4 timeslots in an hour (15 minutes each)
                selectable
                localizer={localizer}
                events={allEvents}
                // titleAccessor={((event) => `${event.note} | ${event.note}`)}
                titleAccessor={getEventTitleAccessor}
                startAccessor={(event) => event.start_date_time}
                endAccessor={(event) => event.end_date_time}
                style={{ height: 1000, margin: '10px' }}
                onSelectEvent={handleSelectEvent}
                // onSelectSlot={handleSelectSlot}
                onSelectSlot={
                  selectedProvider && selectedProvider.id === 0
                    ? undefined
                    : handleSelectSlot
                }
                eventPropGetter={eventPropGetter}
                min={new Date(0, 0, 0, 6)} // Sets the minimum time to 6 AM
                max={new Date(0, 0, 0, 22)} // Sets the maximum time to 10 PM
                // selectable
              />
            </div>
          )}
        </div>

        {/*ADD AVAILABILITY MODAL*/}

        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box className="modal-box">
            <Typography id="modal-modal-title" variant="h6" component="h2">
              Add Availability Details
            </Typography>

            <FormControl sx={{ width: 320, margin: 2 }}>
              <InputLabel id="demo-simple-select-label">Duration</InputLabel>

              <Select
                labelId="demo-multiple-checkbox-label"
                name="frequency"
                value={selectedDuration}
                onChange={handleChangeDuration}
                input={<OutlinedInput label="duration" />}
                renderValue={(selected) =>
                  selected?.map((v) => durations[v]).join(', ')
                }
                MenuProps={MenuProps}
              >
                {Object.keys(durations).map((key, index) => (
                  <MenuItem key={index} value={key}>
                    <Checkbox checked={selectedDuration.indexOf(key) > -1} />
                    <ListItemText primary={durations[key]} />
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            <FormControl
              sx={{
                width: 320,
                margin: 2,
              }}
            >
              <div className="form-group">
                <input
                  type="datetime-local"
                  className="form-control"
                  name="appointmentDate"
                  value={selectedStartDate}
                  onChange={(event) => setSelectedStartDate(event.target.value)}
                  style={{
                    border: selectedStartDate
                      ? '1px solid #ced4da'
                      : '1px solid red',
                    borderRadius: '4px',
                    padding: '8px',
                  }}
                />
              </div>
            </FormControl>

            <FormControl sx={{ width: 320, margin: 4 }}>
              <FormLabel id="demo-multiple-checkbox-label">Type</FormLabel>
              <RadioGroup
                row
                labelId="demo-multiple-checkbox-label"
                name="type"
                value={type}
                onChange={(e) => {
                  if (e.target.value === 'Open') {
                    setSelectedHoldCreator('');
                    setHoldForClient('');
                    setHoldCreationDate(null);
                  }
                  setType(e.target.value);
                }}
                // renderValue={(selected) => selected.join(', ')}
              >
                {['Open', 'Hold'].map((s, index) => (
                  <FormControlLabel
                    value={s}
                    control={<Radio />}
                    label={s}
                    key={index}
                  />
                ))}
              </RadioGroup>
            </FormControl>
            <FormControl sx={{ width: 320, margin: 2 }}>
              <InputLabel id="demo-multiple-checkbox-label">Service</InputLabel>
              <Select
                labelId="demo-multiple-checkbox-label"
                name="location"
                value={service}
                onChange={(e) => setService(e.target.value)}
                input={<OutlinedInput label="apptTypes" />}
                // renderValue={(selected) => selected.join(', ')}
                MenuProps={MenuProps}
              >
                {['Therapy', 'Testing'].map((s, index) => (
                  <MenuItem value={s} key={index}>
                    {s}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            <FormControl
              sx={{
                width: 320,
                margin: 2,
                border: setting ? '1px solid #ced4da' : '1px solid red',
                borderRadius: '4px',
              }}
            >
              <InputLabel id="demo-multiple-checkbox-label">Setting</InputLabel>
              <Select
                labelId="demo-multiple-checkbox-label"
                name="Setting"
                value={setting}
                onChange={handleChangeSetting}
                input={<OutlinedInput label="apptTypes" />}
                // renderValue={(selected) => selected.join(', ')}
                MenuProps={MenuProps}
              >
                {['Remote Only', 'In-Person Only', 'Any Setting'].map(
                  (s, index) => (
                    <MenuItem value={s} key={index}>
                      {s}
                    </MenuItem>
                  )
                )}
              </Select>
            </FormControl>

            {setting && setting !== 'Remote Only' && (
              <FormControl
                sx={{
                  width: 320,
                  margin: 2,
                  border:
                    selectedLocation.length > 0
                      ? '1px solid #ced4da'
                      : '1px solid red',
                  borderRadius: '4px',
                }}
              >
                <InputLabel id="demo-multiple-checkbox-label">
                  Location
                </InputLabel>
                <Select
                  labelId="demo-multiple-checkbox-label"
                  name="location"
                  value={selectedLocation}
                  onChange={handleChangeLocation}
                  input={<OutlinedInput label="apptTypes" />}
                  // renderValue={(selected) => selected.join(', ')}
                  MenuProps={MenuProps}
                >
                  {locations.map((masterObj, index) => (
                    <MenuItem key={index} value={masterObj}>
                      <Checkbox
                        checked={selectedLocation.indexOf(masterObj) > -1}
                      />
                      <ListItemText primary={masterObj} />
                    </MenuItem>
                  ))}
                </Select>
                {/* {formValues['insurances'].length == 0 && <FormHelperText>Required field</FormHelperText>} */}
              </FormControl>
            )}

            <FormControl
              sx={{
                width: 320,
                margin: 2,
                border:
                  selectedFrequency.length > 0
                    ? '1px solid #ced4da'
                    : '1px solid red',
                borderRadius: '4px',
              }}
            >
              <InputLabel id="demo-multiple-checkbox-label">
                Frequency
              </InputLabel>
              <Select
                labelId="demo-multiple-checkbox-label"
                name="frequency"
                value={selectedFrequency}
                onChange={handleChangeFrequency}
                input={<OutlinedInput label="frequency" />}
                MenuProps={MenuProps}
              >
                {Object.keys(frequencies).map((key, index) => (
                  <MenuItem key={key + index} value={key}>
                    <Checkbox checked={selectedFrequency.indexOf(key) > -1} />
                    <ListItemText primary={frequencies[key]} />
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            <FormControl
              sx={{ width: 320, margin: 2 }}
              // error={formValues['insurances'].length>0?false:true}
            >
              <TextField
                // required
                autoComplete="off"
                fullWidth
                variant="outlined"
                id="note"
                label="Note"
                value={note}
                onChange={(event) => setNote(event.target.value)}
              />
            </FormControl>

            {type === 'Hold' && (
              <div>
                <FormControl
                  sx={{
                    width: 320,
                    margin: 2,
                    border:
                      selectedHoldCreator?.length === 0
                        ? '1px solid red'
                        : '1px solid #ced4da',
                    borderRadius: '4px',
                    '&:hover': {
                      border: '1px solid #ced4da',
                    },
                  }}
                >
                  <InputLabel id="demo-multiple-checkbox-label">
                    Person Creating Hold
                  </InputLabel>
                  <Select
                    labelId="demo-multiple-checkbox-label"
                    name="holdCreators"
                    value={selectedHoldCreator}
                    onChange={(e) => setSelectedHoldCreator(e.target.value)}
                    input={<OutlinedInput label="HoldCreators" />}
                    MenuProps={MenuProps}
                  >
                    {holdCreators.map((obj, index) => (
                      <MenuItem key={index} value={obj.username}>
                        <Checkbox
                          checked={
                            selectedHoldCreator?.indexOf(obj.username) > -1
                          }
                        />
                        <ListItemText primary={obj.username} />
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>

                <FormControl
                  sx={{
                    width: 320,
                    margin: 2,
                    border:
                      holdForClient?.length === 0
                        ? '1px solid red'
                        : '1px solid #ced4da',
                    borderRadius: '4px',
                    '&:hover': {
                      border: '1px solid #ced4da',
                    },
                  }}
                >
                  <TextField
                    required
                    autoComplete="off"
                    placeholder="type N/A if not related to a specific client or type Unknown if the client’s name is not known"
                    fullWidth
                    variant="outlined"
                    id="Initials-of-Client"
                    label="Initials of Client"
                    onChange={(event) => {
                      setHoldForClient(event.target.value);
                    }}
                    value={holdForClient}
                    InputProps={{
                      inputProps: {
                        maxLength: 8, // Maximum length of 8 characters
                      },
                    }}
                  />
                </FormControl>

                <div style={{ display: 'flex', flexDirection: 'column' }}>
                  <label style={{ marginBottom: '8px' }}>
                    Hold Creation Date
                  </label>
                  <DatePicker
                    variant="inline"
                    inputVariant="outlined"
                    format="MM/dd/yyyy"
                    value={holdCreationDate}
                    name="custom_date_docs_due"
                    InputAdornmentProps={{ position: 'start' }}
                    onChange={(date) => setHoldCreationDate(date)}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    style={{
                      width: '100%', // Make it full width relative to its container
                      border: holdCreationDate
                        ? '1px solid #ced4da'
                        : '1px solid red',
                      borderRadius: '4px',
                    }}
                  />
                </div>
              </div>
            )}

            <Row style={{ margin: 14 }} xs="12">
              <Col xs="6"></Col>
              <Col xs="6">
                <Row style={{ right: 0, position: 'absolute' }}>
                  <Col xs="6">
                    <Button
                      variant="contained"
                      onClick={async (e) => {
                        setOpen(false);
                      }}
                    >
                      CANCEL
                    </Button>
                  </Col>
                  <Col xs="6">
                    {shouldShowAddButton() && (
                      <Button
                        variant="contained"
                        onClick={handleAddAvailability}
                      >
                        ADD
                      </Button>
                    )}
                  </Col>
                </Row>
              </Col>
            </Row>

            {showCustomAlert && (
              <div
                style={{
                  position: 'absolute',
                  top: 0,
                  left: 0,
                  width: '100%',
                  height: '100%',
                  backgroundColor: 'rgba(0,0,0,0.7)',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  zIndex: 10, // Ensure it's on top of other modal content
                }}
              >
                <div
                  style={{
                    backgroundColor: 'white',
                    padding: '20px',
                    borderRadius: '8px',
                    textAlign: 'center',
                  }}
                >
                  <p style={{ color: 'red', fontSize: '1.5rem' }}>
                    Availability already exists for this provider at the
                    specified date and time.
                  </p>
                  <button
                    onClick={() => setShowCustomAlert(false)}
                    style={{
                      padding: '10px 20px',
                      fontSize: '1rem',
                      margin: '10px auto',
                      display: 'block',
                    }}
                  >
                    OK
                  </button>
                </div>
              </div>
            )}
          </Box>
        </Modal>

        <Modal
          open={confirmModalOpen}
          onClose={() => setConfirmModalOpen(false)}
          aria-labelledby="confirm-modal-title"
          aria-describedby="confirm-modal-description"
        >
          <Box className="confirm-modal-box">
            <Typography
              id="confirm-modal-title"
              variant="h6"
              component="h2"
              sx={{ textAlign: 'center', marginBottom: 4 }} // Added bottom margin
            >
              Confirm Details
            </Typography>

            {/* Summary Elements */}
            <Typography variant="subtitle1" gutterBottom>
              Provider: {selectedProvider?.username || 'Not selected'}
            </Typography>
            <Typography variant="subtitle1" gutterBottom>
              Type: {type}
            </Typography>
            <Typography variant="subtitle1" gutterBottom>
              Service: {service}
            </Typography>
            <Typography variant="subtitle1" gutterBottom>
              Setting: {setting}
            </Typography>
            {setting !== 'Remote Only' && (
              <Typography variant="subtitle1" gutterBottom>
                Location: {selectedLocation.join(', ')}
              </Typography>
            )}
            <Typography variant="subtitle1" gutterBottom>
              Starting Date:{' '}
              {selectedStartDate
                ? new Date(selectedStartDate).toLocaleString('en-US', {
                    year: 'numeric',
                    month: 'numeric',
                    day: 'numeric',
                    hour: '2-digit',
                    minute: '2-digit',
                  })
                : 'Not Set'}
            </Typography>
            <Typography variant="subtitle1" gutterBottom>
              Frequency:{' '}
              {selectedFrequency.map((freq) => frequencies[freq]).join(', ')}
            </Typography>

            {/* Additional details for Hold type */}
            {type === 'Hold' && (
              <>
                <Typography variant="subtitle1" gutterBottom>
                  Note: {note || 'Not Provided'}
                </Typography>
                <Typography variant="subtitle1" gutterBottom>
                  Initials of Client: {holdForClient || 'Not Provided'}
                </Typography>
              </>
            )}

            {/* Confirmation and Go Back Buttons */}
            <Row style={{ margin: '30px 14px 14px' }} xs="12">
              {' '}
              {/* Increased top margin */}
              <Col xs="6">
                <Button
                  variant="contained"
                  onClick={() => setConfirmModalOpen(false)}
                >
                  GO BACK
                </Button>
              </Col>
              <Col xs="6">
                <Button
                  variant="contained"
                  onClick={() => {
                    handleConfirmAddAvailability();
                    setConfirmModalOpen(false);
                  }}
                >
                  CONFIRM
                </Button>
              </Col>
            </Row>
          </Box>
        </Modal>

        {/*This below is the edit modal*/}
        <Modal
          open={openEdit}
          onClose={handleCloseEdit}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box className="modal-box">
            <Typography id="modal-modal-title" variant="h6" component="h2">
              Edit Availability Details
            </Typography>

            <FormControl sx={{ width: 320, margin: 2 }}>
              <InputLabel id="demo-simple-select-label">Duration</InputLabel>

              <Select
                name="frequency"
                value={selectedDuration}
                onChange={handleChangeDuration}
                input={<OutlinedInput label="duration" />}
                renderValue={(selected) =>
                  selected?.map((v) => durations[v]).join(', ')
                }
                MenuProps={MenuProps}
              >
                {Object.keys(durations).map((key, index) => (
                  <MenuItem key={index} value={key}>
                    <Checkbox checked={selectedDuration.indexOf(key) > -1} />
                    <ListItemText primary={durations[key]} />
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            <FormControl sx={{ width: 320, margin: 2 }}>
              <div className="form-group">
                <input
                  type="datetime-local"
                  className="form-control"
                  name="appointmentDate"
                  value={selectedStartDate}
                  onChange={(event) => {
                    setSelectedStartDate(event.target.value);
                  }}
                />
              </div>
            </FormControl>

            <FormControl sx={{ width: 320, margin: 2 }}>
              <div className="form-group">
                <input
                  type="datetime-local"
                  className="form-control"
                  name="appointmentDate"
                  value={moment(selectedEndDate).format('YYYY-MM-DDTHH:mm')}
                  onChange={handleSelectedEndDate}
                />
              </div>
            </FormControl>

            <FormControl sx={{ width: 320, margin: 2 }}>
              <InputLabel id="demo-multiple-checkbox-label">
                Location
              </InputLabel>
              <Select
                labelId="demo-multiple-checkbox-label"
                name="location"
                value={selectedLocation}
                onChange={handleChangeLocation}
                input={<OutlinedInput label="apptTypes" />}
                renderValue={(selected) => selected.join(', ')}
                MenuProps={MenuProps}
              >
                {locations.map((masterObj, index) => (
                  <MenuItem key={index} value={masterObj}>
                    <Checkbox
                      checked={selectedLocation.indexOf(masterObj) > -1}
                    />
                    <ListItemText primary={masterObj} />
                  </MenuItem>
                ))}
              </Select>
              {/* {formValues['insurances'].length == 0 && <FormHelperText>Required field</FormHelperText>} */}
            </FormControl>

            <FormControl sx={{ width: 320, margin: 2 }}>
              <InputLabel id="demo-multiple-checkbox-label">Color</InputLabel>
              <Select
                labelId="demo-multiple-checkbox-label"
                name="color"
                value={selectedColor}
                onChange={handleChangeColor}
                input={<OutlinedInput label="apptTypes" />}
                renderValue={(selected) => selected.join(', ')}
                MenuProps={MenuProps}
              >
                {colors.map((masterObj, index) => (
                  <MenuItem key={index} value={masterObj}>
                    <Checkbox checked={selectedColor.indexOf(masterObj) > -1} />
                    <ListItemText primary={masterObj} />
                  </MenuItem>
                ))}
              </Select>
              {/* {formValues['insurances'].length == 0 && <FormHelperText>Required field</FormHelperText>} */}
            </FormControl>

            <FormControl
              sx={{ width: 320, margin: 2 }}
              // error={formValues['insurances'].length>0?false:true}
            >
              <TextField
                // required
                autoComplete="off"
                fullWidth
                variant="outlined"
                id="note"
                label="Note"
                value={note}
                onChange={(event) => setNote(event.target.value)}
              />
            </FormControl>

            <FormControl
              sx={{ width: 320, margin: 2 }}
              // error={formValues['insurances'].length>0?false:true}
            >
              <InputLabel id="demo-multiple-checkbox-label">
                Frequency
              </InputLabel>
              <Select
                labelId="demo-multiple-checkbox-label"
                name="frequency"
                value={selectedFrequency}
                onChange={handleChangeFrequency}
                input={<OutlinedInput label="frequency" />}
                renderValue={(selected) =>
                  selected?.map((v) => frequencies[v]).join(', ')
                }
                MenuProps={MenuProps}
              >
                {Object.keys(frequencies).map((key, index) => (
                  <MenuItem key={index} value={key}>
                    <Checkbox checked={selectedFrequency.indexOf(key) > -1} />
                    <ListItemText primary={frequencies[key]} />
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <Row style={{ margin: 14 }} xs="12">
              <Col xs="6"></Col>
              <Col xs="6">
                <Row style={{ right: 0, position: 'absolute' }}>
                  <Col xs="6">
                    <Button
                      variant="contained"
                      onClick={async (e) => {
                        setOpen(false);
                      }}
                    >
                      CANCEL
                    </Button>
                  </Col>
                  <Col xs="6">
                    <Button variant="contained" onClick={handleAddAvailability}>
                      ADD
                    </Button>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Box>
        </Modal>

        {/* EVENT EDIT/DELETE MODAL*/}
        <Modal
          open={availabilityDetailOpen}
          onClose={handleAvailabilityDetailClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box className="modal-box">
            <TabContext value={tabIndex}>
              <TabList onChange={(e, newValue) => setTabIndex(newValue)}>
                <Tab label="Delete" value="0" />
                <Tab label="Edit" value="1" />
              </TabList>

              <TabPanel value="0">
                <Typography id="modal-modal-title" variant="h6" component="h2">
                  Event Details
                </Typography>

                <FormControl sx={{ width: 320, margin: 2 }}>
                  <DateTimePicker
                    value={activeEvent.start_date_time}
                    onChange={(newValue) => {
                      setActiveEvent((prevActiveEvent) => ({
                        ...prevActiveEvent,
                        start_date_time: newValue,
                      }));
                    }}
                    label="Start Date"
                    minutesStep={5}
                    renderInput={(props) => <TextField {...props} />}
                  />
                </FormControl>

                <FormControl
                  sx={{ width: 320, margin: 2 }}
                  // error={formValues['insurances'].length>0?false:true}
                >
                  <DateTimePicker
                    value={activeEvent.end_date_time}
                    disabled
                    // disablePast
                    // onChange={handleSelectedEndDate}
                    label="End Date"
                    // showTodayButton
                  />
                </FormControl>

                <FormControl
                  sx={{
                    width: 320,
                    margin: 2,
                  }}
                >
                  <InputLabel id="demo-multiple-checkbox-label">
                    Setting
                  </InputLabel>
                  <Select
                    disabled
                    labelId="demo-multiple-checkbox-label"
                    name="location"
                    value={activeEvent.setting}
                    onChange={handleChangeActiveEventSetting}
                    input={<OutlinedInput label="apptTypes" />}
                    // renderValue={(selected) => selected.join(', ')}
                    MenuProps={MenuProps}
                  >
                    <MenuItem value={null}>
                      <em>None</em>
                    </MenuItem>
                    {['Remote Only', 'In-Person Only', 'Any Setting'].map(
                      (s, index) => (
                        <MenuItem value={s} key={index}>
                          {s}
                        </MenuItem>
                      )
                    )}
                  </Select>
                </FormControl>

                <FormControl sx={{ width: 320, margin: 2 }}>
                  <InputLabel id="demo-multiple-checkbox-label">
                    Color
                  </InputLabel>
                  <Select
                    labelId="demo-multiple-checkbox-label"
                    name="color"
                    value={selectedColor}
                    onChange={handleChangeColor}
                    input={<OutlinedInput label="apptTypes" />}
                    renderValue={(selected) => selected.join(', ')}
                    MenuProps={MenuProps}
                  >
                    {colors.map((masterObj, index) => (
                      <MenuItem key={masterObj + index} value={masterObj}>
                        <Checkbox
                          checked={selectedColor.indexOf(masterObj) > -1}
                        />
                        <ListItemText primary={masterObj} />
                      </MenuItem>
                    ))}
                  </Select>
                  {/* {formValues['insurances'].length == 0 && <FormHelperText>Required field</FormHelperText>} */}
                </FormControl>

                <FormControl
                  sx={{ width: 320, margin: 2 }}
                  // error={formValues['insurances'].length>0?false:true}
                >
                  <TextField
                    // required
                    disabled
                    autoComplete="off"
                    fullWidth
                    variant="outlined"
                    id="note"
                    label="Note"
                    value={activeEvent?.note}
                    // onChange={(event) => setNote(event.target.value)}
                  />
                </FormControl>

                <FormControl sx={{ width: 320, margin: 2 }}>
                  <InputLabel id="demo-multiple-checkbox-label">
                    Service
                  </InputLabel>
                  <Select
                    disabled
                    labelId="demo-multiple-checkbox-label"
                    name="location"
                    value={activeEvent.service}
                    onChange={(e) =>
                      setActiveEvent({
                        ...activeEvent,
                        service: e.target.value,
                      })
                    }
                    input={<OutlinedInput label="apptTypes" />}
                    // renderValue={(selected) => selected.join(', ')}
                    MenuProps={MenuProps}
                  >
                    {['Therapy', 'Testing'].map((s, index) => (
                      <MenuItem value={s} key={index}>
                        {s}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>

                <FormControl sx={{ width: 320, margin: 2 }}>
                  <InputLabel id="demo-multiple-checkbox-label">
                    Setting
                  </InputLabel>
                  <Select
                    disabled
                    labelId="demo-multiple-checkbox-label"
                    name="location"
                    value={activeEvent.setting}
                    onChange={handleChangeActiveEventSetting}
                    input={<OutlinedInput label="apptTypes" />}
                    // renderValue={(selected) => selected.join(', ')}
                    MenuProps={MenuProps}
                  >
                    <MenuItem value={null}>
                      <em>None</em>
                    </MenuItem>
                    {['Remote Only', 'In-Person Only', 'Any Setting'].map(
                      (s, index) => (
                        <MenuItem value={s} key={index}>
                          {s}
                        </MenuItem>
                      )
                    )}
                  </Select>
                </FormControl>

                <FormControl sx={{ width: 320, margin: 2 }}>
                  <FormLabel id="demo-multiple-checkbox-label">Type</FormLabel>
                  <RadioGroup
                    row
                    labelId="demo-multiple-checkbox-label"
                    name="type"
                    value={activeEvent.type}
                    onChange={(e) =>
                      setActiveEvent({ ...activeEvent, type: e.target.value })
                    }
                    // renderValue={(selected) => selected.join(', ')}
                  >
                    {['Open', 'Hold'].map((s, index) => (
                      <FormControlLabel
                        disabled
                        value={s}
                        control={<Radio />}
                        label={s}
                        key={index}
                      />
                    ))}
                  </RadioGroup>
                </FormControl>

                {/*Here i need to add*/}
                {activeEvent.type === 'Hold' && (
                  <div>
                    <FormControl
                      sx={{
                        width: 320,
                        margin: 2,
                      }}
                    >
                      <InputLabel id="demo-multiple-checkbox-label">
                        Person Creating Hold
                      </InputLabel>
                      <Select
                        disabled
                        labelId="demo-multiple-checkbox-label"
                        name="holdCreators"
                        value={activeEvent.person_creating_hold}
                        input={<OutlinedInput label="HoldCreators" />}
                        MenuProps={MenuProps}
                      >
                        {holdCreators.map((obj, index) => (
                          <MenuItem key={index} value={obj.username}>
                            <Checkbox
                              checked={
                                activeEvent.person_creating_hold?.indexOf(
                                  obj.username
                                ) > -1
                              }
                            />
                            <ListItemText primary={obj.username} />
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>

                    <FormControl
                      sx={{
                        width: 320,
                        margin: 2,
                      }}
                    >
                      <TextField
                        required
                        autoComplete="off"
                        placeholder="type N/A if not related to a specific client or type Unknown if the client’s name is not known"
                        fullWidth
                        variant="outlined"
                        id="Initials-of-Client"
                        label="Initials of Client"
                        disabled
                        value={activeEvent.clientInitials}
                        InputProps={{
                          inputProps: {
                            maxLength: 8, // Maximum length of 8 characters
                          },
                        }}
                      />
                    </FormControl>

                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                      <label style={{ marginBottom: '8px' }}>
                        Hold Creation Date
                      </label>
                      <DatePicker
                        variant="inline"
                        inputVariant="outlined"
                        format="MM/dd/yyyy"
                        value={
                          activeEvent.holdCreationDate
                            ? new Date(activeEvent.holdCreationDate)
                            : null
                        }
                        name="custom_date_docs_due"
                        InputAdornmentProps={{ position: 'start' }}
                        disabled
                        InputLabelProps={{
                          shrink: true,
                        }}
                        style={{
                          width: '100%',
                        }}
                      />
                    </div>
                  </div>
                )}

                <FormControl sx={{ width: 320, margin: 2 }}>
                  <RadioGroup
                    aria-label="Gender"
                    name="type"
                    // className={classes.group}
                    value={selectedEventType}
                    onChange={handleChangeEventType}
                  >
                    <FormControlLabel
                      value="This Event"
                      control={<Radio />}
                      label="This Event"
                    />
                    <FormControlLabel
                      value="All Events"
                      control={<Radio />}
                      label="All Events"
                    />
                    <FormControlLabel
                      value="Future Events"
                      control={<Radio />}
                      label="Future Events"
                    />
                  </RadioGroup>
                </FormControl>

                <Row style={{ margin: 14 }} xs="12">
                  <Col xs="6"></Col>
                  <Col xs="6">
                    <Row style={{ right: 0, position: 'absolute' }}>
                      <Col xs="6">
                        <Button
                          variant="contained"
                          onClick={async (e) => {
                            setAvailabilityDetailOpen(false);
                          }}
                        >
                          CANCEL
                        </Button>
                      </Col>
                      <Col xs="6">
                        <Button
                          variant="contained"
                          onClick={async (e) => {
                            if (selectedEventType) {
                              await deleteEvent();
                              setAvailabilityDetailOpen(false);
                              await fetchProviderAvailabilityAndSlots(
                                selectedProvider?.id
                              );
                            } else {
                              enqueueSnackbar(
                                'Please select required fields.',
                                {
                                  variant: 'error',
                                  timeout: 3000,
                                }
                              );
                            }
                          }}
                        >
                          Delete
                        </Button>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </TabPanel>

              <TabPanel value="1">
                <Typography id="modal-modal-title" variant="h6" component="h2">
                  Event Details
                </Typography>

                <FormControl
                  sx={{ width: 320, margin: 2 }}
                  // error={formValues['insurances'].length>0?false:true}
                >
                  <DateTimePicker
                    value={activeEvent.start_date_time}
                    // disablePast
                    disabled
                    onChange={() => {
                      // handleSelectedStartDate
                    }}
                    label="Start Date"
                    minutesStep={5}
                    // showTodayButton
                  />
                </FormControl>

                <FormControl
                  sx={{ width: 320, margin: 2 }}
                  // error={formValues['insurances'].length>0?false:true}
                >
                  <DateTimePicker
                    value={activeEvent.end_date_time}
                    disabled
                    // disablePast
                    // onChange={handleSelectedEndDate}
                    label="End Date"
                    // showTodayButton
                  />
                </FormControl>

                <FormControl
                  sx={{ width: 320, margin: 2 }}
                  // error={formValues['insurances'].length>0?false:true}
                >
                  <InputLabel id="demo-multiple-checkbox-label">
                    Frequency
                  </InputLabel>
                  <Select
                    labelId="demo-multiple-checkbox-label"
                    name="frequency"
                    disabled
                    value={[activeEvent.frequency]}
                    onChange={handleChangeFrequency}
                    input={<OutlinedInput label="frequency" />}
                    renderValue={(selected) =>
                      selected?.map((v) => frequencies[v]).join(', ')
                    }
                    MenuProps={MenuProps}
                  >
                    {Object.keys(frequencies).map((key, index) => (
                      <MenuItem key={index} value={key}>
                        <Checkbox
                          checked={activeEvent.frequency?.indexOf(key) > -1}
                        />
                        <ListItemText primary={frequencies[key]} />
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>

                {activeEvent.setting !== 'Remote Only' && (
                  <FormControl sx={{ width: 320, margin: 2 }}>
                    <InputLabel id="demo-multiple-checkbox-label">
                      Location
                    </InputLabel>
                    <Select
                      labelId="demo-multiple-checkbox-label"
                      name="location"
                      // disabled
                      value={[activeEvent?.location]}
                      onChange={handleChangeActiveEventLocation}
                      input={<OutlinedInput label="apptTypes" />}
                      renderValue={(selected) => selected.join(', ')}
                      MenuProps={MenuProps}
                    >
                      {locations.map((masterObj, index) => (
                        <MenuItem key={index} value={masterObj}>
                          <Checkbox
                            checked={
                              activeEvent?.location?.indexOf(masterObj) > -1
                            }
                          />
                          <ListItemText primary={masterObj} />
                        </MenuItem>
                      ))}
                    </Select>
                    {/* {formValues['insurances'].length == 0 && <FormHelperText>Required field</FormHelperText>} */}
                  </FormControl>
                )}

                <FormControl sx={{ width: 320, margin: 2 }}>
                  <InputLabel id="demo-multiple-checkbox-label">
                    Color
                  </InputLabel>
                  <Select
                    // disabled
                    labelId="demo-multiple-checkbox-label"
                    name="color"
                    value={[activeEvent?.color]}
                    onChange={handleChangeActiveEventColor}
                    input={<OutlinedInput label="apptTypes" />}
                    renderValue={(selected) => selected.join(', ')}
                    MenuProps={MenuProps}
                  >
                    {colors.map((masterObj, index) => (
                      <MenuItem key={index} value={masterObj}>
                        <Checkbox
                          checked={activeEvent?.color?.indexOf(masterObj) > -1}
                        />
                        <ListItemText primary={masterObj} />
                      </MenuItem>
                    ))}
                  </Select>
                  {/* {formValues['insurances'].length == 0 && <FormHelperText>Required field</FormHelperText>} */}
                </FormControl>

                <FormControl
                  sx={{ width: 320, margin: 2 }}
                  // error={formValues['insurances'].length>0?false:true}
                >
                  <TextField
                    // required
                    // disabled
                    autoComplete="off"
                    fullWidth
                    variant="outlined"
                    id="note"
                    label="Note"
                    value={activeEvent?.note}
                    onChange={(event) =>
                      setActiveEvent({
                        ...activeEvent,
                        note: event.target.value,
                      })
                    }
                  />
                </FormControl>

                <FormControl sx={{ width: 320, margin: 2 }}>
                  <InputLabel id="demo-multiple-checkbox-label">
                    Service
                  </InputLabel>
                  <Select
                    labelId="demo-multiple-checkbox-label"
                    name="location"
                    value={activeEvent.service}
                    onChange={(e) =>
                      setActiveEvent({
                        ...activeEvent,
                        service: e.target.value,
                      })
                    }
                    input={<OutlinedInput label="apptTypes" />}
                    // renderValue={(selected) => selected.join(', ')}
                    MenuProps={MenuProps}
                  >
                    {['Therapy', 'Testing'].map((s, index) => (
                      <MenuItem value={s} key={index}>
                        {s}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
                <FormControl sx={{ width: 320, margin: 2 }}>
                  <InputLabel id="demo-multiple-checkbox-label">
                    Setting
                  </InputLabel>
                  <Select
                    labelId="demo-multiple-checkbox-label"
                    name="location"
                    value={activeEvent.setting}
                    onChange={handleChangeActiveEventSetting}
                    input={<OutlinedInput label="apptTypes" />}
                    // renderValue={(selected) => selected.join(', ')}
                    MenuProps={MenuProps}
                  >
                    <MenuItem value={null}>
                      <em>None</em>
                    </MenuItem>
                    {['Any Setting', 'In-Person Only', 'Remote Only'].map(
                      (s, index) => (
                        <MenuItem value={s} key={index}>
                          {s}
                        </MenuItem>
                      )
                    )}
                  </Select>
                </FormControl>

                <FormControl sx={{ width: 320, margin: 4 }}>
                  <FormLabel id="demo-multiple-checkbox-label">Type</FormLabel>
                  <RadioGroup
                    row
                    labelId="demo-multiple-checkbox-label"
                    name="type"
                    value={activeEvent.type}
                    onChange={(e) => {
                      if (e.target.value === 'Open') {
                        setActiveEvent({
                          ...activeEvent,
                          person_creating_hold: null,
                          clientInitials: null,
                          holdCreationDate: null,
                          type: e.target.value,
                        });
                      } else {
                        setActiveEvent({
                          ...activeEvent,
                          type: e.target.value,
                        });
                      }
                    }}
                  >
                    {['Open', 'Hold'].map((s, index) => (
                      <FormControlLabel
                        key={index}
                        value={s}
                        control={<Radio />}
                        label={s}
                      />
                    ))}
                  </RadioGroup>
                </FormControl>

                {activeEvent.type === 'Hold' && (
                  <div>
                    <FormControl
                      sx={{
                        width: 320,
                        margin: 2,
                        border: activeEvent.person_creating_hold
                          ? '1px solid #ced4da'
                          : '1px solid red',
                        borderRadius: '4px',
                        '&:hover': {
                          border: '1px solid #ced4da',
                        },
                      }}
                    >
                      <InputLabel id="demo-multiple-checkbox-label">
                        Person Creating Hold
                      </InputLabel>
                      <Select
                        labelId="demo-multiple-checkbox-label"
                        name="holdCreators"
                        value={activeEvent.person_creating_hold}
                        onChange={(e) =>
                          setActiveEvent({
                            ...activeEvent,
                            person_creating_hold: e.target.value,
                          })
                        }
                        input={<OutlinedInput label="HoldCreators" />}
                        MenuProps={MenuProps}
                      >
                        {holdCreators.map((obj, index) => (
                          <MenuItem key={index} value={obj.username}>
                            <Checkbox
                              checked={
                                activeEvent.person_creating_hold?.indexOf(
                                  obj.username
                                ) > -1
                              }
                            />
                            <ListItemText primary={obj.username} />
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>

                    <FormControl
                      sx={{
                        width: 320,
                        margin: 2,
                        border: activeEvent.clientInitials
                          ? '1px solid #ced4da'
                          : '1px solid red',
                        borderRadius: '4px',
                        '&:hover': {
                          border: '1px solid #ced4da',
                        },
                      }}
                    >
                      <TextField
                        required
                        autoComplete="off"
                        placeholder="type N/A if not related to a specific client or type Unknown if the client’s name is not known"
                        fullWidth
                        variant="outlined"
                        id="Initials-of-Client"
                        label="Initials of Client"
                        onChange={(event) => {
                          setActiveEvent({
                            ...activeEvent,
                            clientInitials: event.target.value,
                          });
                        }}
                        value={activeEvent.clientInitials}
                        InputProps={{
                          inputProps: {
                            maxLength: 8, // Maximum length of 8 characters
                          },
                        }}
                      />
                    </FormControl>

                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                      <label style={{ marginBottom: '8px' }}>
                        Hold Creation Date
                      </label>
                      <DatePicker
                        variant="inline"
                        inputVariant="outlined"
                        format="MM/dd/yyyy"
                        value={
                          activeEvent.holdCreationDate
                            ? new Date(activeEvent.holdCreationDate)
                            : null
                        }
                        name="custom_date_docs_due"
                        InputAdornmentProps={{ position: 'start' }}
                        onChange={(date) =>
                          setActiveEvent({
                            ...activeEvent,
                            holdCreationDate: date,
                          })
                        }
                        InputLabelProps={{
                          shrink: true,
                        }}
                        style={{
                          width: '100%', // Make it full width relative to its container
                          border: activeEvent.holdCreationDate
                            ? '1px solid #ced4da'
                            : '1px solid red',
                          borderRadius: '4px',
                        }}
                      />
                    </div>
                  </div>
                )}

                <FormControl sx={{ width: 320, margin: 2 }}>
                  <RadioGroup
                    aria-label="Gender"
                    name="type"
                    // className={classes.group}
                    value={'All Events'}
                    // onChange={handleChangeEventType}
                  >
                    <FormControlLabel
                      value="All Events"
                      control={<Radio />}
                      label="All Events"
                    />
                  </RadioGroup>
                </FormControl>

                <Row style={{ margin: 14 }} xs="12">
                  <Col xs="6"></Col>
                  <Col xs="6">
                    <Row style={{ right: 0, position: 'absolute' }}>
                      <Col xs="6">
                        <Button
                          variant="contained"
                          onClick={async (e) => {
                            setAvailabilityDetailOpen(false);
                          }}
                        >
                          CANCEL
                        </Button>
                      </Col>
                      <Col xs="6">
                        {shouldShowEditButton() && (
                          <Button
                            variant="contained"
                            onClick={async (e) => {
                              await updateEvent();
                              setAvailabilityDetailOpen(false);
                              await fetchProviderAvailabilityAndSlots(
                                selectedProvider?.id
                              );
                            }}
                          >
                            UPDATE
                          </Button>
                        )}
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </TabPanel>
            </TabContext>
          </Box>
        </Modal>
      </React.Fragment>
    </>
  );
};
export default UpdateAvailability;
