import React, { useEffect, useCallback, useState } from 'react';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import Switch from '@mui/material/Switch';
import EventBus from '../../common/EventBus';
import ProviderPersonalInfo from '../Input/ProviderPersonalInfo';
import ProviderPracticeInfo from '../Input/ProviderPracticeInfo';
import ProviderProfessionalInfo from '../Input/ProviderProfessionalInfo';
import ProviderSystemInfo from '../Input/ProviderSystemInfo';
import ProviderInsuranceInfo from '../Input/ProviderInsuranceInfo';
import TherapyConfig from '../Config/TherapyConfig';
import TestingConfig from '../Config/TestingConfig';
import UserService from '../../services/user.service';
import driveServices from '../../services/drive.services';
import Grid from '@mui/material/Grid';
import { Select, MenuItem, FormControl, InputLabel } from '@mui/material';
import { useSelector, useDispatch } from 'react-redux';
import { clearLoading, setLoading } from '../../actions/loading';

import {
  isAdmin,
  isDoctor,
  isSupervisee,
  isDoctorAdminSupervisor,
  isOnlyDoctorOrSupervisor,
  isOnlyDoctorOrSupervisee,
} from '../../common/utility';

import DriveDetails from '../DriveComponent/DriveDetails';
import FilesOnDrive from '../DriveComponent/FilesOnDrive';
import '../../styles/ProviderConfigHomeStyles.css';
import supervisorService from '../../services/supervisor.service';

export default function ProviderConfigHome() {
  const [value, setValue] = useState('personal_info');
  const [users, setUsers] = React.useState([]);
  const [selectedUser, setSelectedUser] = React.useState('');
  const [selectedUserDetail, setSelectedUserDetail] = React.useState();
  const [showActiveProviderOnly, setShowActiveProviderOnly] =
    React.useState(true);
  const [shouldShow, setShouldShow] = React.useState(false);
  const { user: currentUser } = useSelector((state) => state.auth);
  const [superviseeIds, setSuperViseeIds] = useState(); // supervisorIds setting and unsetting
  const [globalProviders, setGlobalProviders] = useState(); // Declaring Providers as a state
  const [liabilityFileExpirationDate, setLiabilityFileExpirationDate] =
    useState(); // This useState created for setting liability file expiration date
  const [accordionToOpen, setAccordionToOpen] = useState();
  // The above state is use to keep track of accordion to be opened.

  const dispatch = useDispatch();

  const showLoading = useCallback(() => {
    dispatch(setLoading());
  }, [dispatch]);

  const hideLoading = useCallback(() => {
    dispatch(clearLoading());
  }, [dispatch]);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const getAllProviders = async () => {
    UserService.getAllProviders().then(
      (response) => {
        let users = response?.data?.users ?? [];
        let providers = users
          .filter((user) => {
            return user.roleId === 5; // filtering provider from users
          })
          .sort((user1, user2) => {
            const name1 = user1?.username?.split(' ')[1] ?? '';
            const name2 = user2?.username?.split(' ')[1] ?? '';
            return name1.localeCompare(name2);
          });
        // The above filtering providers from users and setting providers.

        if (isDoctor(currentUser) && !isDoctorAdminSupervisor(currentUser)) {
          providers = providers.filter((provider) => {
            return provider.id === currentUser.id;
          });
        }
        setUsers(providers);
        hideLoading();
      },
      (error) => {
        const _content =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();

        console.log('_content', _content);

        if (error.response && error.response.status === 401) {
          EventBus.dispatch('logout');
        }
      }
    );
  };

  // The below function is responsible for setting the Tab and menu based on Active Tab selected
  const setTheSelectedProviderBasedOnActiveTab = async (providers) => {
    // Showing the loading
    showLoading();
    const activeTab = localStorage.getItem('activeTab');

    if (activeTab) {
      // The below optionSelected key is responsible for opening the accordion
      const accordionToOpen = localStorage.getItem('optionSelected');
      setAccordionToOpen(accordionToOpen);
      // The above setting the accordion to be opened.

      const currentProviderTobeSelected = providers?.find(
        (provider) => provider.id === currentUser.id
      );

      setValue(activeTab);
      if (currentProviderTobeSelected) {
        const providerDetail = await UserService.getProviderDetail(
          currentProviderTobeSelected.id,
          currentProviderTobeSelected.email
        );
        setSelectedUserDetail({
          ...providerDetail?.data?.provider,
        });

        let foundUser = providers.find((provider) => {
          return provider.id === currentProviderTobeSelected.id;
        });
        setSelectedUser(foundUser);
      }
    }
    // Removing both the activeTab key and optionSelected key
    localStorage.removeItem('activeTab');
    localStorage.removeItem('optionSelected');
    hideLoading();
  };

  const getNormalUsers = async () => {
    UserService.getUsers().then(
      async (response) => {
        let users = response?.data?.users ?? [];
        let providers = users
          .filter((user) => {
            return user.roleId === 5 && user.is_active === 1;
          })
          .sort((user1, user2) => {
            const name1 = user1?.username?.split(' ')[1] ?? '';
            const name2 = user2?.username?.split(' ')[1] ?? '';
            return name1.localeCompare(name2);
          });
        // The above filtering providers from users and setting providers

        // Setting the providers below to be used as a global and somewhere else in the code.
        setGlobalProviders(providers);

        // I am here confirming if the user is actually only with the doctor role
        if (isOnlyDoctorOrSupervisee(currentUser)) {
          let currentProviderLogin = providers.find(
            (provider) => provider.id === currentUser.id
          );
          setSelectedUser(currentProviderLogin);
          const providerDetail = await UserService.getProviderDetail(
            currentProviderLogin.id,
            currentProviderLogin.email
          );
          if (providerDetail && providerDetail.data) {
            providerDetail.data.provider.username =
              currentProviderLogin.username;
            setSelectedUserDetail(providerDetail?.data?.provider);
          }
          hideLoading();
          setTheSelectedProviderBasedOnActiveTab(providers);
        }
        // I am here confirming if the user is actually only with the doctor or supervisor role
        else if (isOnlyDoctorOrSupervisor(currentUser)) {
          const response = await supervisorService.getSupervisorSupervisee(
            currentUser.id
          );

          let currentProviderLogin = providers.find(
            (provider) => provider.id === currentUser.id
          );
          setSelectedUser(currentProviderLogin);
          const providerDetail = await UserService.getProviderDetail(
            currentProviderLogin.id,
            currentProviderLogin.email
          );
          if (providerDetail && providerDetail.data) {
            providerDetail.data.provider.username =
              currentProviderLogin.username;
            setSelectedUserDetail(providerDetail?.data?.provider);
          }

          const superviseeIds = response.data.data.map(
            (dataObj) => dataObj.superviseeId
          );

          setSuperViseeIds(superviseeIds);
          // Above setting the superviseeIds

          const filteredProvider = providers.filter(
            (provider) =>
              superviseeIds.includes(provider.id) ||
              currentUser.id === provider.id
          ); // In the above code. I am making an OR comparison to check if providerId is equal to superviseeIds or currentUser id to providerId

          setUsers(filteredProvider);
          setSelectedUser(undefined);
          setSelectedUserDetail(undefined);
          hideLoading();
          setTheSelectedProviderBasedOnActiveTab(providers);
        }
        // if none of the condition are satisfied then come towards this
        else {
          setUsers(providers);
          hideLoading();
          setTheSelectedProviderBasedOnActiveTab(providers);
        }
      },
      (error) => {
        const _content =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();

        console.log('_content', _content);

        if (error.response && error.response.status === 401) {
          EventBus.dispatch('logout');
        }
      }
    );
  };

  useEffect(() => {
    if (
      // eslint-disable-next-line eqeqeq
      currentUser.id == process.env.REACT_APP_USERID1_FOR_PROVIDER_STATUS ||
      // eslint-disable-next-line eqeqeq
      currentUser.id == process.env.REACT_APP_USERID2_FOR_PROVIDER_STATUS
    ) {
      setShouldShow(true);
    } else {
      setShouldShow(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    showLoading();

    if (showActiveProviderOnly) {
      getNormalUsers();
    } else {
      getAllProviders();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showActiveProviderOnly]);

  useEffect(() => {
    async function getLiabilityDate() {
      const response = await driveServices.viewFiles({
        folder: 'liability',
        provider_id: selectedUserDetail?.id,
      });

      // Below liability File Expiration Date set
      setLiabilityFileExpirationDate(
        response?.data?.data.files[response?.data?.data.files.length - 1]
          ?.expiry_date
      );
    }

    if (selectedUserDetail?.id) {
      getLiabilityDate();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedUserDetail]);

  const onTagsChange = async (event, id) => {
    let enObj = users.find((obj) => {
      return obj?.id === id;
    });
    setSelectedUser(enObj);

    // Below condition make sures that the user is DoctorOrSupervisor
    if (isOnlyDoctorOrSupervisor(currentUser)) {
      // The below condition hanldes the case for the Provider not getting shown up twice duplicate in the top dropdown

      const filteredProvider = globalProviders?.filter(
        (provider) =>
          superviseeIds?.includes(provider?.id) ||
          provider?.id === currentUser?.id
      );
      setUsers(filteredProvider);
    }

    if (enObj?.id) {
      const providerDetail = await UserService.getProviderDetail(
        enObj.id,
        enObj.email
      );
      if (providerDetail && providerDetail.data) {
        providerDetail.data.provider.username = enObj.username;
        setSelectedUserDetail(providerDetail?.data?.provider);
      }
    }
  };

  const updateProviderDetail = async () => {
    const providerDetail = await UserService.getProviderDetail(
      selectedUser.id,
      selectedUser.email
    );

    if (providerDetail && providerDetail.data) {
      setSelectedUserDetail({
        ...providerDetail?.data?.provider,
      });
    }
  };

  return (
    <React.Fragment>
      <div className="mainContainer">
        <Grid container spacing={3}>
          <Grid item xs={12}>
            {(!isDoctor(currentUser) ||
              isDoctorAdminSupervisor(currentUser) ||
              isOnlyDoctorOrSupervisor(currentUser)) && (
              <FormControl variant="outlined" className="widerProviderDropdown">
                <InputLabel id="provider-label">Provider</InputLabel>
                <Select
                  labelId="provider-label"
                  id="provider"
                  value={selectedUser?.id || ''}
                  onChange={(event) => onTagsChange(event, event.target.value)}
                  label="Provider"
                >
                  {users.map((option) => {
                    let displayLabel = option.username;
                    if (isOnlyDoctorOrSupervisor(currentUser)) {
                      if (option.id === currentUser.id) {
                        displayLabel = `${option.username} (Me)`;
                      } else if (superviseeIds?.includes(option.id)) {
                        displayLabel = `${option.username} (Supervisee)`;
                      }
                    }
                    return (
                      <MenuItem key={option.id} value={option.id}>
                        {displayLabel}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
            )}

            {/*if the user is only Doctor then displaying the current user being logged in */}
            {isOnlyDoctorOrSupervisee(currentUser) && (
              <>
                <h3>{currentUser.username}</h3>
                <h5>{currentUser.email}</h5>
              </>
            )}

            {/*if the user is superVisor then displaying the selected user being logged in */}
            {isOnlyDoctorOrSupervisor(currentUser) && (
              <>
                <h3>{selectedUser?.username}</h3>
                <h5>{selectedUser?.email}</h5>
              </>
            )}
          </Grid>
        </Grid>
        {selectedUser && (
          <Box className="tab-container">
            <Tabs
              onChange={handleChange}
              value={value}
              aria-label="Tabs where selection follows focus"
              selectionFollowsFocus
            >
              <Tab value="personal_info" label="Personal Info" />

              <Tab value="professional_info" label="Professional Info" />

              {isAdmin(currentUser) && selectedUserDetail && (
                <Tab value="practice_info" label="Practice Info" />
              )}

              {isAdmin(currentUser) && selectedUserDetail && (
                <Tab value="provider_systems" label="Systems" />
              )}

              {isAdmin(currentUser) && selectedUserDetail && (
                <Tab value="provider_insurance_info" label="Insurance Info" />
              )}

              {selectedUserDetail && selectedUserDetail.in_therapy === true && (
                <Tab value="in_therapy" label="Approp - Therapy" />
              )}
              {selectedUserDetail && selectedUserDetail.in_testing === true && (
                <Tab value="in_testing" label="Approp - Testing" />
              )}
              {isAdmin(currentUser) && selectedUserDetail && (
                <Tab value="drive_detail" label="Drive Details" />
              )}

              {/* {!isCareCoordinator(currentUser) && selectedUserDetail && (
                <Tab value="drive" label="Professional Documents" />
              )} */}
            </Tabs>
            {value === 'personal_info' && (
              <ProviderPersonalInfo
                isProvider={isDoctor(currentUser)}
                isSupervisee={isSupervisee(currentUser)} // Add this line
                selectedUser={selectedUser}
                selectedUserDetail={selectedUserDetail}
                updateProviderDetail={updateProviderDetail}
                mode={'update'} // This Prop is needed don't remove here it is showing for update
              />
            )}

            {value === 'professional_info' && (
              <ProviderProfessionalInfo
                setLiabilityFileExpirationDate={setLiabilityFileExpirationDate}
                isProvider={isDoctor(currentUser)}
                selectedUser={selectedUser}
                selectedUserDetail={selectedUserDetail}
                updateProviderDetail={updateProviderDetail}
                accordionToOpen={accordionToOpen}
                // The above prop to open accordion
              />
            )}

            {value === 'practice_info' && (
              <ProviderPracticeInfo
                isProvider={isDoctor(currentUser)}
                selectedUser={selectedUser}
                selectedUserDetail={selectedUserDetail}
                updateProviderDetail={updateProviderDetail}
              />
            )}

            {value === 'provider_systems' && (
              <ProviderSystemInfo
                liabilityFileExpirationDate={liabilityFileExpirationDate}
                isProvider={isDoctor(currentUser)}
                selectedUser={selectedUser}
                selectedUserDetail={selectedUserDetail}
                updateProviderDetail={updateProviderDetail}
                showLoading={showLoading}
                hideLoading={hideLoading}
              />
            )}

            {value === 'provider_insurance_info' && (
              <ProviderInsuranceInfo
                isProvider={isDoctor(currentUser)}
                selectedUser={selectedUser}
                selectedUserDetail={selectedUserDetail}
                updateProviderDetail={updateProviderDetail}
              />
            )}

            {value === 'in_therapy' && (
              <TherapyConfig
                isProvider={isDoctor(currentUser)}
                isSupervisee={isSupervisee(currentUser)} // Add this line
                isSupervisor={selectedUser?.roles?.includes(7)} // Check if the selected user is a supervisor
                isSelectedSupervisee={selectedUser?.roles?.includes(8)} // Check if the selected user is a supervisee
                selectedUser={selectedUser}
                selectedUserDetail={selectedUserDetail}
                updateProviderDetail={updateProviderDetail}
              />
            )}
            {value === 'in_testing' && (
              <TestingConfig
                isProvider={isDoctor(currentUser)}
                isSupervisee={isSupervisee(currentUser)} // Add this line
                selectedUser={selectedUser}
                selectedUserDetail={selectedUserDetail}
                updateProviderDetail={updateProviderDetail}
              />
            )}
            {value === 'drive_detail' && (
              <DriveDetails selectedUser={selectedUser} />
            )}
            {value === 'drive' && (
              <FilesOnDrive
                selectedUserDetail={selectedUserDetail}
                selectedUser={selectedUser}
              />
            )}
            {/* {value === 3 && <UpdateAvailability />} */}
          </Box>
        )}
        {!selectedUser && <div>Please select a Provider.</div>}

        {shouldShow && !selectedUser && (
          <div
            style={{
              flexGrow: 1,
              flexBasis: 'calc(33.333% - 24px)',
              maxWidth: 'calc(33.333% - 24px)',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              marginTop: '20px',
            }}
          >
            <Switch
              checked={showActiveProviderOnly}
              onChange={(e) => setShowActiveProviderOnly(e.target.checked)}
              color="primary"
            />
            <span
              style={{
                fontSize: '1.1rem',
                fontWeight: 'bold',
              }}
            >
              {showActiveProviderOnly ? (
                <span>Show Active Providers</span> // Displays when the switch is 'on'
              ) : (
                <span>Show All Providers</span> // Displays when the switch is 'off'
              )}
            </span>
          </div>
        )}
      </div>
    </React.Fragment>
  );
}
