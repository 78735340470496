import React, { useState, useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import UserService from '../../services/user.service';
import { IconButton, Tooltip } from '@mui/material';
import ArrowDownward from '@mui/icons-material/ArrowDownward';
import ArrowUpward from '@mui/icons-material/ArrowUpward';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import moment from 'moment';
import EventBus from '../../common/EventBus';
import '../../styles/Rosters.css';
import rosterService from '../../services/roster.service';
import userService from '../../services/user.service';
import { clearLoading, setLoading } from '../../actions/loading';
import { logout } from '../../actions/auth';
import { SET_MESSAGE } from '../../actions/types';

const calculateRemainingDays = (lastSubmissionDate) => {
  if (!lastSubmissionDate) return 30; // Default to 30 days if no submission date is fetched

  let m = moment.utc(lastSubmissionDate).add(30, 'days');
  let n = moment.utc(new Date());
  let remainingDays = m.diff(n, 'days');

  return remainingDays > 0 ? remainingDays : 0;
};

const getShortStatus = (fullStatus) => {
  const statusMap = {
    established: 'Established',
    future: 'Future',
    never_showed: 'Never Showed',
    agreed_termination: 'Agreed Termination',
    awol: 'AWOL', // Adjust the key here as per your exact string
  };

  return statusMap[fullStatus] || fullStatus; // Fallback to full status if no match is found
};

export default function Rosters() {
  const dispatch = useDispatch();
  const [sortColumn, setSortColumn] = useState('Provider');
  const [radioSelections, setRadioSelections] = useState({});
  const [sortDirection, setSortDirection] = useState('asc');
  const [clients, setClients] = useState([]);
  const [LocalLoading, setLocalLoading] = useState(false);
  const [displayedClients, setDisplayedClients] = useState(clients);
  const [columnSelection, setColumnSelection] = useState();
  const [confirmedSelections, setConfirmedSelections] = useState({});
  const [isAllConfirmedModalVisible, setIsAllConfirmedModalVisible] =
    useState(false);
  const [specificConfirmationModalOpen, setSpecificConfirmationModalOpen] =
    useState(false);
  const [successMessage, setSuccessMessage] = useState('');
  const [modalMessageType, setModalMessageType] = useState('');
  const [isSubmissionLocked, setIsSubmissionLocked] = useState(false); // New state for lock statu
  const [expandedRows, setExpandedRows] = useState({});
  const isDoctor = (user) => user.roles.includes('ROLE_DOCTOR');
  const isAdmin = (user) => user.roles.includes('ROLE_ADMIN');

  const [isSubmitted, setIsSubmitted] = useState(false);
  const [submissionDate, setSubmissionDate] = useState(null);

  const [users, setUsers] = React.useState([]);
  const [providers, setProviders] = useState([]);
  const [selectedProvider, setSelectedProvider] = useState({});
  const [, setSelectedUserDetail] = React.useState();
  const [selectedClientId, setSelectedClientId] = useState(null);
  const [selectedOption, setSelectedOption] = useState(null);
  const [
    selectedAgreedTerminationClients,
    setSelectedAgreedTerminationClients,
  ] = useState([]);
  const [allClients, setAllClients] = useState();

  const showLoading = useCallback(() => {
    dispatch(setLoading());
  }, [dispatch]);

  const hideLoading = useCallback(() => {
    dispatch(clearLoading());
  }, [dispatch]);

  const { user: currentUser } = useSelector((state) => state.auth);

  const tooltipStyles = {
    fontSize: '1.2em', // Existing tooltip styles
  };

  const handleOptionChange = (event) => {
    setSelectedOption(event.target.value);
  };

  const sortClients = (clients) => {
    if (!Array.isArray(clients)) return [];

    return [...clients].sort((a, b) => {
      let primaryValA, primaryValB, secondaryValA, secondaryValB;

      switch (sortColumn) {
        case 'Provider':
          primaryValA = a.PROVIDER.split(' ')[a.PROVIDER.split(' ').length - 2]; // Sorting by last name
          primaryValB = b.PROVIDER.split(' ')[b.PROVIDER.split(' ').length - 2];
          secondaryValA = `${a.LASTNAME} ${a.FIRSTNAME}`;
          secondaryValB = `${b.LASTNAME} ${b.FIRSTNAME}`;
          break;
        case 'Name':
          primaryValA = a.LASTNAME;
          primaryValB = b.LASTNAME;
          secondaryValA = a.FIRSTNAME;
          secondaryValB = b.FIRSTNAME;
          break;
        case 'Status':
          primaryValA = a.STATUS;
          primaryValB = b.STATUS;
          break;
        case 'Therapist':
          primaryValA = a.PROVIDER.split(' ')[a.PROVIDER.split(' ').length - 2];
          primaryValB = b.PROVIDER.split(' ')[b.PROVIDER.split(' ').length - 2];
          break;
        default:
          return 0;
      }

      if (primaryValA < primaryValB) return sortDirection === 'asc' ? -1 : 1;
      if (primaryValA > primaryValB) return sortDirection === 'asc' ? 1 : -1;

      // Secondary sort logic (for Provider and Name)
      if (secondaryValA < secondaryValB) return -1;
      if (secondaryValA > secondaryValB) return 1;

      return 0;
    });
  };

  const handleRadioChange = (clientId, selection) => {
    setColumnSelection(selection);
    setRadioSelections((prev) => ({ ...prev, [clientId]: selection }));
    setSelectedClientId(clientId); // Keep track of the selected client ID

    if (['established', 'future'].includes(selection)) {
      // Immediately confirm the selection for these columns
      setConfirmedSelections((prev) => ({ ...prev, [clientId]: true }));
    } else if (
      ['never_showed', 'agreed_termination', 'awol'].includes(selection)
    ) {
      // Open the specific confirmation modal for these columns
      setSpecificConfirmationModalOpen(true);
      setModalMessageType(selection);
    }

    // Check if the application is in mobile view
    const isMobileView = window.innerWidth <= 768; // Adjust 768px according to your mobile breakpoint
    if (isMobileView) {
      // Collapse the client's details view only in mobile view
      setExpandedRows((prev) => ({ ...prev, [clientId]: false }));
    }
  };

  const handleConfirmSubmit = async () => {
    try {
      if (columnSelection === 'agreed_termination') {
        setSelectedAgreedTerminationClients([
          ...selectedAgreedTerminationClients,
          {
            selectedClientId: selectedClientId,
            selectedOption: selectedOption,
          },
        ]);
      }

      setConfirmedSelections((prev) => ({ ...prev, [selectedClientId]: true }));
      setSpecificConfirmationModalOpen(false);
      setSelectedClientId(null);
    } catch (error) {
      console.log('Error occurred during handleConfirmSubmit:', error); // Added log statement for error debugging
      alert('Something went wrong while doing operation');
    }
  };

  const toggleClientDetail = (ta_mrn) => {
    setExpandedRows((prevState) => ({
      ...prevState,
      [ta_mrn]: !prevState[ta_mrn], // Toggle the boolean value for the specific client
    }));
  };

  const handleCancelConfirmation = () => {
    setRadioSelections((prev) => ({ ...prev, [selectedClientId]: '' }));
    setSpecificConfirmationModalOpen(false);
    setSelectedClientId(null);
  };

  useEffect(() => {
    setLocalLoading(true); // Set loading to true while fetching data

    // Check if the current user is an admin
    if (!currentUser.roles.includes('ROLE_ADMIN')) {
      UserService.getMyClients()
        .then((response) => {
          setAllClients(response.data.clients);
          const activeClients = response.data.clients.filter(
            (client) => client.STATUS !== 'Inactive'
          );
          setClients(activeClients);
          setDisplayedClients(activeClients);
        })
        .catch((error) => {
          console.error('There was an error fetching my clients:', error);
        })
        .finally(() => {
          setLocalLoading(false); // Set loading to false when the call is complete
        });
    } else {
      // If the user is an admin, do not load clients initially
      setLocalLoading(false);
    }
  }, [currentUser]); // Include currentUser in the dependency array

  useEffect(() => {
    UserService.getProviders().then(
      (response) => {
        let providers = response?.data?.providers ?? [];
        // Extract last name by finding the word before the comma
        if (providers?.length > 0) {
          providers.sort((a, b) => {
            const lastNameA = (a?.provider_name || '')
              .split(', ')[0]
              .split(' ')
              .slice(-1)[0]
              .toLowerCase();

            const lastNameB = (b?.provider_name || '')
              .split(', ')[0]
              .split(' ')
              .slice(-1)[0]
              .toLowerCase();

            return lastNameA.localeCompare(lastNameB);
          });
        }

        setProviders(providers);
      },
      (error) => {
        // Handle any errors here
        console.error('Error fetching providers:', error);
      }
    );
  }, []);

  const fetchLastSubmissionDate = async () => {
    try {
      const response = await UserService.getLastRosterSubmission(
        currentUser.id
      );
      if (response.data && response.data.last_roster_submission) {
        setSubmissionDate(
          moment.utc(new Date(response.data.last_roster_submission))
        );
        // Calculate if the submission period is still locked
        const remainingDays = calculateRemainingDays(
          moment.utc(new Date(response.data.last_roster_submission))
        );
        setIsSubmitted(remainingDays > 0);
        setIsSubmissionLocked(remainingDays > 0);
      }
    } catch (error) {
      console.error('Error fetching last roster submission date:', error);
    }
  };

  useEffect(() => {
    if (!currentUser.roles.includes('ROLE_ADMIN')) {
      fetchLastSubmissionDate();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentUser]);

  useEffect(() => {
    if (isAdmin(currentUser)) {
      // If the user has an 'admin' role
      setClients([]);
      setDisplayedClients([]);
    } else if (isDoctor(currentUser)) {
      // If the user is a doctor (and not an admin), fetch all active clients for this doctor
      UserService.getClientsByProvider(currentUser.id)
        .then((response) => {
          const activeClients = response?.data?.clients?.filter(
            (client) => client?.STATUS !== 'Inactive'
          );
          setClients(activeClients);
          setDisplayedClients(activeClients);
        })
        .catch((error) => {
          console.error(
            'There was an error fetching the clients for the selected provider:',
            error
          );
          // here have to make user logout of the application
          dispatch({
            type: SET_MESSAGE,
            payload:
              'You have been logged out something went wrong. Please click on login button to login back!',
          });
          dispatch(logout(currentUser));
        });
    } else {
      // For other roles (not admin or doctor), show nothing
      setClients([]);
      setDisplayedClients([]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentUser, selectedProvider]);

  useEffect(() => {
    // Initialize confirmed selections when displayedClients changes
    const initialConfirmedSelections = displayedClients?.reduce(
      (acc, client) => {
        acc[client.ta_mrn] = false; // Assuming ta_mrn is your unique identifier
        return acc;
      },
      {}
    );

    setConfirmedSelections(initialConfirmedSelections);

    if (isSubmissionLocked && allClients?.length > 0) {
      const initialConfirmedSelections = allClients?.reduce((acc, client) => {
        acc[client.ta_mrn] = false; // Assuming ta_mrn is your unique identifier
        return acc;
      }, {});
      setConfirmedSelections(initialConfirmedSelections);

      // eslint-disable-next-line array-callback-return
      allClients.map((client) => {
        if (client.AGREED_TERMINATION_CLIENT) {
          setRadioSelections((prev) => ({
            ...prev,
            [client.ta_mrn]: 'agreed_termination',
          }));
        } else if (client.AWOL_CLIENT) {
          setRadioSelections((prev) => ({
            ...prev,
            [client.ta_mrn]: 'awol',
          }));
        } else if (client.ESTABLISHED_CLIENT) {
          setRadioSelections((prev) => ({
            ...prev,
            [client.ta_mrn]: 'established',
          }));
        } else if (client.FUTURE_CLIENT) {
          setRadioSelections((prev) => ({
            ...prev,
            [client.ta_mrn]: 'future',
          }));
        } else if (client.NEVER_SHOWED_CLIENT) {
          setRadioSelections((prev) => ({
            ...prev,
            [client.ta_mrn]: 'never_showed',
          }));
        }
      });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [displayedClients, allClients, isSubmissionLocked]);

  useEffect(() => {
    const allConfirmed =
      displayedClients?.length > 0 &&
      Object.values(confirmedSelections).every((status) => status);

    setIsAllConfirmedModalVisible(allConfirmed);
  }, [confirmedSelections, displayedClients]);

  useEffect(() => {
    UserService.getUsers().then(
      (response) => {
        let users = response?.data?.users ?? [];
        let providers = users
          .filter((user) => {
            return user.roleId === 5; // filtering provider from users
          })
          .sort((user1, user2) => {
            const name1 = user1?.username?.split(' ')[1] ?? '';
            const name2 = user2?.username?.split(' ')[1] ?? '';
            return name1.localeCompare(name2);
          });
        // The above filtering providers from users and setting providers.

        if (isDoctor(currentUser)) {
          providers = providers.filter((provider) => {
            return provider.id === currentUser.id;
          });
        }
        setUsers(providers);
      },
      (error) => {
        const _content =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();

        // setContent(_content);
        console.log('_content', _content);
        if (error.response && error.response.status === 401) {
          EventBus.dispatch('logout');
        }
      }
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (users.length > 1) {
      // not a doctor. so return
      return;
    }
    if (currentUser.roles.indexOf('ROLE_DOCTOR') > -1) {
      onTagsChange(null, users[0]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [users]);

  useEffect(() => {
    // Filter out items from selectedAgreedTerminationClients where selectedOption is not "agreed_termination"
    const updatedSelectedAgreedTermination =
      selectedAgreedTerminationClients.filter((item) => {
        const selectedOption = radioSelections[item.selectedClientId];
        return selectedOption === 'agreed_termination';
      });

    // Update the state with the filtered array
    setSelectedAgreedTerminationClients(updatedSelectedAgreedTermination);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [radioSelections]);

  /* The below function is for testing whether provider belongs to both therapy and testing.*/
  const getUserInformationStatus = () => {
    let provider;
    if (Object.keys(selectedProvider).length > 0) {
      provider = providers.find((p) => p.id === selectedProvider.id);
    } else {
      provider = providers.find((p) => p.id === currentUser.id);
    }
    if (provider?.in_testing === true && provider?.in_therapy === true) {
      return true;
    } else {
      setSelectedOption(null);
      return false;
    }
  };

  const onTagsChange = async (event, values) => {
    let enObj = users.find((obj) => {
      return obj?.id === values?.id;
    });
    if (enObj?.id) {
      const providerDetail = await UserService.getProviderDetail(
        enObj.id,
        enObj.email
      );
      if (providerDetail && providerDetail.data && enObj?.username) {
        if (typeof providerDetail.data.provider === 'object') {
          providerDetail.data.provider.username = enObj?.username;
          setSelectedUserDetail(providerDetail?.data?.provider);
        } else {
          console.log('providerDetail.data.provider is not an object');
        }
      }
    }
  };

  const toggleSort = (column) => {
    if (sortColumn === column) {
      setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc');
    } else {
      setSortColumn(column);
      setSortDirection('asc');
    }
  };

  const handleProviderChange = async (event) => {
    setLocalLoading(true);
    const providerId = Number(event.target.value); // Convert to Number if your IDs are numerical
    const provider = providers.find((p) => p.id === providerId);
    setSelectedProvider(provider || {});

    // Fetch the clients for the selected provider
    try {
      const clientsResponse = await userService.getClientsByProvider(
        providerId
      );

      setAllClients(clientsResponse.data.clients);
      // Filter out inactive clients
      const activeClients = clientsResponse?.data?.clients?.filter(
        (client) => client?.STATUS !== 'Inactive'
      );

      setDisplayedClients(activeClients || []);
    } catch (error) {
      console.error('Error fetching clients for provider:', error);
      // here have to make user logout of the application
      dispatch({
        type: SET_MESSAGE,
        payload:
          'You have been logged out something went wrong. Please click on login button to login back!',
      });
      dispatch(logout(currentUser));
      if (error.response && error.response.status === 404) {
        setDisplayedClients([]);
      }
    }
    // Fetch the last roster submission date for the selected provider
    try {
      const submissionResponse = await userService.getLastRosterSubmission(
        providerId
      );
      if (
        submissionResponse.data &&
        submissionResponse.data.last_roster_submission
      ) {
        const submissionDate = new Date(
          submissionResponse.data.last_roster_submission
        );
        setSubmissionDate(moment.utc(submissionDate));

        const remainingDays = calculateRemainingDays(
          moment.utc(submissionDate)
        );
        setIsSubmitted(remainingDays > 0);
        setIsSubmissionLocked(remainingDays > 0);
      } else {
        setIsSubmissionLocked(false);
      }
    } catch (error) {
      console.error('Error fetching last roster submission:', error);
      setIsSubmissionLocked(false);
    }
    setLocalLoading(false);
  };

  // Updated handleSubmit -  12-25-23 #2
  const handleCombinedSubmit = async () => {
    showLoading();
    try {
      // Prepare data for updating new columns in the database.
      // This involves mapping the current selections to a format suitable for the backend API.
      for (let i = 0; i < selectedAgreedTerminationClients.length; i++) {
        console.log('Sending Emails for Agreed_Termination');
        await rosterService.sendRosterEmail(
          selectedAgreedTerminationClients[i].selectedClientId,
          selectedAgreedTerminationClients[i].selectedOption,
          {
            providerId: selectedProvider.id || currentUser.id, // if a provider is selected otherwise currentUser id
          }
        );
      }

      const clientUpdates = Object.entries(radioSelections).map(
        ([clientId, selection]) => {
          // Determine the new status based on the selection.
          let newStatus = '';
          switch (selection) {
            case 'established':
            case 'awol':
              newStatus = 'Active';
              break;
            case 'future':
              newStatus = 'New';
              break;
            case 'never_showed':
            case 'agreed_termination':
              newStatus = 'Inactive';
              break;
            default:
              newStatus = 'Active'; // Default status or any other logic for different statuses
          }

          return {
            clientId: clientId,
            establishedClient: selection === 'established',
            futureClient: selection === 'future',
            neverShowedClient: selection === 'never_showed',
            agreedTerminationClient: selection === 'agreed_termination',
            awolClient: selection === 'awol',
            newStatus: newStatus, // Updated status based on the selection
            providerId: selectedProvider.id
              ? selectedProvider.id
              : currentUser.id, // This is parameter where I am passing selectedProvider or the currentUserId
          };
        }
      );
      console.log('Prepared client updates:', clientUpdates);

      // Send the updates to the backend using the UserService.
      console.log('Before sending updates to backend');
      const updateResponse = await UserService.updateClientColumns(
        clientUpdates
      );

      if (updateResponse.status === 200) {
        // If the update is successful, then update the last roster submission date
        const submissionDate = new Date();
        const submissionResponse = await UserService.updateLastRosterSubmission(
          selectedProvider.id ? selectedProvider.id : currentUser.id, // This is parameter
          {
            newSubmissionDate: new Date(), // This is body
          }
        );

        if (submissionResponse.status === 200) {
          // Update the submission date in the component's state
          setSubmissionDate(submissionDate);
          setIsSubmitted(true); // Set the isSubmitted flag to true
        }
      }

      // Close the modal and show a success message.
      setIsAllConfirmedModalVisible(false); // This will close the modal
      setSuccessMessage('Update Successful'); // Display a success message.

      // Set a timeout to clear the success message after 3 seconds.
      setTimeout(() => {
        setSuccessMessage('');
      }, 3000);
    } catch (error) {
      console.log('Error in handleCombinedSubmit:', error);
      // Handle any errors that occur during the submission process.
      // This could include displaying error messages to the user.
      setIsSubmitted(false); // Reset the isSubmitted flag in case of error
    } finally {
      console.log('Completed handleCombinedSubmit');
      // Any final cleanup can be performed here.
      hideLoading();
      window.location.reload();
    }
  };

  return (
    <div>
      {isAdmin(currentUser) && (
        <select
          value={selectedProvider?.id || ''}
          onChange={handleProviderChange}
        >
          {/* Add a default option with an empty value */}
          <option value="">Please Select</option>

          {providers
            .filter(
              (provider) =>
                provider.provider_name && provider.provider_name.trim() !== ''
            )
            .map((provider) => (
              <option key={provider.id} value={provider.id}>
                {provider.provider_name}
              </option>
            ))}
        </select>
      )}

      {LocalLoading ? (
        <p>Loading...</p>
      ) : (
        <>
          <div className="table-container">
            {isSubmissionLocked && (
              <div
                className={`submission-overlay ${
                  !isSubmitted && 'overlay-inactive'
                }`}
              >
                <div className="submission-locked-text">Submissions Locked</div>
                <div className="countdown-timer">
                  {calculateRemainingDays(submissionDate)} days left until
                  Monthly Rosters are Due
                </div>
                <div className="explanation-text">
                  While your roster is locked you will not be able to make any
                  routine changes. Please reach out to an admin if there is a
                  time-sensitive change that cannot wait until your rosters are
                  due again.
                </div>
              </div>
            )}
            <div className="rosters-table-container">
              <table border="1">
                <thead>
                  <tr>
                    {/* <th className="centered-header">Confirm</th> */}
                    <th
                      className="sticky-header"
                      onClick={() => toggleSort('Name')}
                    >
                      <div className="header-title">
                        Name
                        <ArrowUpward
                          style={{
                            color:
                              sortColumn === 'Name' && sortDirection === 'asc'
                                ? 'black'
                                : 'lightgray',
                          }}
                        />
                        <ArrowDownward
                          style={{
                            color:
                              sortColumn === 'Name' && sortDirection === 'desc'
                                ? 'black'
                                : 'lightgray',
                          }}
                        />
                      </div>
                    </th>

                    <th className="sticky-header">
                      <div className="header-with-tooltip">
                        <div className="header-title">Established Client</div>
                        <Tooltip
                          title="Select this column for an established client that you have intent to continue to see"
                          sx={tooltipStyles}
                        >
                          <IconButton
                            aria-label="help"
                            className="top-right-icon"
                          >
                            <HelpOutlineIcon />
                          </IconButton>
                        </Tooltip>
                      </div>
                    </th>
                    <th className="sticky-header">
                      <div className="header-with-tooltip">
                        <div className="header-title">Future Client</div>
                        <Tooltip
                          title="Select this column if this is a client that you are scheduled to see in the future"
                          sx={tooltipStyles}
                        >
                          <IconButton
                            aria-label="help"
                            className="top-right-icon"
                          >
                            <HelpOutlineIcon />
                          </IconButton>
                        </Tooltip>
                      </div>
                    </th>
                    <th className="sticky-header">
                      <div className="header-with-tooltip">
                        <div className="header-title">Never Showed Client</div>
                        <Tooltip
                          title="Select this column if client never showed to initial session"
                          sx={tooltipStyles}
                        >
                          <IconButton
                            aria-label="help"
                            className="top-right-icon"
                          >
                            <HelpOutlineIcon />
                          </IconButton>
                        </Tooltip>
                      </div>
                    </th>
                    <th className="sticky-header">
                      <div className="header-with-tooltip">
                        <div className="header-title">
                          Agreed Termination Client
                        </div>
                        <Tooltip
                          title="Select this column for an established client that you made a decision to end treatment"
                          sx={tooltipStyles}
                        >
                          <IconButton
                            aria-label="help"
                            className="top-right-icon"
                          >
                            <HelpOutlineIcon />
                          </IconButton>
                        </Tooltip>
                      </div>
                    </th>
                    <th className="sticky-header">
                      <div className="header-with-tooltip">
                        <div className="header-title">
                          AWOL - Need Term Letter
                        </div>
                        <Tooltip
                          title="Select this column if client has gone AWOL where there is a need for a discharge letter"
                          sx={tooltipStyles}
                        >
                          <HelpOutlineIcon />
                        </Tooltip>
                      </div>
                    </th>
                  </tr>
                </thead>

                <tbody>
                  {sortClients(
                    isSubmissionLocked && allClients?.length > 0
                      ? allClients.filter((client) => {
                          // This logic is to stop displaying the clients that were added after submission was locked
                          const clientAddedDate = new Date(client.createdAt);
                          const lockDate = new Date(submissionDate);
                          return clientAddedDate <= lockDate;
                        })
                      : displayedClients // If the submission is not locked, display all displayed clients
                  ).map((client) => (
                    <tr
                      key={client.ta_mrn + client.provider_id}
                      className={
                        confirmedSelections[client.ta_mrn] ||
                        ['established', 'future'].includes(
                          radioSelections[client.ta_mrn]
                        )
                          ? 'highlighted-row'
                          : ''
                      }
                    >
                      <td style={{ fontSize: '1.2em' }}>
                        {`${client.FIRSTNAME} ${client.LASTNAME}`}
                      </td>

                      <td className="centered-radio">
                        <input
                          type="radio"
                          checked={
                            radioSelections[client.ta_mrn] === 'established'
                          }
                          onChange={() =>
                            handleRadioChange(client.ta_mrn, 'established')
                          }
                        />
                      </td>
                      <td className="centered-radio">
                        <input
                          type="radio"
                          checked={radioSelections[client.ta_mrn] === 'future'}
                          onChange={() =>
                            handleRadioChange(client.ta_mrn, 'future')
                          }
                        />
                      </td>
                      <td className="centered-radio">
                        <input
                          type="radio"
                          checked={
                            radioSelections[client.ta_mrn] === 'never_showed'
                          }
                          onChange={() =>
                            handleRadioChange(client.ta_mrn, 'never_showed')
                          }
                        />
                      </td>
                      <td className="centered-radio">
                        <input
                          type="radio"
                          checked={
                            radioSelections[client.ta_mrn] ===
                            'agreed_termination'
                          }
                          onChange={() =>
                            handleRadioChange(
                              client.ta_mrn,
                              'agreed_termination'
                            )
                          }
                        />
                      </td>
                      <td className="centered-radio">
                        <input
                          type="radio"
                          checked={radioSelections[client.ta_mrn] === 'awol'}
                          onChange={() =>
                            handleRadioChange(client.ta_mrn, 'awol')
                          }
                        />
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
            {/* Mobile card view layout, shown only on mobile */}
            <div className="mobile-roster-list">
              {displayedClients?.map((client) => (
                <div
                  className={`mobile-roster-card ${
                    radioSelections[client.ta_mrn] ? 'selected' : ''
                  }`}
                  key={client.ta_mrn}
                >
                  <div
                    className="roster-client-name"
                    onClick={() => toggleClientDetail(client.ta_mrn)}
                    style={{ cursor: 'pointer' }}
                  >
                    {`${client.FIRSTNAME} ${client.LASTNAME}`}
                    {/* Show brief version of selection next to client's name when card is collapsed */}
                    {radioSelections[client.ta_mrn] && (
                      <span className="selected-status">
                        - {getShortStatus(radioSelections[client.ta_mrn])}
                      </span>
                    )}
                  </div>
                  {expandedRows[client.ta_mrn] && (
                    <div className="roster-client-details">
                      <div className="centered-radio">
                        <label>
                          <input
                            type="radio"
                            name={`client-selection-${client.ta_mrn}`}
                            checked={
                              radioSelections[client.ta_mrn] === 'established'
                            }
                            onChange={() =>
                              handleRadioChange(client.ta_mrn, 'established')
                            }
                          />{' '}
                          Established Client
                        </label>
                      </div>
                      <div className="centered-radio">
                        <label>
                          <input
                            type="radio"
                            name={`client-selection-${client.ta_mrn}`}
                            checked={
                              radioSelections[client.ta_mrn] === 'future'
                            }
                            onChange={() =>
                              handleRadioChange(client.ta_mrn, 'future')
                            }
                          />{' '}
                          Future Client
                        </label>
                      </div>
                      <div className="centered-radio">
                        <label>
                          <input
                            type="radio"
                            name={`client-selection-${client.ta_mrn}`}
                            checked={
                              radioSelections[client.ta_mrn] === 'never_showed'
                            }
                            onChange={() =>
                              handleRadioChange(client.ta_mrn, 'never_showed')
                            }
                          />{' '}
                          Never Showed Client
                        </label>
                      </div>
                      <div className="centered-radio">
                        <label>
                          <input
                            type="radio"
                            name={`client-selection-${client.ta_mrn}`}
                            checked={
                              radioSelections[client.ta_mrn] ===
                              'agreed_termination'
                            }
                            onChange={() =>
                              handleRadioChange(
                                client.ta_mrn,
                                'agreed_termination'
                              )
                            }
                          />{' '}
                          Agreed Termination Client
                        </label>
                      </div>
                      <div className="centered-radio">
                        <label>
                          <input
                            type="radio"
                            name={`client-selection-${client.ta_mrn}`}
                            checked={radioSelections[client.ta_mrn] === 'awol'}
                            onChange={() =>
                              handleRadioChange(client.ta_mrn, 'awol')
                            }
                          />{' '}
                          AWOL - Need Term Letter
                        </label>
                      </div>
                    </div>
                  )}
                </div>
              ))}
            </div>
          </div>
        </>
      )}

      <ConfirmationModal
        isVisible={specificConfirmationModalOpen}
        onConfirm={handleConfirmSubmit}
        onCancel={handleCancelConfirmation}
        modalMessageType={modalMessageType}
      />

      <AllConfirmedModal
        isVisible={isAllConfirmedModalVisible}
        onSubmit={handleCombinedSubmit}
      />

      {successMessage && (
        <div className="success-message">{successMessage}</div>
      )}
    </div>
  );

  function ConfirmationModal({
    isVisible,
    onConfirm,
    onCancel,
    modalMessageType,
  }) {
    if (!isVisible) return null;

    // This function determines the message to display based on the modalMessageType
    const getMessage = () => {
      switch (modalMessageType) {
        case 'agreed_termination':
          return (
            <div style={{ fontFamily: 'Arial, sans-serif', padding: '20px' }}>
              <p
                style={{
                  fontWeight: 'bold',
                  fontSize: '18px',
                  marginBottom: '10px',
                }}
              >
                Confirm That:
              </p>
              <ol style={{ marginLeft: '20px', marginBottom: '20px' }}>
                <li style={{ marginBottom: '5px' }}>
                  <strong>#1 Termination information</strong> is documented in
                  TA progress notes, including (but not limited to):
                  <ol type="A" style={{ marginLeft: '20px' }}>
                    <li style={{ marginBottom: '5px' }}>
                      A. Reason(s) for termination
                    </li>
                    <li style={{ marginBottom: '5px' }}>
                      B. Any additional referrals made (if appropriate)
                    </li>
                  </ol>
                </li>
                <li style={{ marginBottom: '20px' }}>
                  <strong>#2 Client Status</strong> Set to Inactive in TA
                </li>
              </ol>
              {/*The below function is responsible for checking whether user belongs to both therapy and testing*/}
              {getUserInformationStatus() && (
                <div
                  style={{ borderTop: '1px solid #ccc', paddingTop: '15px' }}
                >
                  <p
                    style={{
                      fontWeight: 'bold',
                      fontSize: '16px',
                      marginBottom: '10px',
                    }}
                  >
                    Note: Your provider profile is registered for both Therapy
                    and Testing. Which service did you provide for this client?
                    Please select:
                  </p>
                  <form>
                    <label style={{ marginRight: '30px', fontSize: '14px' }}>
                      <input
                        type="radio"
                        value="Therapy"
                        checked={selectedOption === 'Therapy'}
                        onChange={handleOptionChange}
                        style={{ marginRight: '10px' }}
                      />
                      Therapy
                    </label>
                    <label style={{ fontSize: '14px' }}>
                      <input
                        type="radio"
                        value="Testing"
                        checked={selectedOption === 'Testing'}
                        onChange={handleOptionChange}
                        style={{ marginRight: '10px' }}
                      />
                      Testing
                    </label>
                  </form>
                </div>
              )}
            </div>
          );

        case 'awol':
          return (
            <div style={{ fontFamily: 'Arial, sans-serif', padding: '20px' }}>
              <p
                style={{
                  fontWeight: 'bold',
                  fontSize: '18px',
                  textAlign: 'center',
                  marginBottom: '10px',
                }}
              >
                Confirm your AWOL Selection
              </p>

              <div style={{ marginTop: '10px', fontWeight: 'normal' }}>
                <p style={{ marginBottom: '5px' }}>
                  <i>
                    Upon confirmation, (1) An admin will be notified to mail an
                    AWOL letter to the client. (2) The AWOL letter will be be
                    uploaded to the Document section in TA (3) The client will
                    be placed in the Discharge in Progress (DIP) section for
                    later review.
                  </i>
                </p>
              </div>
            </div>
          );

        default:
          return (
            <>
              <p>Please make this client is Inactive in TA.</p>
              <p>Are you sure you want to confirm this selection?</p>
            </>
          );
      }
    };

    return (
      <div className="modal">
        <div className="modal-content">
          {getMessage()}
          <div className="modal-button-container">
            <>
              <button
                onClick={onConfirm}
                className="modal-button submit-button"
              >
                Confirm
              </button>
              <button onClick={onCancel} className="modal-button cancel-button">
                Cancel
              </button>
            </>
          </div>
        </div>
      </div>
    );
  }
  function AllConfirmedModal({ isVisible, onSubmit }) {
    if (!isVisible) return null;

    return (
      <div className="confirmation-modal-backdrop">
        <div className="confirmation-modal">
          <p>
            All clients have been accounted for. Click the "Submit" button below
            to finalize and confirm your Active Rosters for this month.
          </p>
          <div className="modal-button-container">
            <button onClick={onSubmit} className="modal-button submit-button">
              Submit
            </button>
            <button
              onClick={() => setIsAllConfirmedModalVisible(false)}
              className="modal-button cancel-button"
            >
              Cancel
            </button>
          </div>
        </div>
      </div>
    );
  }
}
