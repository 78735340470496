import React from 'react';
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
} from 'recharts';

const CustomTick = (props) => {
  const { x, y, payload } = props;

  const parenthIndex = payload.value.indexOf('(');
  const firstLine = payload.value.substring(0, parenthIndex);
  const secondLine = payload.value.substring(parenthIndex);

  return (
    <g transform={`translate(${x},${y})`}>
      <text x={0} y={0} textAnchor="middle" fill="#666">
        <tspan x={0} y="20">
          {firstLine}
        </tspan>{' '}
        {/* y is set to absolute position */}
        <tspan x={0} y="35">
          {secondLine}
        </tspan>{' '}
        {/* y is set to absolute position */}
      </text>
    </g>
  );
};

function StackBarCharts({ data, CustomTooltip, barWidth ,showXaxis=true}) {
  // const COLORS = ["#0088FE", "#00C49F", "#FFBB28", "#FF8042", "#ff80ed", "#065535", "#ffd700", "#ff7373", "#003366", "#8a2be2", "#800000", "#008000", "#ccff00", "#794044", "#cc0000", "#b4eeb4", "#800080"];
  const COLORS = [
    '#FF5733',
    '#5733FF',
    '#FF33A3',
    '#33A3FF',
    '#FF3366',
    '#3366FF',
    '#6633FF',
    '#33FFA3',
    '#33FF66',
    '#66FF33',
    '#FF6633',
    '#33A3FF',
    '#FF33FF',
    '#33FFFF',
    '#FF33CC',
    '#33CCFF',
    '#CC33FF',
    '#33FF33',
    '#FFCC33',
    '#33FF00',
    '#00FF33',
    '#33FF99',
    '#9933FF',
    '#FF9933',
    '#33FFCC',
    '#CC33FF',
    '#FF99CC',
    '#CC99FF',
    '#FFCC99',
    '#CCFF99',
    '#99CCFF',
    '#99FFCC',
    '#FF99FF',
    '#CCFFFF',
    '#FFCCFF',
    '#FFFF33',
    '#33FFFF',
    '#FFFF66',
    '#66FFFF',
    '#FFFF99',
    '#99FFFF',
    '#FF9933',
    '#33FF33',
    '#33CC33',
  ];

  const calculatedWidth = (data?.length || 0) * (barWidth || 200); // calculate the width based on the data length and bar width

  return (
    <ResponsiveContainer width="100%" height="100%">
      <BarChart
        width={calculatedWidth} // use calculatedWidth here
        data={data}
        margin={{
          top: 20,
          right: 0,
          left: 0,
          bottom: 40,
        }}
        // barGap={-40} // add this line
        // barCategoryGap={10} // and this line
      >
        <CartesianGrid strokeDasharray="1 1" />
        {showXaxis &&
            <XAxis
            dataKey="name"
            padding={{ left: 30, right: 30 }}
            tick={<CustomTick />}
          />
        }
        <YAxis />
        <Tooltip content={CustomTooltip || null} />
        {Array(10)
          .fill('0')
          ?.map((v, k) => (
            <Bar dataKey={`value${k}`} stackId="a" fill={COLORS[k - 1]} />
          ))}
      </BarChart>
    </ResponsiveContainer>
  );
}

export default StackBarCharts;

